import libtess from "libtess";
import { InstancedMesh } from "webcad/core/instanced-mesh";
import {
  addVectors2,
  multiplyVector2ByFloats,
  rotateVector2,
  Vector2,
} from "webcad/math";
import {
  Aabb2,
  getPointsFromSegment,
  IsPointInsideOfShape,
  isPointOnSegment,
  movePolylineInPlace,
  rotatePolylineInPlace,
  scalePolyline,
  Segment,
  SegmentType,
} from "webcad/models";
import { anySegment, ShapeWithHoles } from "./shape-with-holes";
import { Buffer, Scene, Vector3, VertexBuffer} from "@babylonjs/core";
export enum MountingType {
  langloch = "Langloch",
  quadrat = "Quadrat",
  rechteck = "Rechteck",
  rund = "Rund",
}

export enum MountingPositioningType {
  free = "Free",
  edgeCutout = "EdgeCutout",
  edgeExtension = "EdgeExtension",
}

export interface Mounting {
  id: number;
  active: boolean;
  form: string;
  width: number;
  length: number;
  extensionX: number;
  extensionY: number;
  materialCode: string;
  thicknessMin: number;
  thicknessMax: number;
  rotateable: boolean;
  positioning: MountingPositioningType;
}

export interface MountingModel {
  mounting: Mounting;
  verticesMap: Map<string, Vector2[]>;
  shapeMap: Map<string, Segment[]>;
}

export const initialMounting: Mounting = {
  active: null,
  extensionX: null,
  extensionY: null,
  form: null,
  id: null,
  length: null,
  materialCode: null,
  positioning: null,
  rotateable: null,
  thicknessMax: null,
  thicknessMin: null,
  width: null,
};

export const initialMountingModel: MountingModel = {
  mounting: initialMounting,
  verticesMap: new Map<string, Vector2[]>(),
  shapeMap: new Map<string, Segment[]>(),
};

export interface MountingAndShape {
  mounting: Mounting;
  shape: Vector2[];
  polyline: Segment[];
}

export const initialMountingAndShape = {
  mounting: initialMounting,
  shape: [],
  polyline: [],
};

export interface MountingAttachment {
  mountingRef: Mounting;
  vertices: Vector2[];
  shape: Segment[];
  position: Vector2;
  rotation: number;
  possible: boolean;
}

export const initialMountingAttachment: MountingAttachment = {
  mountingRef: null,
  vertices: [],
  shape: [],
  position: { x: 0, y: 0 },
  rotation: 0,
  possible: true,
};

export interface MountingSave {
  id: number;
  position: Vector2;
  rotation: number;
}

export const initialMountingSave: MountingSave = {
  id: null,
  position: null,
  rotation: null,
};

export function mountingSizeToString(mounting: Mounting) {
  if (!!mounting.width && !!mounting.length) {
    if (mounting.form.toLowerCase() === "rund") {
      return mounting.width.toString();
    }
    if (mounting.length !== 0) {
      return mounting.width.toString() + " x " + mounting.length.toString();
    } else {
      return mounting.width.toString() + " x " + mounting.width.toString();
    }
  } else {
    return null;
  }
}

const quadratVertices: Vector2[] = [
  { x: -0.5, y: -0.5 },
  { x: 0.5, y: -0.5 },
  { x: 0.5, y: 0.5 },
  { x: -0.5, y: 0.5 },
];

export function mountingToPoints(
  mounting: Mounting,
  polyline: Segment[],
  angle: number
): Vector2[] {
  const shape = scaleMounting(mounting, polyline, angle);
  const positions = [];
  for (const s of shape) {
    const points = getPointsFromSegment(s);
    positions.push(...points.map((v) => new Vector3(v.x, v.y, 0)));
  }
  return positions;
}

export function createMountingMeshFrom(
  mounting: Mounting,
  polyline: Segment[],
  scene: Scene,
  thickness: number,
  angle: number,
  mountingsPositions: Vector2[]
): InstancedMesh {
  const positions = mountingToPoints(mounting, polyline, angle);
  const mesh = buildMesh([positions], scene, thickness, mountingsPositions);
  return mesh;
}

export function getMountingScale(mounting: Mounting): Vector2 {
  const scale = { x: 1, y: 1 };
  if (mounting.form.toLowerCase().includes("agraffe")) {
    scale.y = 0.001;
    scale.x = 0.001;
  } else {
    scale.y = mounting.width / 1000;
    scale.x =
      mounting.length !== 0 ? mounting.length / 1000 : mounting.width / 1000;
    if (mounting.form === MountingType.rund) {
      scale.x /= 2;
      scale.y /= 2;
    }
  }

  return scale;
}

export function scaleMounting(
  mounting: Mounting,
  polyline: Segment[],
  angle: number = 0,
  position: Vector2 = null
): Segment[] {
  const scaleFactor = getMountingScale(mounting);
  let shape: Segment[] = [];
  if (mounting.form.toLowerCase() === MountingType.langloch.toLowerCase()) {
    const r = scaleFactor.y / 2;
    const Leftorigin = { x: -scaleFactor.x * 0.5 + r, y: 0 };
    const Rightorigin = { x: scaleFactor.x * 0.5 - r, y: 0 };
    shape = [
      {
        begin: rotateVector2(addVectors2(Leftorigin, { x: 0, y: r }), angle),
        end: rotateVector2(addVectors2(Leftorigin, { x: 0, y: -r }), angle),
        beginAngle: Math.PI / 2 + angle,
        endAngle: (Math.PI * 3) / 2 + angle,
        type: SegmentType.arc,
        radius: r,
        origin: rotateVector2(Leftorigin, angle),
      },
      {
        begin: rotateVector2(addVectors2(Leftorigin, { x: 0, y: -r }), angle),
        end: rotateVector2(addVectors2(Rightorigin, { x: 0, y: -r }), angle),
        type: SegmentType.line,
      },
      {
        begin: rotateVector2(addVectors2(Rightorigin, { x: 0, y: -r }), angle),
        end: rotateVector2(addVectors2(Rightorigin, { x: 0, y: r }), angle),
        beginAngle: (Math.PI * 3) / 2 + angle,
        endAngle: (Math.PI * 5) / 2 + angle,
        type: SegmentType.arc,
        radius: r,
        origin: rotateVector2(Rightorigin, angle),
      },
      {
        begin: rotateVector2(addVectors2(Rightorigin, { x: 0, y: r }), angle),
        end: rotateVector2(addVectors2(Leftorigin, { x: 0, y: r }), angle),
        type: SegmentType.line,
      },
    ];
    if (position) {
      movePolylineInPlace(shape, position);
    }
  } else {
    shape = scalePolyline(polyline, scaleFactor.x, scaleFactor.y);
    rotatePolylineInPlace(shape, angle);
    if (position) {
      movePolylineInPlace(shape, position);
    }
  }
  return shape;
}

function createLanglochVertices(a: number, b: number): Vector2[] {
  const r = a / 2;
  const Leftorigin = { x: -b * 0.5 + r, y: 0 };
  const Rightorigin = { x: b * 0.5 - r, y: 0 };
  const leftCirclePoints = [];
  const rightCirclePoints = [];
  let segments = 32;
  if (r <= 0.002) {
    segments = 8;
  } else if (r < 0.02) {
    segments = Math.round(8 + ((32 - 8) * (r - 0.002)) / (0.02 - 0.002));
  }
  segments = Math.round(segments * 0.5) * 2;

  for (let i = 0; i <= segments / 2; i++) {
    leftCirclePoints.push({
      x:
        Leftorigin.x +
        Math.cos((Math.PI * 2 * i) / segments + Math.PI * 0.5) * r,
      y:
        Leftorigin.y +
        Math.sin((Math.PI * 2 * i) / segments + Math.PI * 0.5) * r,
    });
  }

  for (let i = 0; i <= segments / 2; i++) {
    rightCirclePoints.push({
      x:
        Rightorigin.x +
        Math.cos((Math.PI * 2 * i) / segments + Math.PI * 1.5) * r,
      y:
        Rightorigin.y +
        Math.sin((Math.PI * 2 * i) / segments + Math.PI * 1.5) * r,
    });
  }
  return [...leftCirclePoints, ...rightCirclePoints];
}

export function isMountingModelReady(model: Mounting) {
  return model && model.form !== null && model.width !== null;
}

export function getMountingAaBb(
  model: MountingAttachment,
  vertices: Vector2[]
): Aabb2 {
  const max: Vector2 = {
    x: Number.NEGATIVE_INFINITY,
    y: Number.NEGATIVE_INFINITY,
  };
  const min: Vector2 = {
    x: Number.POSITIVE_INFINITY,
    y: Number.POSITIVE_INFINITY,
  };
  for (const v of vertices) {
    if (v.x > max.x) {
      max.x = v.x;
    }
    if (v.y > max.y) {
      max.y = v.y;
    }
    if (v.x < min.x) {
      min.x = v.x;
    }
    if (v.y < min.y) {
      min.y = v.y;
    }
  }
  const scale = getMountingScale(model.mountingRef);
  return {
    max: addVectors2(
      model.position,
      multiplyVector2ByFloats(max, scale.x, scale.y)
    ),
    min: addVectors2(
      model.position,
      multiplyVector2ByFloats(min, scale.x, scale.y)
    ),
  };
}

export function buildMesh(
  contours: Vector2[][],
  scene: Scene,
  thickness: number,
  mountingsPositions: Vector2[]
): InstancedMesh {
  const tessy = new libtess.GluTesselator();
  tessy.gluTessCallback(
    libtess.gluEnum.GLU_TESS_VERTEX_DATA,
    (data: number[], polyVertArray: number[]) => {
      const x = data[0];
      const y = data[1];
      polyVertArray[polyVertArray.length] = x;
      polyVertArray[polyVertArray.length] = y;
    }
  );
  tessy.gluTessNormal(0, 0, 1);

  const triangleVerts = [];
  tessy.gluTessBeginPolygon(triangleVerts);

  for (let i = 0; i < contours.length; i++) {
    tessy.gluTessBeginContour();
    const contour = contours[i];
    for (let j = 0; j < contour.length; j++) {
      const coords = [contour[j].x, contour[j].y, 0];
      tessy.gluTessVertex(coords, coords);
    }
    tessy.gluTessEndContour();
  }

  // finish polygon (and time triangulation process)
  tessy.gluTessEndPolygon();

  const positions: number[] = [];

  const t2 = thickness / 2;
  for (let i = 0; i < triangleVerts.length; i += 6) {
    const x1 = triangleVerts[i];
    const y1 = triangleVerts[i + 1];
    const x2 = triangleVerts[i + 2];
    const y2 = triangleVerts[i + 3];
    const x3 = triangleVerts[i + 4];
    const y3 = triangleVerts[i + 5];
    positions.push(x1, y1, -t2, x2, y2, -t2, x3, y3, -t2);
    positions.push(x1, y1, t2, x3, y3, t2, x2, y2, t2);
  }
  //const vertexData = new BABYLON.VertexData();
  //vertexData.positions = positions;
  const result = new InstancedMesh(
    "mounting",
    mountingsPositions.length,
    scene
  );
  result.setVerticesData(VertexBuffer.PositionKind, positions, false);
  const mPositions: number[] = [];
  for (let i = 0; i < mountingsPositions.length; i++) {
    const mountingsPosition = mountingsPositions[i];
    mPositions.push(mountingsPosition.x);
    mPositions.push(mountingsPosition.y);
  }
  const instancesBuffer = new Buffer(
    scene.getEngine(),
    mPositions,
    false,
    2,
    false,
    true
  );
  (result as any)._instancesBuffer = instancesBuffer;
  const positionsVertexBuffer = instancesBuffer.createVertexBuffer(
    "instancePos",
    0,
    2
  );
  result.setVerticesBuffer(positionsVertexBuffer);
  result._unIndexed = true;
  return result;
}

export function isMountingCorrectlyPlaced(
  mountingAttachment: MountingAttachment,
  shapeWithHoles: ShapeWithHoles,
  materialThickness: number = 0
): boolean {
  switch (mountingAttachment.mountingRef.positioning) {
    case MountingPositioningType.edgeCutout:
    case MountingPositioningType.edgeExtension:
      return anySegment(shapeWithHoles, (segment) =>
        isPointOnSegment(mountingAttachment.position, segment)
      );
    case MountingPositioningType.free:
      const c = Math.cos(mountingAttachment.rotation);
      const s = Math.sin(mountingAttachment.rotation);
      const hX =
        mountingAttachment.mountingRef.length / 2000 + materialThickness * 2;
      const hY =
        mountingAttachment.mountingRef.width / 2000 + materialThickness * 2;
      const controlPoints: Vector2[] = [
        {
          x: c * hX - s * hY + mountingAttachment.position.x,
          y: s * hX + c * hY + mountingAttachment.position.y,
        },
        {
          x: c * hX - s * -hY + mountingAttachment.position.x,
          y: s * hX + c * -hY + mountingAttachment.position.y,
        },
        {
          x: c * -hX - s * -hY + mountingAttachment.position.x,
          y: s * -hX + c * -hY + mountingAttachment.position.y,
        },
        {
          x: c * -hX - s * hY + mountingAttachment.position.x,
          y: s * -hX + c * hY + mountingAttachment.position.y,
        },
        {
          x: mountingAttachment.position.x,
          y: mountingAttachment.position.y,
        },
      ];

      for (const cp of controlPoints) {
        if (!IsPointInsideOfShape(cp, shapeWithHoles.conture)) {
          return false;
        }
      }

      for (const cp of controlPoints) {
        for (const hole of shapeWithHoles.holes) {
          if (IsPointInsideOfShape(cp, hole)) {
            return false;
          }
        }
      }
      return true;
    default:
      throw new Error("unknown type of mounting positioning type");
      return false;
  }
  return false;
}

export function groupByShape(
  mountingAttachments: MountingAttachment[]
): MountingAttachment[][] {
  return mountingAttachments.reduce<MountingAttachment[][]>(
    (groups, mountingAttachment) => {
      const group = groups.find(
        (ams) =>
          ams[0].shape === mountingAttachment.shape &&
          ams[0].rotation === mountingAttachment.rotation &&
          ams[0].mountingRef.id === mountingAttachment.mountingRef.id
      );
      if (!!group) {
        group.push(mountingAttachment);
      } else {
        groups.push([mountingAttachment]);
      }
      return groups;
    },
    []
  );
}
