import { ChangeType, MeasurementVisualizer, Webcad } from "webcad";
import { Vector3, sqrDistanceVector2 } from "webcad/math";
import { ObjectUnderPoint } from "webcad/models/ObjectUnderPoint";
import { CameraModel } from "webcad/models/camera.model";
import { detectChangesInMap } from "webcad/utils";
import { ModelVisualizer } from "webcad/visualizers";
import { MeasurementsViewModel } from "../model/view-model/measurements.viewModel";
import { Node } from "@babylonjs/core";
export class MeasurementsVisualizer
  implements ModelVisualizer<MeasurementsViewModel>
{
  private model: MeasurementsViewModel;
  private cameraModel: CameraModel;
  private rootNode: Node;
  private webcad: Webcad;
  private measurementsVisualizers: Map<number, MeasurementVisualizer>;
  private automaticMeasurementsVisualizers: Map<number, MeasurementVisualizer>;
  constructor() {
    this.measurementsVisualizers = new Map<number, MeasurementVisualizer>();
    this.automaticMeasurementsVisualizers = new Map<
      number,
      MeasurementVisualizer
    >();
  }

  init(
    rootNode: Node,
    model: MeasurementsViewModel,
    webcad: Webcad
  ): Promise<void> {
    this.rootNode = rootNode;
    this.model = model;
    this.webcad = webcad;
    this.cameraModel = this.webcad.viewState.camera;
    return Promise.resolve();
  }
  /*
  private addVisualizer(key:number, model:MeasurementViewModel){
    const newVisualizer: MeasurementVisualizer = new MeasurementVisualizer();
    newVisualizer.init(this.rootNode, model, this.webcad);
    this.measurementsVisualizers.set(key, newVisualizer);
  }

  private removeVisualizer(key:number){
    const measurementVisualizer = this.measurementsVisualizers.get(key);
    if(measurementVisualizer) {
      this.measurementsVisualizers.get(key).dispose();
      this.measurementsVisualizers.delete(key);
    }

  }*/
  updateVisualization(newModel: MeasurementsViewModel): void {
    // console.log(newModel.measurementsMask);
    // console.log(newModel.visible);
    const newMeasurementsMask = newModel.visible
      ? newModel.measurementsMask
      : 0x0;
    const oldMeasurementsMask = this.model.visible
      ? this.model.measurementsMask
      : 0x0;
    detectChangesInMap(
      this.model.measurements,
      newModel.measurements,
      (type, key) => {
        switch (type) {
          case ChangeType.Added:
            const newVisualizer: MeasurementVisualizer =
              new MeasurementVisualizer();
            newVisualizer.init(
              this.rootNode,
              {
                viewMask: newMeasurementsMask,
                model: newModel.measurements.get(key),
              },
              this.webcad
            );
            this.measurementsVisualizers.set(key, newVisualizer);
            break;
          case ChangeType.Changed:
            this.measurementsVisualizers.get(key).updateVisualization({
              viewMask: newMeasurementsMask,
              model: newModel.measurements.get(key),
            });
            break;
          case ChangeType.Removed:
            const measurementVisualizer = this.measurementsVisualizers.get(key);
            if (measurementVisualizer) {
              this.measurementsVisualizers.get(key).dispose();
              this.measurementsVisualizers.delete(key);
            }
            break;
        }
      },
      (key) => {
        if (
          this.cameraModel !== this.webcad.viewState.camera ||
          oldMeasurementsMask !== newMeasurementsMask
        ) {
          this.measurementsVisualizers.get(key).updateVisualization({
            viewMask: newMeasurementsMask,
            model: newModel.measurements.get(key),
          });
        }
      }
    );

    const newAutomaticMeasurementsMask = newModel.visible
      ? newModel.measurementsMask
      : 0x0;
    const oldAutomaticMeasurementsMask = this.model.visible
      ? this.model.measurementsMask
      : 0x0;
    detectChangesInMap(
      this.model.automaticMeasurements,
      newModel.automaticMeasurements,
      (type, key) => {
        switch (type) {
          case ChangeType.Added:
            const newVisualizer: MeasurementVisualizer =
              new MeasurementVisualizer();
            newVisualizer.init(
              this.rootNode,
              {
                viewMask: newAutomaticMeasurementsMask,
                model: newModel.automaticMeasurements.get(key),
              },
              this.webcad
            );
            this.automaticMeasurementsVisualizers.set(key, newVisualizer);
            break;
          case ChangeType.Changed:
            this.automaticMeasurementsVisualizers.get(key).updateVisualization({
              viewMask: newAutomaticMeasurementsMask,
              model: newModel.automaticMeasurements.get(key),
            });
            break;
          case ChangeType.Removed:
            this.automaticMeasurementsVisualizers.get(key).dispose();
            this.automaticMeasurementsVisualizers.delete(key);
            break;
        }
      },
      (key) => {
        if (
          this.cameraModel !== this.webcad.viewState.camera ||
          newAutomaticMeasurementsMask !== oldAutomaticMeasurementsMask
        ) {
          this.automaticMeasurementsVisualizers.get(key).updateVisualization({
            viewMask: newAutomaticMeasurementsMask,
            model: newModel.automaticMeasurements.get(key),
          });
        }
      }
    );
    /*
    if (newModel.state !== this.model.state) {
      const keys = Array.from(newModel.measurements.keys());
      for (const k of keys) {
        const measurement = newModel.measurements.get(k);
        newModel.measurements.set(k, {...measurement, visible: newModel.state});
        const measurementVisualizer = this.measurementsVisualizers.get(k);
        if(measurementVisualizer) {
          measurementVisualizer.updateVisualization(newModel.measurements.get(k));
        }
      }
      const aKeys = Array.from(newModel.automaticMeasurements.keys());
      for (const k of aKeys) {
        const measurement = newModel.automaticMeasurements.get(k);
        newModel.automaticMeasurements.set(k, {...measurement, visible: newModel.state});
        this.automaticMeasurementsVisualizers.get(k).updateVisualization(newModel.automaticMeasurements.get(k));
      }
    }
    */

    this.model = newModel;
    this.cameraModel = this.webcad.viewState.camera;
  }

  dispose(): void {}

  getObjectUnderPoint(point: Vector3, maxDist: number): ObjectUnderPoint {
    const objects: ObjectUnderPoint[] = [];
    this.measurementsVisualizers.forEach((v, k, m) => {
      const obj = v.getObjectUnderPoint(point, maxDist);
      if (obj) {
        objects.push(obj);
      }
    });
    let closestDist = Number.POSITIVE_INFINITY;
    let closest: ObjectUnderPoint = null;
    for (const o of objects) {
      const dist = sqrDistanceVector2(point, o.point);
      if (dist < closestDist) {
        closestDist = dist;
        closest = o;
      }
    }
    return closest;
  }
}
