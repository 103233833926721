import { select, Store } from "@ngrx/store";
import { Observable, pipe, Subject, Subscription } from "rxjs";
import {
  getLeftPerpendicularVector2,
  MeasurementModelManager,
  MeasurementsManager,
  SegmentType,
  subVectors2,
  Vector3,
  Webcad,
} from "webcad";
import { PointerState } from "webcad/collision";
import {
  addVectors3,
  multiplyVector3byScalar,
  normalizeVector3,
  subVectors3,
  Vector2,
} from "webcad/math";
import { AngleModelManager } from "webcad/measurements";
import { Segment } from "webcad/models";
import { ActionType, createNode, Plate, PointNode } from "../../../model";
import { ShapeOrigin } from "../../../model/drawing.model";
import { MeasurementsManagerProvider } from "../../../providers/measurements-manager.provider";
import {
  checkForClosestNode,
  ClosestSegments,
} from "../../../providers/mevaco-pointer.provider";
import { SceneProvider } from "../../../providers/scene.provider";
import { ToolProvider } from "../../../providers/tool.provider";
import { TranslationProvider } from "../../../providers/translation.provider";
import { WebcadProvider } from "../../../providers/webcad.provider";
import { AddNode, getNodesState, getPlate, MevacoState } from "../../../store";
import {
  RequestRender,
  SetHintMessage,
} from "../../../store/actions/drawing.actions";
import { createLineWithDepthOffset } from "../../../visualizers/line-system";
import { DrawingTool } from "../../drawing-tool.interface";
import { getNewEndPosition, updateXYMeasurements } from "../../utils";
import {LinesMesh, Node, Scene, Vector3 as B_Vector3, Color4 as B_Color4} from "@babylonjs/core";
export class LineTool extends DrawingTool {
  public mode: ActionType = ActionType.REMOVE;
  private beginPosition: Vector3;
  private endPosition: Vector3;
  private currentMesh: LinesMesh;
  private nodes: PointNode[];
  private rootNode: Node;
  private measurementModel: MeasurementModelManager;
  private point: Vector3;
  private maxLength: number;
  private scene: Scene;
  private webcad: Webcad;
  private measurementManager: MeasurementsManager;
  private verticalMeasurement: MeasurementModelManager;
  private horizontalMeasurement: MeasurementModelManager;
  private angleMeasurement: AngleModelManager;
  private maxWidth: number;
  private maxHeight: number;
  private lineSub: Subscription;
  private closestSegments: ClosestSegments;
  private lastMousePoint: Vector3;
  private onSegmentDone: Subject<Segment> = new Subject<Segment>();

  constructor(
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private webcadProvider: WebcadProvider,
    private measurementManagerProvider: MeasurementsManagerProvider,
    private toolProvider: ToolProvider,
    private translationProvider: TranslationProvider
  ) {
    super();
    this.sceneProvider.getSubscription().subscribe((value) => {
      this.scene = value;
      if (this.scene) {
        this.rootNode = new Node("measurmentNode", this.scene);
      }
    });
    this.webcadProvider.getObservable().subscribe((value) => {
      this.webcad = value;
    });
    this.measurementManagerProvider.getSubsciption().subscribe((value) => {
      if (value) {
        this.measurementManager = value;
        this.disposeMeasurements();
      }
    });
    this.init();
  }

  public segmentDoneObservable(): Observable<Segment> {
    return this.onSegmentDone.asObservable();
  }

  isDirty(): boolean {
    return this._dirty;
  }

  onMouseDown(pointerState: PointerState) {}

  onMouseUp(pointerState: PointerState) {}

  onClosestSegmentsChanged(segments: ClosestSegments) {
    this.closestSegments = segments;
    if (this.point) {
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    }
  }

  setSegmentLength(value: number): void {
    if (this.currentMesh) {
      if (this.point && this.beginPosition) {
        const end = getNewEndPosition(this.measurementModel, value);
        this.point = end;
        this.updateClosestSegments(this.point);
        this.updateXYMeasurements(
          this.horizontalMeasurement,
          this.verticalMeasurement,
          this.point
        );
        this.visualizeLineTo(end);
        this.store.dispatch(new RequestRender());
      }
    }
  }

  setSegmentAngle(value: number): void {
    if (this.currentMesh) {
      if (this.point && this.beginPosition) {
        const measureModel = this.measurementModel.getModel();
        const l = measureModel.exchange.value;
        const s = measureModel.start;
        const dir: Vector3 = { x: Math.cos(value), y: Math.sin(value), z: 0 };
        const end: Vector3 = addVectors3(s, multiplyVector3byScalar(dir, l));
        this.point = end;
        this.updateClosestSegments(this.point);
        this.updateXYMeasurements(
          this.horizontalMeasurement,
          this.verticalMeasurement,
          this.point
        );
        this.visualizeLineTo(end);
        this.store.dispatch(new RequestRender());
      }
    }
  }

  setVertical(value: number): void {
    if (!this.beginPosition) {
      const point = this.verticalMeasurement.getStart();
      const wall = this.verticalMeasurement.getEnd();
      const moveDir = normalizeVector3(subVectors3(point, wall));
      const newPoint = addVectors3(
        wall,
        multiplyVector3byScalar(moveDir, value)
      );
      this.point = { x: newPoint.x, y: newPoint.y, z: newPoint.z };
      this.updateClosestVerticalSegment(this.point);
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    } else {
      const wall = this.verticalMeasurement.getEnd();
      const moveDir = normalizeVector3(subVectors3(this.point, wall));
      const newPoint = addVectors3(
        wall,
        multiplyVector3byScalar(moveDir, value)
      );
      this.point = { x: newPoint.x, y: newPoint.y, z: newPoint.z };
      this.updateClosestVerticalSegment(this.point);
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
      this.visualizeLineTo(this.point);
    }
    this.store.dispatch(new RequestRender());
  }

  setHorizontal(value: number): void {
    if (!this.beginPosition) {
      const point = this.horizontalMeasurement.getStart();
      const wall = this.horizontalMeasurement.getEnd();
      const moveDir = normalizeVector3(subVectors3(point, wall));
      const newPoint = addVectors3(
        wall,
        multiplyVector3byScalar(moveDir, value)
      );
      this.point = { x: newPoint.x, y: newPoint.y, z: newPoint.z };
      this.updateClosestHorizontalSegment(this.point);
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    } else {
      const wall = this.horizontalMeasurement.getEnd();
      const moveDir = normalizeVector3(subVectors3(this.point, wall));
      const newPoint = addVectors3(
        wall,
        multiplyVector3byScalar(moveDir, value)
      );
      this.point = { x: newPoint.x, y: newPoint.y, z: newPoint.z };
      this.visualizeLineTo(this.point);
      this.updateClosestHorizontalSegment(this.point);
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    }
    this.store.dispatch(new RequestRender());
  }

  activate() {
    this.store.dispatch(
      new SetHintMessage(this.translationProvider.translate("startLineTool"))
    );
    this.store.pipe(pipe(select(getPlate))).subscribe((plate: Plate) => {
      this.maxLength = Math.max(plate.height, plate.width);
      this.maxHeight = plate.height;
      this.maxWidth = plate.width;
    });
    if (!this.measurementModel) {
      this.measurementModel = this.measurementManager.getMeasurementModel();
      this.measurementModel.setInputCallbacks(
        (value: number) => {
          this.setSegmentLength(value);
          return value;
        },
        (value: number) => {
          this.setSegmentLength(value);
          this.applyCurrentSegment();
          return value;
        }
      );
    }

    if (!this.angleMeasurement) {
      this.angleMeasurement =
        this.measurementManager.getAngleMeasurementModel();
      this.angleMeasurement.setInputCallbacks(
        (value: number) => {
          this.setSegmentAngle(value);
          return value;
        },
        (value: number) => {
          this.setSegmentAngle(value);
          this.applyCurrentSegment();
          return value;
        }
      );
    }

    if (!this.verticalMeasurement) {
      this.verticalMeasurement = this.measurementManager.getMeasurementModel();
      this.verticalMeasurement.setInputCallbacks(
        (value: number) => {
          this.setVertical(value);
          return value;
        },
        (value: number) => {
          this.setVertical(value);
          this.applyCurrentSegment();
          return value;
        }
      );
      this.verticalMeasurement.setVisible(false);
    }
    if (!this.horizontalMeasurement) {
      this.horizontalMeasurement =
        this.measurementManager.getMeasurementModel();
      this.horizontalMeasurement.setInputCallbacks(
        (value: number) => {
          this.setHorizontal(value);
          return value;
        },
        (value: number) => {
          this.setHorizontal(value);
          this.applyCurrentSegment();
          return value;
        }
      );
      this.horizontalMeasurement.setVisible(false);
    }
  }

  init() {
    this.store.pipe(select(getNodesState)).subscribe((nodes) => {
      if (nodes) {
        this.nodes = nodes;
      }
    });
  }

  onMouseMove(pointerState: PointerState) {
    if (!this.measurementModel) {
      this.activate();
    }
    if (!this.verticalMeasurement) {
      this.activate();
    }
    if (!this.horizontalMeasurement) {
      this.activate();
    }
    this.updateXYMeasurements(
      this.horizontalMeasurement,
      this.verticalMeasurement,
      pointerState.position
    );
    this.point = pointerState.position;
    this.lastMousePoint = { ...pointerState.position };
    if (this.beginPosition) {
      this.visualizeLineTo(pointerState.position);
    }
  }

  private updateXYMeasurements(
    XMeasurement: MeasurementModelManager,
    YMeasurement: MeasurementModelManager,
    point: Vector3
  ) {
    updateXYMeasurements(
      XMeasurement,
      YMeasurement,
      point,
      this.closestSegments
    );
  }

  private updateClosestSegments(point: Vector3) {
    this.updateClosestHorizontalSegment(point);
    this.updateClosestVerticalSegment(point);
  }

  private updateClosestVerticalSegment(point: Vector3) {
    this.closestSegments.verticalSegment =
      this.toolProvider.getNearestVerticalSegmentToPoint(point);
  }

  private updateClosestHorizontalSegment(point: Vector3) {
    this.closestSegments.horizontalSegment =
      this.toolProvider.getNearestHorizontalSegmentToPoint(point);
  }

  private visualizeLineTo(point: Vector3) {
    if (this.currentMesh) {
      this.currentMesh.dispose();
    }
    if (this.beginPosition) {
      this.endPosition = point;
      const start = new B_Vector3(
        this.beginPosition.x,
        this.beginPosition.y,
        this.beginPosition.z
      );
      const end = new B_Vector3(
        this.endPosition.x,
        this.endPosition.y,
        this.beginPosition.z
      );
      const dirV2 = getLeftPerpendicularVector2(subVectors2(end, start));
      const dir = new B_Vector3(
        dirV2.x,
        dirV2.y,
        this.beginPosition.z
      ).normalize();
      const points: B_Vector3[] = [start, end];
      const red = new B_Color4(0.2, 0.2, 0.7, 1);
      const colors = [red, red];
      this.currentMesh = createLineWithDepthOffset(
        "currentLine",
        { points: points, colors: colors },
        this.scene,
        -0.0006
      );
      this.updateMeasurmentVisualization(end, dir);
    }
  }

  updateMeasurmentVisualization(end: Vector3, direction: Vector3) {
    if (this.measurementModel) {
      this.measurementModel.updateMeasurement(
        this.beginPosition,
        end,
        direction,
        this.maxLength,
        true
      );
    }
    if (this.angleMeasurement) {
      this.angleMeasurement.updateMeasurement(this.beginPosition, end);
    }
  }

  onMouseClick(pointerState: PointerState) {
    this.applyCurrentSegment();
  }

  setBeginNode() {
    this.beginPosition =
      checkForClosestNode(this.nodes, this.point).position || this.point;
  }

  applyCurrentSegment() {
    if (!!this.beginPosition) {
      if (this.beginPosition) {
        const beginNode = createNode(this.beginPosition, ShapeOrigin.TEMPORARY);
        this.store.dispatch(new AddNode(beginNode));
      }
      this.endPosition = this.point;
      const endNode = createNode(
        {
          x: this.endPosition.x,
          y: this.endPosition.y,
          z: this.endPosition.z,
        },
        ShapeOrigin.TEMPORARY
      );
      this.store.dispatch(new AddNode(endNode));
      const newLine: Segment = {
        end: this.endPosition,
        begin: this.beginPosition,
        type: SegmentType.line,
      };
      this.onSegmentDone.next(newLine);
      if (this.measurementModel) {
        this.measurementModel.setVisible(false);
      }
      if (this.angleMeasurement) {
        this.angleMeasurement.setVisible(false);
      }
      this.store.dispatch(
        new SetHintMessage(this.translationProvider.translate("endLineTool"))
      );
    } else {
      this.setBeginNode();
      this.store.dispatch(new SetHintMessage(this.translate("optionLineTool")));
    }
    if (this.measurementModel) {
      this.point = this.lastMousePoint;
      this.visualizeLineTo(this.point);
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    }
    this._dirty = true;
  }

  startNextLine(
    beginPosition: Vector3,
    previousLineDirection: Vector2 = { x: 1, y: 0 }
  ) {
    this.beginPosition = beginPosition;
    this.endPosition = null;
  }

  reset() {
    this.onCancel();
    this.activate();
  }

  private disposeMesh() {
    if (this.currentMesh) {
      this.currentMesh.dispose();
      this.currentMesh = null;
    }
  }

  disposeStartMeasurements() {
    if (this.horizontalMeasurement) {
      this.horizontalMeasurement.disposeModel();
      this.horizontalMeasurement = null;
    }
    if (this.verticalMeasurement) {
      this.verticalMeasurement.disposeModel();
      this.verticalMeasurement = null;
    }
  }

  disposeMeasurements() {
    if (this.measurementModel) {
      this.measurementModel.disposeModel();
      this.measurementModel = null;
    }
    if (this.angleMeasurement) {
      this.angleMeasurement.disposeModel();
      this.angleMeasurement = null;
    }
    this.disposeStartMeasurements();
  }

  onCancel() {
    this.disposeMesh();
    this.disposeMeasurements();
    this.endPosition = null;
    this.beginPosition = null;
    this.point = null;
    if (this.lineSub) {
      this.lineSub.unsubscribe();
    }
    this._dirty = false;
  }

  onConfirm() {
    this.applyCurrentSegment();
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }
}
