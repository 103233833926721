import { select, Store } from "@ngrx/store";
import { BehaviorSubject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { PointerState } from "webcad/collision";
import { InstancedMesh } from "webcad/core/instanced-mesh";
import { sqrDistanceVector2, Vector2 } from "webcad/math";
import {
  aabbCenter,
  aabbOfPolyline,
  createAabb2FromTwoPoints,
  getShortestPathBetweenTwoPolylines,
  isAabbInAabb,
  IsPointInsideOfShape,
  movePolyline,
  Segment,
} from "webcad/models";
import {
  createMountingMeshFrom,
  groupByShape,
  isMountingCorrectlyPlaced,
  Mounting,
  MountingAttachment,
  scaleMounting,
} from "../../../model/mounting.model";
import { ConfigurationMaterial } from "../../../model/product-configuration/configuration-material.model";
import { ShapeWithHoles } from "../../../model/shape-with-holes";
import { SegmentCollider } from "../../../providers/colliders/segment.collider";
import { ClosestSegments } from "../../../providers/mevaco-pointer.provider";
import { SceneProvider } from "../../../providers/scene.provider";
import { TranslationProvider } from "../../../providers/translation.provider";
import { SetHintMessage } from "../../../store/actions/drawing.actions";
import { SetError, SetSuccess } from "../../../store/actions/errorable.actions";
import { AddMountings } from "../../../store/actions/mountings.actions";
import {
  getImportedShape,
  getMaterial,
  getMountingsModel,
  getPerforationArea,
  getPlate,
  getShapeWithHoles,
  MevacoState,
} from "../../../store/reducers";
import { createLineWithDepthOffset } from "../../../visualizers/line-system";
import { Tool } from "../../tool.interface";
import {AbstractMesh, BoundingInfo, Color4, Effect, Scene, ShaderMaterial, Vector3, Vector4} from "@babylonjs/core";
export class AttachmentImportTool extends Tool {
  public get anySelectedPosition(): boolean {
    return this.positions.value.length > 0;
  }

  constructor(
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private translationProvider: TranslationProvider
  ) {
    super();
    this.store.pipe(select(getImportedShape)).subscribe((v) => {
      this.importedShape = v;
    });

    this.store.pipe(select(getPerforationArea)).subscribe((v) => {
      this.perforationAreas = v || [];
    });

    this.store.pipe(select(getShapeWithHoles)).subscribe((v) => {
      this.shapeWithHoles = v;
    });

    this.store.pipe(select(getShapeWithHoles)).subscribe((v) => {
      this.plateContour = v.conture;
    });
    this.store.pipe(select(getPlate)).subscribe((plate) => {
      this.depth = plate.depth;
    });
    this.sceneProvider.getSubscription().subscribe((v) => {
      if (!!this.visualizedSelectedMountings) {
        this.visualizedSelectedMountings.dispose();
      }
      this.visualizedSelectedMountings = null;
      if (!!this.visualizedAddedMountings) {
        for (const m of this.visualizedAddedMountings) {
          m.dispose();
        }
        this.visualizedAddedMountings = [];
      }
      if (this.selectedMountingMaterial) {
        this.selectedMountingMaterial.dispose();
      }
      if (this.adddedMountingMaterial) {
        this.adddedMountingMaterial.dispose();
      }
      if (v) {
        this.scene = v;
        this.selectedMountingMaterial = new ShaderMaterial(
          "shader",
          this.scene,
          {
            vertex: "mountings",
            fragment: "customColor",
          },
          {
            attributes: ["position", "instancePos"],
          }
        );
        this.selectedMountingMaterial.setVector4(
          "matColor",
          new Vector4(0.0, 0.0, 1.0, 1)
        );

        this.adddedMountingMaterial = new ShaderMaterial(
          "shader",
          this.scene,
          {
            vertex: "mountings",
            fragment: "customColor",
          },
          {
            attributes: ["position", "instancePos"],
          }
        );
        this.adddedMountingMaterial.setVector4(
          "matColor",
          new Vector4(0.0, 1.0, 0.0, 1)
        );
        this.visualizeCurrentSelectedMountings();
        this.visualizeAddedMountings();
      }
    });
    this.rotation = new BehaviorSubject(0);
    this.store.pipe(select(getMaterial)).subscribe((v) => {
      this.material = v;
      this.materialCode = v && v.materialcodeLomoe;
    });
  }
  private mountingModelSub: Subscription;
  private mountingModel: Mounting;
  private mountingVertices: Vector2[];
  private scene: Scene;
  private depth: number;
  private importedShape: Segment[][];
  private perforationAreas: ShapeWithHoles[];
  private plateContour: Segment[];
  private shapeWithHoles: ShapeWithHoles;
  private positions: BehaviorSubject<Vector2[]> = new BehaviorSubject([]);
  public anySelectedPositionObservable = this.positions.pipe(
    map((positions) => positions.length > 0)
  );
  public rotation: BehaviorSubject<number>;
  private mountingsToImport: MountingAttachment[] = [];
  private visualizedSelectedMountings: InstancedMesh = null;
  private selectedMountingMaterial: ShaderMaterial;
  private adddedMountingMaterial: ShaderMaterial;
  private visualizedAddedMountings: InstancedMesh[] = [];
  private mountingShape: Segment[];
  private materialCode: string;
  private material: ConfigurationMaterial;
  private realMountingShape: Segment[];

  selectingStarted = false;
  startingPointPosition: { x: number; y: number };
  segmentsToRender: Segment[] = [];

  setRotation(newRotation: string) {
    const num: number = Number(newRotation);
    if (!isNaN(num) && newRotation !== "") {
      const rads = (num * Math.PI) / 180;
      this.rotation.next(rads);
      this.realMountingShape = scaleMounting(
        this.mountingModel,
        this.mountingShape,
        rads
      );
      this.visualizeCurrentSelectedMountings();
    }
  }

  activate() {
    this.store.dispatch(
      new SetHintMessage(
        this.translationProvider.translate("startAttachmentImportHint")
      )
    );
    this.mountingModelSub = this.store
      .pipe(select(getMountingsModel))
      .subscribe((v) => {
        if (v && v.mounting.form !== null && v.mounting.width !== null) {
          this.mountingModel = v.mounting;
          this.mountingVertices = v.verticesMap.get(this.mountingModel.form);
          this.mountingShape = v.shapeMap.get(this.mountingModel.form);
          this.realMountingShape = scaleMounting(
            this.mountingModel,
            this.mountingShape,
            this.rotation.getValue()
          );
          this.visualizeCurrentSelectedMountings();
        }
      });
  }

  onCancel() {
    if (!!this.mountingModelSub) {
      this.mountingModelSub.unsubscribe();
    }
    this.mountingModelSub = null;
    if (!!this.visualizedSelectedMountings) {
      this.visualizedSelectedMountings.dispose();
    }
    this.visualizedSelectedMountings = null;

    for (const m of this.visualizedAddedMountings) {
      m.dispose();
    }
    this.visualizedAddedMountings = [];
  }

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {}

  onConfirm() {
    this.store.dispatch(new AddMountings(this.mountingsToImport));
    this.positions.next([]);
    this.mountingsToImport = [];
    this.visualizeCurrentSelectedMountings();
    this.visualizeAddedMountings();
  }

  public addMountings() {
    const mas: MountingAttachment[] = this.positions.value.map((v) => {
      return {
        position: v,
        vertices: this.mountingVertices,
        shape: this.mountingShape,
        mountingRef: this.mountingModel,
        rotation: this.rotation.getValue(),
        possible: true,
      };
    });
    this.mountingsToImport.push(...mas);
    this.positions.next([]);
    this.store.dispatch(
      new SetSuccess(
        `${this.translationProvider.translate(
          "number of imported mounting holes"
        )}: ${this.mountingsToImport.length}`
      )
    );
    this.store.dispatch(
      new SetHintMessage(
        this.translationProvider.translate("startAttachmentImportHint")
      )
    );
    this.visualizeCurrentSelectedMountings();
    this.visualizeAddedMountings();
  }

  onMouseClick(pointerState: PointerState) {
    if (pointerState.intersection) {
      if ((pointerState.intersection.collider as SegmentCollider).segment) {
        if (
          this.mountingModel &&
          this.mountingModel.form &&
          this.mountingModel.width &&
          this.scene
        ) {
          const segment = (
            pointerState.intersection.collider as SegmentCollider
          ).segment;
          this.editSelection(segment);
          this.visualizeCurrentSelectedMountings();
          if (this.positions.value.length > 0) {
            this.store.dispatch(
              new SetHintMessage(this.translate("addAttachmentImportHint"))
            );
          }
        } else {
          this.store.dispatch(
            new SetError(this.translate("NoMountingModelError"))
          ); // Please Set Attachment Model
        }
      }
    }
  }
  onMouseDown(pointerState: PointerState) {
    this.segmentsToRender = [];
    this.selectingStarted = true;
    this.startingPointPosition = pointerState.position;
  }

  onMouseMove(pointerState: PointerState) {
    if (this.selectingStarted) {
      this.drawMarker(pointerState);
      this.segmentsToRender = [];
      this.positions.next([]);
    }
  }

  onMouseUp(pointerState: PointerState) {
    this.selectingStarted = false;
    this.makeSelection(pointerState);
    this.clearLines();
    /// stop drawing on canvas, clear
    const filteredSegmentsToRender = this.removeDuplicates(
      this.segmentsToRender
    );
    if (
      this.mountingModel &&
      this.mountingModel.form &&
      this.mountingModel.width &&
      this.scene
    ) {
      filteredSegmentsToRender.forEach((segment: Segment) => {
        this.editSelection(segment, true);
      });
      this.visualizeCurrentSelectedMountings();
    } else {
      this.store.dispatch(new SetError(this.translate("NoMountingModelError"))); // Please Set Attachment Model
    }
  }

  private drawMarker(pointerState: PointerState) {
    const posA = this.startingPointPosition;
    const posB = pointerState.position;
    const colors = [
      new Color4(1, 0, 0, 1),
      new Color4(1, 0, 0, 1),
      new Color4(1, 0, 0, 1),
      new Color4(1, 0, 0, 1),
      new Color4(1, 0, 0, 1),
    ];

    const points: Vector3[] = [
      new Vector3(posA.x, posA.y),
      new Vector3(posB.x, posA.y),
      new Vector3(posB.x, posB.y),
      new Vector3(posA.x, posB.y),
      new Vector3(posA.x, posA.y),
    ];

    this.clearLines();
    createLineWithDepthOffset(
      "selectionRect",
      { points, colors },
      this.scene,
      -0.0006
    );
    this.scene.render();
  }

  private makeSelection(pointerState: PointerState): void {
    const posA = this.startingPointPosition;
    const posB = pointerState.position;
    if (!posA || !posB) {
      return;
    }
    const selectionAabb = createAabb2FromTwoPoints(posA, posB);
    for (let i = 0; i < this.importedShape.length; i++) {
      const shape = this.importedShape[i];
      const aabb = aabbOfPolyline(shape);
      if (isAabbInAabb(selectionAabb, aabb)) {
        const pos = aabbCenter(aabb);
        if (
          !this.perforationAreas.some((perforationArea) =>
            IsPointInsideOfShape(pos, perforationArea.conture)
          )
        ) {
          this.segmentsToRender.push(shape[0]);
        }
      }
    }
  }

  private clearLines(): void {
    if (this.scene.getMeshByName("selectionRect") != null) {
      this.scene.removeMesh(this.scene.getMeshByName("selectionRect"));
    }
  }

  reset() {
    if (!!this.visualizedSelectedMountings) {
      this.visualizedSelectedMountings.dispose();
    }
    this.visualizedSelectedMountings = null;
    for (const m of this.visualizedAddedMountings) {
      m.dispose();
    }
    this.visualizedAddedMountings = [];
  }

  editSelection(segment: Segment, doNotRemove: boolean = false) {
    for (const c of this.importedShape) {
      for (const s of c) {
        if (s === segment) {
          const aabb = aabbOfPolyline(c);
          const p = {
            x: (aabb.max.x + aabb.min.x) / 2,
            y: (aabb.max.y + aabb.min.y) / 2,
          };
          let index = this.positions.value.findIndex(
            (v) => sqrDistanceVector2(v, p) < 0.00005
          );
          let removed = false;
          if (doNotRemove && index !== -1) {
            continue;
          }
          removed = this.removeMountingFromSelectionsByIndex(index);
          if (!removed) {
            index = this.mountingsToImport.findIndex(
              (v) => sqrDistanceVector2(v.position, p) < 0.00005
            );
            removed = this.removeMountingFromImportByIndex(index);
          }
          if (!removed) {
            if (this.validateMinMountingDistance(p)) {
              this.addMountingToSelections(p);
            } else {
              this.store.dispatch(
                new SetError(this.translate("MountingTooClose"))
              );
            }
          }
        }
      }
    }
  }

  thickness(): number {
    return this.material && this.material.materialThickness
      ? this.material.materialThickness / 1000
      : 0.001;
  }

  validateMinMountingDistance(position: Vector2): boolean {
    if (!!this.material && !!this.material.materialThickness) {
      const minDist = this.material.materialThickness / 500;
      const sqrDist = minDist * minDist;
      const globalShape = movePolyline(this.realMountingShape, position);
      if (!!this.plateContour) {
        const seg = getShortestPathBetweenTwoPolylines(
          globalShape,
          this.plateContour
        );
        const sqrDistFromSegment = sqrDistanceVector2(seg.begin, seg.end);
        return sqrDistFromSegment >= sqrDist;
      }
      return false;
    }
    return true;
  }

  addMountingToSelections(position: Vector2) {
    const isInPlate = isMountingCorrectlyPlaced(
      {
        position: position,
        vertices: this.mountingVertices,
        shape: this.mountingShape,
        mountingRef: this.mountingModel,
        rotation: this.rotation.getValue(),
        possible: true,
      },
      this.shapeWithHoles
    );
    if (isInPlate) {
      this.positions.next([...this.positions.value, position]);
    }
  }

  removeMountingFromSelections(position: Vector2): boolean {
    const index = this.positions.value.findIndex(
      (v) => sqrDistanceVector2(v, position) < 0.00005
    );
    return this.removeMountingFromSelectionsByIndex(index);
  }

  removeMountingFromSelectionsByIndex(index: number): boolean {
    if (index > -1) {
      const newPositions = [...this.positions.value];
      newPositions.splice(index, 1);
      this.positions.next(newPositions);
      return true;
    }
    return false;
  }

  removeMountingFromImportByIndex(index: number): boolean {
    if (index > -1) {
      this.mountingsToImport.splice(index, 1);
      return true;
    }
    return false;
  }

  removeMountingsFromImport(position: Vector2): boolean {
    const index = this.mountingsToImport.findIndex(
      (v) => sqrDistanceVector2(v.position, position) < 0.00005
    );
    return this.removeMountingFromImportByIndex(index);
  }

  visualizeCurrentSelectedMountings() {
    if (
      this.mountingModel &&
      this.mountingModel.form &&
      this.mountingModel.width &&
      this.scene
    ) {
      if (!!this.visualizedSelectedMountings) {
        this.visualizedSelectedMountings.dispose();
      }
      if (this.positions.value.length > 0) {
        const mesh = createMountingMeshFrom(
          this.mountingModel,
          this.mountingShape,
          this.scene,
          this.thickness(),
          this.rotation.getValue(),
          this.positions.value
        );
        mesh.material = this.selectedMountingMaterial;
        mesh.isPickable = false;
        mesh.renderingGroupId = 3;
        mesh.occlusionType = AbstractMesh.OCCLUSION_TYPE_NONE;
        mesh.setBoundingInfo(
          new BoundingInfo(
            new Vector3(
              -Number.MAX_VALUE,
              -Number.MAX_VALUE,
              -Number.MAX_VALUE
            ),
            new Vector3(
              Number.MAX_VALUE,
              Number.MAX_VALUE,
              Number.MAX_VALUE
            )
          )
        );
        this.visualizedSelectedMountings = mesh;
      }
    }
  }

  visualizeAddedMountings() {
    if (
      this.mountingModel &&
      this.mountingModel.form &&
      this.mountingModel.width &&
      this.scene
    ) {
      for (const m of this.visualizedAddedMountings) {
        m.dispose();
      }
      this.visualizedAddedMountings = [];
      const groups = groupByShape(this.mountingsToImport);
      for (const group of groups) {
        const mesh = createMountingMeshFrom(
          group[0].mountingRef,
          group[0].shape,
          this.scene,
          this.thickness(),
          group[0].rotation,
          group.map((m) => m.position)
        );
        mesh.material = this.adddedMountingMaterial;
        mesh.isPickable = false;
        mesh.renderingGroupId = 3;
        mesh.occlusionType = AbstractMesh.OCCLUSION_TYPE_NONE;
        mesh.setBoundingInfo(
          new BoundingInfo(
            new Vector3(
              -Number.MAX_VALUE,
              -Number.MAX_VALUE,
              -Number.MAX_VALUE
            ),
            new Vector3(
              Number.MAX_VALUE,
              Number.MAX_VALUE,
              Number.MAX_VALUE
            )
          )
        );
        this.visualizedAddedMountings.push(mesh);
      }
    }
  }

  private removeDuplicates(data) {
    const unique = data.reduce(function (a, b) {
      if (a.indexOf(b) < 0) {
        a.push(b);
      }
      return a;
    }, []);
    return unique;
  }

  isDirty() {
    return this._dirty;
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }
}

function createMountingsShaders() {
  if (!Effect.ShadersStore["mountingsVertexShader"]) {
    Effect.ShadersStore["mountingsVertexShader"] =
      "\r\n" +
      "precision highp float;\r\n" +
      "attribute vec3 position;\r\n" +
      "attribute vec2 instancePos;\r\n" +
      "uniform mat4 worldViewProjection;\r\n" +
      "uniform mat4 projection;\r\n" +
      "uniform mat4 worldView;\r\n" +
      "void main(void) {\r\n" +
      "    vec3 pos = vec3(position.x,position.y,position.z) + vec3(instancePos.x, instancePos.y, 0);\r\n" +
      "    gl_Position = worldViewProjection * vec4(pos, 1.0);\r\n" +
      "}\r\n";
  }
}
createMountingsShaders();
