import { Webcad } from 'webcad/core';
import { Vector3 } from 'webcad/math';
import { isAabb2Empty } from 'webcad/models';
import { ObjectUnderPoint } from 'webcad/models/ObjectUnderPoint';
import { SnappingGridViewModel } from '../model/view-model/snapping-grid-visualizer.viewModel';
import { createLineSystemWithDepthOffset } from './line-system';
import {Color4, DynamicTexture, Engine, InstancedMesh, LinesMesh, Mesh, Node, Vector3 as B_Vector3} from '@babylonjs/core';
import {ModelVisualizer} from 'webcad/visualizers';

export class SnappingGridVisualizer
  implements ModelVisualizer<SnappingGridViewModel> {
  private model: SnappingGridViewModel;
  private rootNode: Node;
  private webcad: Webcad;
  private currentGridMesh: LinesMesh;
  private plane: Mesh;
  private engine: Engine;
  private texture: DynamicTexture;
  private numberMeshes: InstancedMesh[] = [];
  private labelTemplates: Map<string, Mesh>;

  updateVisualization(newModel: SnappingGridViewModel): void {
    if (!newModel) {
      return;
    }
    if (
      this.model.snappingGrid !== newModel.snappingGrid ||
      this.model.state !== newModel.state ||
      this.model.plateAabb !== newModel.plateAabb
    ) {
      this.visualizeGrid(newModel);
    }
    this.model = newModel;
  }

  init(
    rootNode: Node,
    model: SnappingGridViewModel,
    webcad: Webcad
  ): Promise<void> {
    this.model = model;
    this.rootNode = rootNode;
    this.webcad = webcad;
    this.engine = this.rootNode.getEngine();
    this.labelTemplates = new Map<string, Mesh>();
    if (model.state) {
      // this.populateLabelTemplates();
      // rebuild shaders
      // rootNode.getScene().markAllMaterialsAsDirty(BABYLON.Material.TextureDirtyFlag | BABYLON.Material.MiscDirtyFlag);
      if (this.model) {
        this.visualizeGrid(this.model);
      }
    }
    return;
  }

  visualizeGrid(model: SnappingGridViewModel) {
    if (this.currentGridMesh) {
      this.currentGridMesh.dispose();
    }
    if (this.numberMeshes.length > 0) {
      for (const m of this.numberMeshes) {
        m.dispose();
      }
      this.numberMeshes = [];
    }
    if (!model.state) {
      // Grid is off so we are returning
      return;
    }
    const positions: B_Vector3[][] = [];
    const colors: Color4[][] = [];
    const blue = new Color4(0, 0, 1, 0.2);
    const darkGrey = new Color4(0.75, 0.75, 0.75, 0.2);

    const x = model.plateAabb
      ? isAabb2Empty(model.plateAabb)
        ? model.snappingGrid.size.x
        : model.plateAabb.max.x - model.plateAabb.min.x + 1
      : model.snappingGrid.size.x;
    const y = model.plateAabb
      ? isAabb2Empty(model.plateAabb)
        ? model.snappingGrid.size.y
        : model.plateAabb.max.y - model.plateAabb.min.y + 1
      : model.snappingGrid.size.y;
    const max = Math.max(x, y);
    const snapMin = Math.min(
      model.snappingGrid.snapX,
      model.snappingGrid.snapY
    );
    const numberOfVerticalLines = Math.ceil(max / snapMin);
    // vertical lines
    for (let i = -numberOfVerticalLines; i <= numberOfVerticalLines; i++) {
      const xPos = i * snapMin;
      const color =
        i % Math.round(model.snappingGrid.majorLineFrequency / snapMin) === 0
          ? blue
          : darkGrey;
      positions.push([
        new B_Vector3(xPos, -max, 0),
        new B_Vector3(xPos, max, 0),
      ]);
      colors.push([color, color]);
      /*
      if (color === blue) {
        const text = (i * model.snapX * 1000).toFixed(0);
        this.generateLabelForGrid(model, text, {x: xPos, y: 0, z: 0});
      }*/
    }

    // horizontal lines

    const numberOfHorizontalLines = Math.ceil(max / snapMin);
    for (let i = -numberOfHorizontalLines; i <= numberOfHorizontalLines; i++) {
      const yPos = i * snapMin;
      const color =
        i % Math.round(model.snappingGrid.majorLineFrequency / snapMin) === 0
          ? blue
          : darkGrey;
      positions.push([
        new B_Vector3(-max, yPos, 0),
        new B_Vector3(max, yPos, 0),
      ]);

      colors.push([color, color]);
      /*
      if (color === blue) {
        const text = (i * model.snapY * 1000).toFixed(0);
        this.generateLabelForGrid(model, text, {x: 0, y: yPos, z: 0});
      }*/
    }

    this.currentGridMesh = createLineSystemWithDepthOffset(
      'grid',
      {
        lines: positions,
        colors: colors,
        useVertexAlpha: true,
      },
      this.rootNode.getScene(),
      -0.0005
    );
    this.currentGridMesh.position.x = model.snappingGrid.offset.x;
    this.currentGridMesh.position.y = model.snappingGrid.offset.y;
    this.currentGridMesh.isPickable = false;
  }

  dispose() {
    if (this.rootNode) {
      this.rootNode.dispose();
      this.rootNode = null;
    }
    if (this.currentGridMesh) {
      this.currentGridMesh.dispose();
      this.currentGridMesh = null;
    }
    if (this.plane) {
      this.plane.dispose();
      this.plane = null;
    }
    this.labelTemplates.forEach((v, k) => {
      v.dispose();
    });
    this.labelTemplates = null;
    if (this.texture) {
      this.texture.dispose();
      this.texture = null;
    }
  }

  getObjectUnderPoint(position: Vector3): ObjectUnderPoint {
    const closestX =
      Math.round(
        (position.x - this.model.snappingGrid.offset.x) /
          this.model.snappingGrid.snapX
      ) *
        this.model.snappingGrid.snapX +
      this.model.snappingGrid.offset.x;
    const closestY =
      Math.round(
        (position.y - this.model.snappingGrid.offset.y) /
          this.model.snappingGrid.snapY
      ) *
        this.model.snappingGrid.snapY +
      this.model.snappingGrid.offset.y;
    return null;
  }
}
