import { Store, select } from "@ngrx/store";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { Vector2, Vector3 } from "webcad";
import { ButtonType, PointerState } from "webcad/collision";
import { RotateCamera } from "webcad/components";
import {
  HelpLine,
  copyHelpLine,
  createHelpLine,
  helpLinesEqual,
  isPointOnHelpLine,
} from "../../model/help-line.model";
import { ClosestSegments } from "../../providers/mevaco-pointer.provider";
import { SceneProvider } from "../../providers/scene.provider";
import { TranslationProvider } from "../../providers/translation.provider";
import {
  AddHelpLine,
  RequestRender,
  SetHintMessage,
} from "../../store/actions/drawing.actions";
import { MevacoState, getHelpLines } from "../../store/reducers";
import { visualizeHelpingLine } from "../../visualizers/help-lines.visualizer";
import { Tool } from "../tool.interface";
import {Color4, LinesMesh, Scene} from "@babylonjs/core";
export class HelpLineTool extends Tool {
  private _angle: BehaviorSubject<number>;
  private _offset: BehaviorSubject<number>;
  private model: HelpLine;
  private point: Vector2;
  private currentLine: LinesMesh;
  private angleSub: Subscription;
  private offsetSub: Subscription;
  private helpLineUnderMouse: HelpLine;
  private selectedHelpLineMesh: LinesMesh;
  public selectedHelpLine: HelpLine;
  private scene: Scene;
  private canDraw: BehaviorSubject<boolean>;
  private offsetInputVal: string = null;
  private angleInputVal: string = null;

  constructor(
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private translationProvider: TranslationProvider
  ) {
    super();
    this._angle = new BehaviorSubject<number>(180);
    this._offset = new BehaviorSubject<number>(0);
    this.helpLineUnderMouse = null;
    this.sceneProvider.getSubscription().subscribe((v) => {
      this.scene = v;
      if (v) {
        this.scene.onBeforeRenderObservable.add((ed, es) => {
          // const activeCamera = this.scene.activeCamera as unknown;
          const scale = 0.08 * (this.scene.activeCamera as RotateCamera).radius;
          if (this.selectedHelpLineMesh) {
            this.selectedHelpLineMesh.edgesWidth = scale;
          }
        });
      }
    });
  }

  public changeOffset(newOffset: string) {
    const num: number = Number(newOffset);
    if (!isNaN(num) && newOffset !== "") {
      this._offset.next(num);
    }
    this.offsetInputVal = newOffset;
  }

  public changeAngle(newAngle: string) {
    const num: number = Number(newAngle);
    if (!isNaN(num) && newAngle !== "") {
      this._angle.next(num);
    }
    this.angleInputVal = newAngle;
  }

  get angle(): Observable<number> {
    return this._angle;
  }

  get offset(): Observable<number> {
    return this._offset;
  }

  get direction(): Vector2 {
    return this.model ? this.model.direction : { x: 1, y: 0 };
  }

  activate() {
    this.store.dispatch(new SetHintMessage(this.translate("helpLineToolHint"))); //Click left mouse button to place the origin of the Helping Line.
    this._angle.next(180);
    this._offset.next(0);
    this.angleInputVal = "180";
    this.offsetInputVal = "0";
    this.point = { x: 0, y: 0 };
    this.model = createHelpLine(Math.PI, true, 0, this.point);
    this.angleSub = this._angle
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        map((value) => {
          return value;
        })
      )
      .subscribe((value) => {
        this.model = createHelpLine(
          value,
          false,
          this._offset.getValue(),
          this.point
        );
        this.visualizeHelpLine();
      });
    this.offsetSub = this._offset
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        map((value) => {
          return value;
        })
      )
      .subscribe((value) => {
        this.model = createHelpLine(
          this._angle.getValue(),
          false,
          value,
          this.point
        );
        this.visualizeHelpLine();
      });
  }

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {}

  onMouseClick(pointerState: PointerState) {
    this.point = pointerState.position;
    if (this.selectedHelpLineMesh) {
      this.selectedHelpLineMesh.disableEdgesRendering();
      this.selectedHelpLineMesh = null;
    }
    if (pointerState.button === ButtonType.LEFT) {
      this.onConfirm();
    } else if (pointerState.button === ButtonType.RIGHT) {
      this.selectedHelpLine = copyHelpLine(this.helpLineUnderMouse);
      this.selectedHelpLineMesh = this.scene.meshes.find(
        (v) => v.metadata && helpLinesEqual(v.metadata, this.selectedHelpLine)
      ) as LinesMesh;
      if (this.selectedHelpLineMesh) {
        this.selectedHelpLineMesh.enableEdgesRendering();
        this.selectedHelpLineMesh.edgesColor = new Color4(0, 1, 0, 1);
        const activeCamera = this.scene.activeCamera as unknown;
        this.selectedHelpLineMesh.edgesWidth =
          0.08 * (activeCamera as RotateCamera).radius;
      }
    }
  }

  onMouseDown(pointerState: PointerState) {
    this.point = pointerState.position;
  }

  onMouseMove(pointerState: PointerState) {
    this.helpLineUnderMouse = this.getHelpLineOnPoint(pointerState.position);
    this.point = pointerState.position;
    this.model = createHelpLine(
      this._angle.getValue(),
      false,
      this._offset.getValue(),
      this.point
    );
    this.visualizeHelpLine();
  }

  onMouseUp(pointerState: PointerState) {}

  reset() {
    this.onCancel();
    this.activate();
  }

  visualizeHelpLine() {
    if (this.currentLine) {
      this.currentLine.dispose();
      this.currentLine = null;
    }
    if (
      this.angleInputVal &&
      this.angleInputVal.length > 0 &&
      this.offsetInputVal &&
      this.offsetInputVal.length > 0
    ) {
      this.currentLine = visualizeHelpingLine(this.model, this.scene);
    }
    this.store.dispatch(new RequestRender());
  }

  getHelpLineOnPoint(point: Vector3): HelpLine {
    let helpLine = null;
    this.store
      .pipe(select(getHelpLines))
      .subscribe((value) => {
        value.forEach((hli, k, m) => {
          if (!helpLine && isPointOnHelpLine(point, hli)) {
            helpLine = hli;
          }
        });
      })
      .unsubscribe();
    return helpLine;
  }

  onCancel() {
    this.model = createHelpLine(0, true, 0, this.point);
    if (this.selectedHelpLine) {
      this.selectedHelpLine = null;
    }
    if (this.selectedHelpLineMesh) {
      this.selectedHelpLineMesh.disableEdgesRendering();
      this.selectedHelpLineMesh = null;
    }
    if (this.angleSub && !this.angleSub.closed) {
      this.angleSub.unsubscribe();
      this.angleSub = null;
    }
    if (this.offsetSub && !this.offsetSub.closed) {
      this.offsetSub.unsubscribe();
      this.offsetSub = null;
    }
    if (this.currentLine) {
      this.currentLine.dispose();
      this.currentLine = null;
    }
    this._angle.next(180);
    this._offset.next(0);
  }

  onConfirm() {
    this.store.dispatch(new AddHelpLine(copyHelpLine(this.model)));
  }

  isDirty() {
    return this._dirty;
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }
}
