import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {Scene} from "@babylonjs/core";
@Injectable()
export class SceneProvider {
  private scene: BehaviorSubject<Scene>;
  constructor() {
    this.scene = new BehaviorSubject(null);
  }

  setScene(scene: Scene): void {
    this.scene.next(scene);
  }

  getScene(): Scene {
    return this.scene.getValue();
  }

  getSubscription() {
    return this.scene.asObservable();
  }
}
