import { ModelVisualizer, Webcad } from "webcad";

import { Vector3 } from "webcad/math";
import {
  getRightPerpendicularVector2,
  multiplyVector2byScalar,
  normalizeVector2,
  subVectors2,
} from "webcad/math";
import { ObjectUnderPoint } from "webcad/models/ObjectUnderPoint";
import { BendingLine } from "../model/bending-line.model";
import { BendingLinesViewModel } from "../model/view-model/bending-lines.viewModel";
import { Node } from "@babylonjs/core";
import {Color3, MeshBuilder, Scene, Vector3 as B_Vector3} from "@babylonjs/core";

export class BendingLinesVisualizer
  implements ModelVisualizer<BendingLinesViewModel>
{
  private model: BendingLinesViewModel;
  private rootNode: Node;
  private webcad: Webcad;
  private bendingLineMeshes: Node[];

  constructor() {
    this.bendingLineMeshes = [];
  }

  init(
    rootNode: Node,
    model: BendingLinesViewModel,
    webcad: Webcad
  ): Promise<void> {
    this.rootNode = rootNode;
    this.model = model;
    this.webcad = webcad;
    return Promise.resolve();
  }

  updateVisualization(newModel: BendingLinesViewModel): void {
    if (
      newModel.bendLines !== this.model.bendLines ||
      this.model.state !== newModel.state
    ) {
      // delete all lines
      for (let i = 0; i < this.bendingLineMeshes.length; i++) {
        this.bendingLineMeshes[i].dispose();
      }
      this.bendingLineMeshes = [];

      if (newModel.state) {
        for (let i = 0; i < newModel.bendLines.length; i++) {
          const newMesh = visualizeBendingLine(
            newModel.bendLines[i],
            this.webcad.scene
          );
          this.bendingLineMeshes.push(newMesh);
        }
      }
    }

    this.model = newModel;
  }

  dispose(): void {
    for (let i = 0; i < this.bendingLineMeshes.length; i++) {
      this.bendingLineMeshes[i].dispose();
      this.bendingLineMeshes = [];
    }
  }

  getObjectUnderPoint(point: Vector3, maxDist: number): ObjectUnderPoint {
    let obj: ObjectUnderPoint = null;
    return obj;
  }
}

export function visualizeBendingLine(
  model: BendingLine,
  scene: Scene
): Node {
  const dir = normalizeVector2(subVectors2(model.end, model.begin));
  //  const l =  lengthVector2( subVectors2( model.end, model.begin) );
  const baOffset = multiplyVector2byScalar(
    getRightPerpendicularVector2(dir),
    model.bentParams.bendAllowance / 2
  );
  const ossbOffset = multiplyVector2byScalar(
    getRightPerpendicularVector2(dir),
    model.bentParams.ossb - model.bentParams.bendAllowance / 2
  );

  const positions: B_Vector3[] = [
    new B_Vector3(model.begin.x, model.begin.y, 0),
    new B_Vector3(model.end.x, model.end.y, 0),
  ];

  const positionsBa1: B_Vector3[] = [
    new B_Vector3(
      model.begin.x + baOffset.x,
      model.begin.y + baOffset.y,
      0
    ),
    new B_Vector3(model.end.x + baOffset.x, model.end.y + baOffset.y, 0),
  ];

  const positionsBa2: B_Vector3[] = [
    new B_Vector3(
      model.begin.x - baOffset.x,
      model.begin.y - baOffset.y,
      0
    ),
    new B_Vector3(model.end.x - baOffset.x, model.end.y - baOffset.y, 0),
  ];

  const ossbBa1: B_Vector3[] = [
    new B_Vector3(
      model.begin.x + ossbOffset.x,
      model.begin.y + ossbOffset.y,
      0
    ),
    new B_Vector3(
      model.end.x + ossbOffset.x,
      model.end.y + ossbOffset.y,
      0
    ),
  ];

  const ossbBa2: B_Vector3[] = [
    new B_Vector3(
      model.begin.x - ossbOffset.x,
      model.begin.y - ossbOffset.y,
      0
    ),
    new B_Vector3(
      model.end.x - ossbOffset.x,
      model.end.y - ossbOffset.y,
      0
    ),
  ];

  const color = new Color3(1, 0.5, 0);
  const baColor = new Color3(0.6, 0.55, 0.5);
  const ossbColor = new Color3(0.3, 0.3, 0.3);

  const obj = new Node("Bending", scene);
  obj.metadata = model;
  let mesh = MeshBuilder.CreateLines(
    "BendingLine",
    { points: positions },
    scene
  );
  mesh.renderingGroupId = 3;
  mesh.color = color;
  mesh.material.pointSize = 3;
  mesh.parent = obj;

  // mesh = BABYLON.MeshBuilder.CreateLines('BendingLine', {points: positionsBa1, /*dashNb: l* 200*/}, scene);
  // mesh.renderingGroupId = 3;
  // mesh.color = baColor;
  // mesh.material.pointSize = 3;
  // mesh.parent = obj;
  //
  // mesh = BABYLON.MeshBuilder.CreateLines('BendingLine', {points: positionsBa2, /*dashSize: 1, gapSize: 1, dashNb: 200*/}, scene);
  // mesh.renderingGroupId = 3;
  // mesh.color = baColor;
  // mesh.material.pointSize = 3;
  // mesh.parent = obj;

  mesh = MeshBuilder.CreateLines(
    "BendingLine",
    { points: ossbBa1 /*dashNb: l* 200*/ },
    scene
  );
  mesh.renderingGroupId = 3;
  mesh.color = ossbColor;
  mesh.material.pointSize = 3;
  mesh.parent = obj;

  mesh = MeshBuilder.CreateLines(
    "BendingLine",
    { points: ossbBa2 /*dashSize: 1, gapSize: 1, dashNb: 200*/ },
    scene
  );
  mesh.renderingGroupId = 3;
  mesh.color = ossbColor;
  mesh.material.pointSize = 3;
  mesh.parent = obj;

  return obj;
}
