import { Vector3 } from "../math/vector3";
import { Vector2 } from "../math/vector2";

export enum ChangeType {
	Added,
	Removed,
	Changed,
}

export function detectChangesInMap<TKey, TVal>(oldMap: Map<TKey, TVal>, newMap: Map<TKey, TVal>, onChange: (type: ChangeType, key: TKey) => void, onUnchanged:(key: TKey) => void = null) {
	if (oldMap !== newMap || !!onUnchanged) {
		let countOfItemsAlreadyFoundInOldMap = 0;
		newMap.forEach((newValue, key) => {
			let oldValue = oldMap.get(key);
			if (oldValue === newValue) {
				countOfItemsAlreadyFoundInOldMap++; //no change
				if(!!onUnchanged){
					onUnchanged(key);
				}
			} else if (!oldValue) {
				onChange(ChangeType.Added, key);
			} else {
				countOfItemsAlreadyFoundInOldMap++;
				onChange(ChangeType.Changed, key);
			}
		});

		if (countOfItemsAlreadyFoundInOldMap !== oldMap.size) {
			//optimization if no item was Removed then this block will not execute

			oldMap.forEach((v, key) => {
				if (!newMap.has(key)) {
					onChange(ChangeType.Removed, key);
				}
			});
		}
	}
}


export function ArrayOfVectors3ToArrayOfFloats(ArrayOfVectors3: Vector3[]): Float32Array {
    let floatArray: Float32Array = new Float32Array(3 * ArrayOfVectors3.length);

    for (let i = 0; i < ArrayOfVectors3.length; i++) {
        floatArray[i * 3] = ArrayOfVectors3[i].x;
        floatArray[i * 3 + 1] = ArrayOfVectors3[i].y;
        floatArray[i * 3 + 2] = ArrayOfVectors3[i].z;
    }

    return floatArray;
}

export function ArrayOfVectors2ToArrayOfFloatsWithExtraZ(ArrayOfVectors2: Vector2[] ,zValue: number = 0): Float32Array {
    let floatArray: Float32Array = new Float32Array(3 * ArrayOfVectors2.length);

    for (let i = 0; i < ArrayOfVectors2.length; i++) {
        floatArray[i * 3] = ArrayOfVectors2[i].x;
        floatArray[i * 3 + 1] = ArrayOfVectors2[i].y;
        floatArray[i * 3 + 2] = zValue;
    }

    return floatArray;
}

export function changeMapValue<U, T>(map: Map<U, T>, newValue: T, key: U): Map<U, T> {
    const newMap = new Map<U, T>();
    map.forEach((value: T, key: U, map: Map<U, T>) => newMap.set(key, value));
    newMap.set(key, newValue);
    return newMap;
}