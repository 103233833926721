import { Store, select } from "@ngrx/store";
import { BehaviorSubject, Subscription, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { SegmentType, Vector3, Webcad } from "webcad";
import { PointerState } from "webcad/collision";
import {
  CosOfAngleBetween2Vectors2D,
  Vector2,
  addVectors3,
  copyVector2,
  crossVector2,
  fModF,
  isPointBetween,
  multiplyVector3byScalar,
  normalizeVector2,
  normalizeVector3,
  subVectors2,
  subVectors3,
} from "webcad/math";
import {
  MeasurementModelManager,
  MeasurementsManager,
} from "webcad/measurements";
import {
  Segment,
  getPointsFromSegment,
  isPointInArcSegment,
  isPolylineClosed,
} from "webcad/models";
import { HelpLine } from "../../../model/help-line.model";
import { LineType } from "../../../model/line-type.model";
import { PointNode, getNodeByPosition } from "../../../model/point-node.model";
import { ActionType } from "../../../model/shape-action.model";
import { MeasurementsManagerProvider } from "../../../providers/measurements-manager.provider";
import { ClosestSegments } from "../../../providers/mevaco-pointer.provider";
import { SceneProvider } from "../../../providers/scene.provider";
import { ToolProvider } from "../../../providers/tool.provider";
import { TranslationProvider } from "../../../providers/translation.provider";
import { WebcadProvider } from "../../../providers/webcad.provider";
import { RemoveAllTemporaryNodes } from "../../../store";
import {
  AddToolHelpLine,
  RemoveAllToolHelpLines,
  RequestRender,
  SetHintMessage,
} from "../../../store/actions/drawing.actions";
import {
  AddShapeToAdd,
  AddShapeToRemove,
} from "../../../store/actions/plate.actions";
import {
  MevacoState,
  getCurrentActionType,
  getNodesState,
  getOuterShapePolyline,
  getShapeWithHoles,
} from "../../../store/reducers";
import { createLineWithDepthOffset } from "../../../visualizers/line-system";
import { DrawingTool } from "../../drawing-tool.interface";
import { updateXYMeasurements } from "../../utils";
import { LineTool } from "./line.tool";
import { PolylineTool } from "./polyline.tool";
import {Color4, LinesMesh, Scene, Vector3 as B_Vector3} from "@babylonjs/core";
export class LineMainTool extends DrawingTool {
  mode: ActionType;
  private lineTool: LineTool;
  private polyLineTool: PolylineTool;
  private activeTool: LineTool | PolylineTool;
  private scene: Scene;
  private webcad: Webcad;
  private currentDrawnLine: Segment[];
  private nodes: PointNode[];
  private currentLineMesh: LinesMesh;
  private segments: Segment[];
  private plateOuterShape: Segment[];
  private plateSub: Subscription;
  private outerShapeSub: Subscription;
  private isShapeClosed = false;
  private lineSubscription: Subscription;
  private arcSubscription: Subscription;
  private point: Vector3;
  private begin: BehaviorSubject<Vector3>;
  private previousLineDirection: Vector2;
  private tempNodes: PointNode[] = [];
  private snapAngle: BehaviorSubject<number>;
  private epsilon: number = 5 * (Math.PI / 180);
  private helpLines: HelpLine[] = [];
  private helplinesSub: Subscription;
  private measurementManager: MeasurementsManager;
  private verticalMeasurement: MeasurementModelManager;
  private horizontalMeasurement: MeasurementModelManager;
  private closestSegments: ClosestSegments;
  private plateHoles: Segment[][];
  private holesShapeSub: Subscription;

  constructor(
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private webcadProvider: WebcadProvider,
    private measurementManagerProvider: MeasurementsManagerProvider,
    private toolProvider: ToolProvider,
    private translationProvider: TranslationProvider
  ) {
    super();
    this.currentDrawnLine = [];
    this.sceneProvider.getSubscription().subscribe((value) => {
      this.scene = value;
    });
    this.webcadProvider.getObservable().subscribe((value) => {
      this.webcad = value;
    });
    this.measurementManagerProvider.getSubsciption().subscribe((value) => {
      if (value) {
        this.measurementManager = value;
        this.disposeMeasurements();
      }
    });
    this.snapAngle = new BehaviorSubject(Math.PI / 4);
    this.begin = new BehaviorSubject(null);
    this.init();
  }

  init() {
    this.store.pipe(select(getCurrentActionType)).subscribe((actionType) => {
      this.mode = actionType;
    });
    this.store.pipe(select(getNodesState)).subscribe((value) => {
      this.nodes = value;
    });
    this.lineTool = new LineTool(
      this.store,
      this.sceneProvider,
      this.webcadProvider,
      this.measurementManagerProvider,
      this.toolProvider,
      this.translationProvider
    );
    this.lineSubscription = this.lineTool
      .segmentDoneObservable()
      .subscribe((segment: Segment) => this.onObtainedSegment(segment));
    this.polyLineTool = new PolylineTool(
      this.store,
      this.sceneProvider,
      this.webcadProvider,
      this.measurementManagerProvider,
      this.toolProvider,
      this.translationProvider
    );
    this.arcSubscription = this.polyLineTool
      .segmentDoneObservable()
      .subscribe((segment: Segment) => this.onObtainedSegment(segment));
    // this.scene.onPreKeyboardObservable.add(this.changeLineType.bind(this));
  }

  onMouseDown(pointerState: PointerState) {}

  onMouseUp(pointerState: PointerState) {}

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {
    this.closestSegments = closestSegments;
    if (this.begin && this.begin.value) {
      this.activeTool.onClosestSegmentsChanged(closestSegments);
    } else {
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    }
    this.store.dispatch(new RequestRender());
  }

  onMouseMove(pointerState: PointerState) {
    this.point = pointerState.position;
    if (this.begin && this.begin.value) {
      this.activeTool.onMouseMove(pointerState);
    } else {
      this.activeTool.disposeStartMeasurements();
    }
  }

  onMouseClick(pointerState: PointerState) {
    // this.point = point;
    if ((!this.begin.value || this.begin.value === null) && !!this.point) {
      this.begin.next({ x: this.point.x, y: this.point.y, z: this.point.z });
      this.activeTool.startNextLine({
        x: this.begin.getValue().x,
        y: this.begin.getValue().y,
        z: this.begin.getValue().z,
      });
      this.disposeMeasurements();
    } else {
      this.store.dispatch(
        new SetHintMessage(this.translationProvider.translate("endLineTool"))
      );
      this.activeTool.onMouseClick(pointerState);
    }
  }

  reset() {
    this.onCancel();
    this.activate();
  }

  activate() {
    if (this.measurementManager) {
      if (!this.verticalMeasurement) {
        this.verticalMeasurement =
          this.measurementManager.getMeasurementModel();
        this.verticalMeasurement.setInputCallbacks(
          (value: number) => {
            this.setVertical(value);
            return value;
          },
          (value: number) => {
            this.setVertical(value);
            this.begin.next({
              x: this.point.x,
              y: this.point.y,
              z: this.point.z,
            });
            this.activeTool.startNextLine({
              x: this.begin.getValue().x,
              y: this.begin.getValue().y,
              z: this.begin.getValue().z,
            });
            this.disposeMeasurements();
            return value;
          }
        );
      }
      if (!this.horizontalMeasurement) {
        this.horizontalMeasurement =
          this.measurementManager.getMeasurementModel();
        this.horizontalMeasurement.setInputCallbacks(
          (value: number) => {
            this.setHorizontal(value);
            return value;
          },
          (value: number) => {
            this.setHorizontal(value);
            this.begin.next({
              x: this.point.x,
              y: this.point.y,
              z: this.point.z,
            });
            this.activeTool.startNextLine({
              x: this.begin.getValue().x,
              y: this.begin.getValue().y,
              z: this.begin.getValue().z,
            });
            this.disposeMeasurements();
            return value;
          }
        );
      }
    }
    this.store.dispatch(
      new SetHintMessage(this.translationProvider.translate("startLineTool"))
    );
    this.begin.next(null);
    if (!this.helplinesSub) {
      this.helplinesSub = combineLatest([this.snapAngle, this.begin]).subscribe(
        ([angle, begin]) => {
          if (this.helpLines) {
            this.store.dispatch(new RemoveAllToolHelpLines());
          }
          this.helpLines = [];
          if (begin) {
            const amountOfHelpLines = (Math.PI * 2) / angle / 2;
            for (let i = 0; i < amountOfHelpLines; i++) {
              const alpha = i * angle;
              const dir = { x: Math.cos(alpha), y: Math.sin(alpha) };
              const model: HelpLine = {
                visible: false,
                direction: normalizeVector2(dir),
                offset: 0,
                position: begin,
              };
              this.helpLines.push(model);
              this.store.dispatch(new AddToolHelpLine(model));
            }
          }
        }
      );
    }
    this.snapAngle.next(Math.PI / 4);
    this.previousLineDirection = { x: 1, y: 0 };
    this.outerShapeSub = this.store
      .pipe(select(getOuterShapePolyline))
      .subscribe((polylines) => {
        this.plateOuterShape = polylines;
      });
    this.holesShapeSub = this.store
      .pipe(
        select(getShapeWithHoles),
        map((v) => v.holes)
      )
      .subscribe((v) => {
        this.plateHoles = v;
      });
    this.currentDrawnLine = [];
    this.lineTool.onCancel();
    this.polyLineTool.onCancel();
    this.activeTool = this.lineTool;
    this.activeTool.activate();
  }

  private onObtainedSegment(segment: Segment): void {
    if (this.currentDrawnLine) {
      this.currentDrawnLine = [...this.currentDrawnLine, segment];
      const segBeginNode = getNodeByPosition(this.nodes, segment.begin);
      const segEndNode = getNodeByPosition(this.nodes, segment.end);
      if (this.tempNodes.indexOf(segBeginNode) === -1) {
        this.tempNodes.push(segBeginNode);
      }
      if (this.tempNodes.indexOf(segEndNode) === -1) {
        this.tempNodes.push(segEndNode);
      }
      if (isPolylineClosed(this.currentDrawnLine)) {
        this.isShapeClosed = true;
        this.store.dispatch(
          this.mode === ActionType.ADD
            ? new AddShapeToAdd(this.currentDrawnLine)
            : new AddShapeToRemove(this.currentDrawnLine)
        );
        this.tempNodes = [];
        this.reset();
      } else {
        let autoClose = false;
        // Auto close polyline
        if (this.currentDrawnLine.length === 1) {
          this.segments = this.getClosestSegments(
            this.currentDrawnLine[0].begin
          );
        } else if (this.segments) {
          const currSegs = this.getClosestSegments(segment.end);
          if (currSegs) {
            const segmentOn = this.sameSegments(this.segments, currSegs);
            if (!!segmentOn) {
              const newLine: Segment = {
                end: this.currentDrawnLine[0].begin,
                begin:
                  this.currentDrawnLine[this.currentDrawnLine.length - 1].end,
                type: SegmentType.line,
              };
              if (segmentOn.type === SegmentType.arc) {
                newLine.origin = copyVector2(segmentOn.origin);
                newLine.type = SegmentType.arc;
                newLine.radius = segmentOn.radius;
                const originToBegin = normalizeVector2(
                  subVectors2(newLine.begin, newLine.origin)
                );
                const originToEnd = normalizeVector2(
                  subVectors2(newLine.end, newLine.origin)
                );
                const beginAngle = fModF(
                  Math.atan2(originToBegin.y, originToBegin.x),
                  Math.PI * 2
                );
                const sign = Math.sign(
                  crossVector2(originToBegin, originToEnd)
                );
                const angleBetween =
                  Math.acos(
                    CosOfAngleBetween2Vectors2D(originToBegin, originToEnd)
                  ) * sign;
                newLine.beginAngle = beginAngle;
                newLine.endAngle = beginAngle + angleBetween;
              }
              autoClose = true;
              this.currentDrawnLine = [...this.currentDrawnLine, newLine];
              this.isShapeClosed = true;
              this.store.dispatch(
                this.mode === ActionType.ADD
                  ? new AddShapeToAdd(this.currentDrawnLine)
                  : new AddShapeToRemove(this.currentDrawnLine)
              );
              this.tempNodes = [];
              this.reset();
              this.segments = null;
              return;
            }
          }
        }
        // End of auto close
        if (this.currentLineMesh) {
          this.currentLineMesh.dispose();
        }
        if (!autoClose) {
          this.currentLineMesh = this.createLineShapeVisualization(
            this.currentDrawnLine,
            this.mode,
            this.scene
          );
          this.activeTool.reset();
          const newBegin =
            this.currentDrawnLine[this.currentDrawnLine.length - 1].end;
          this.begin.next({
            x: newBegin.x,
            y: newBegin.y,
            z: this.begin.getValue().z,
          });
          this.previousLineDirection = subVectors2(
            this.begin.value,
            this.currentDrawnLine[this.currentDrawnLine.length - 1].begin
          );
          this.activeTool.startNextLine(
            this.begin.value,
            this.previousLineDirection
          );
          this.activeTool.activate();
        }
      }
    }
  }

  private sameSegments(a: Segment[], b: Segment[]): Segment {
    for (const p of a) {
      for (const pp of b) {
        if (p === pp) {
          return p;
        }
      }
    }
    return null;
  }

  private createLineShapeVisualization(
    lineShape: Segment[],
    actionType: ActionType,
    scene: Scene
  ): LinesMesh {
    const positions: B_Vector3[] = [];
    for (const s of lineShape) {
      positions.push(
        ...getPointsFromSegment(s, true).map(
          (v) => new B_Vector3(v.x, v.y, 0)
        )
      );
    }
    const colors: Color4[] = [];
    const color =
      actionType === ActionType.REMOVE
        ? new Color4(1, 0, 0, 0)
        : new Color4(0, 1, 0, 0);
    const lineColor = this.isShapeClosed
      ? new Color4(color.r, color.g, color.b, 0)
      : new Color4(color.r, color.g, color.b, 1);
    for (const p of positions) {
      colors.push(lineColor);
    }
    const mesh = createLineWithDepthOffset(
      "currentLine",
      {
        points: positions,
        colors: colors,
      },
      scene,
      -0.0006
    );
    if (this.isShapeClosed) {
      mesh.metadata = { actionType: actionType };
    }
    return mesh;
  }

  public changeLineType(event: KeyboardEvent) {
    if (event.ctrlKey) {
      event.preventDefault();
      event.stopPropagation();
      switch (event.keyCode) {
        case 49:
          this.activeTool.reset();
          this.activeTool = this.lineTool;
          if (this.begin && this.begin.value && this.begin.value !== null) {
            this.activeTool.startNextLine(
              this.begin.value,
              this.previousLineDirection
            );
            this.activeTool.activate();
          }
          break;
        case 50:
          this.activeTool.reset();
          this.activeTool = this.polyLineTool;
          this.polyLineTool.setMode(LineType.TANGENTIAL);
          if (this.begin && this.begin.value && this.begin.value !== null) {
            this.activeTool.startNextLine(
              this.begin.value,
              this.previousLineDirection
            );
            this.activeTool.activate();
          }
          break;
        case 51:
          this.activeTool.reset();
          this.activeTool = this.polyLineTool;
          this.polyLineTool.setMode(LineType.ANGULAR, Math.PI);
          if (this.begin && this.begin.value && this.begin.value !== null) {
            this.activeTool.startNextLine(
              this.begin.value,
              this.previousLineDirection
            );
            this.activeTool.activate();
          }
          break;
      }
    }
  }

  private getClosestSegments(point: Vector2): Segment[] {
    const segments: Segment[] = [];
    if (this.plateOuterShape) {
      for (const c of this.plateOuterShape) {
        if (c.type === SegmentType.line) {
          if (isPointBetween(c.begin, c.end, point)) {
            segments.push(c);
          }
        } else if (c.type === SegmentType.arc) {
          if (isPointInArcSegment(point, c)) {
            segments.push(c);
          }
        }
      }
    }
    if (this.plateHoles) {
      for (const hole of this.plateHoles) {
        for (const c of hole) {
          if (c.type === SegmentType.line) {
            if (isPointBetween(c.begin, c.end, point)) {
              segments.push(c);
            }
          } else if (c.type === SegmentType.arc) {
            if (isPointInArcSegment(point, c)) {
              segments.push(c);
            }
          }
        }
      }
    }
    return segments.length > 0 ? segments : null;
  }

  onCancel() {
    this.disposeMeasurements();
    if (this.tempNodes.length > 0) {
      this.store.dispatch(new RemoveAllTemporaryNodes());
    }
    if (this.helpLines) {
      this.store.dispatch(new RemoveAllToolHelpLines());
    }
    if (this.helplinesSub && !this.helplinesSub.closed) {
      this.helplinesSub.unsubscribe();
      this.helplinesSub = null;
    }
    this.helpLines = [];
    this.tempNodes = [];
    this.currentDrawnLine = [];
    this.begin.next(null);
    this.point = null;
    this.previousLineDirection = { x: 1, y: 0 };
    this.lineTool.onCancel();
    this.isShapeClosed = false;
    this.polyLineTool.onCancel();
    if (this.currentLineMesh) {
      this.currentLineMesh.dispose();
      this.currentLineMesh = null;
    }
    if (this.plateSub && !this.plateSub.closed) {
      this.plateSub.unsubscribe();
      this.plateSub = null;
    }
    if (this.outerShapeSub && !this.outerShapeSub.closed) {
      this.outerShapeSub.unsubscribe();
      this.outerShapeSub = null;
    }
    this.store.dispatch(new RequestRender());
  }

  onConfirm() {
    if (!this.begin.value || this.begin.value === null) {
      this.begin.next({ x: this.point.x, y: this.point.y, z: this.point.z });
      this.activeTool.startNextLine({
        x: this.begin.getValue().x,
        y: this.begin.getValue().y,
        z: this.begin.getValue().z,
      });
      this.disposeMeasurements();
    } else {
      this.store.dispatch(
        new SetHintMessage(this.translationProvider.translate("endLineTool"))
      );
      this.activeTool.onConfirm();
    }
  }

  isDirty(): boolean {
    return this._dirty;
  }

  private disposeMeasurements() {
    if (this.horizontalMeasurement) {
      this.horizontalMeasurement.disposeModel();
      this.horizontalMeasurement = null;
    }
    if (this.verticalMeasurement) {
      this.verticalMeasurement.disposeModel();
      this.verticalMeasurement = null;
    }
  }

  setVertical(value: number): void {
    if (!!this.begin) {
      const point = this.verticalMeasurement.getStart();
      const wall = this.verticalMeasurement.getEnd();
      const moveDir = normalizeVector3(subVectors3(point, wall));
      const newPoint = addVectors3(
        wall,
        multiplyVector3byScalar(moveDir, value)
      );
      this.point = { x: newPoint.x, y: newPoint.y, z: newPoint.z };
      this.updateClosestVerticalSegment(this.point);
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    }
    this.store.dispatch(new RequestRender());
  }

  setHorizontal(value: number): void {
    if (!!this.begin) {
      const point = this.horizontalMeasurement.getStart();
      const wall = this.horizontalMeasurement.getEnd();
      const moveDir = normalizeVector3(subVectors3(point, wall));
      const newPoint = addVectors3(
        wall,
        multiplyVector3byScalar(moveDir, value)
      );
      this.point = { x: newPoint.x, y: newPoint.y, z: newPoint.z };
      this.updateClosestHorizontalSegment(this.point);
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    }
    this.store.dispatch(new RequestRender());
  }

  private updateXYMeasurements(
    xMeasurement: MeasurementModelManager,
    yMeasurement: MeasurementModelManager,
    point: Vector3
  ) {
    updateXYMeasurements(
      xMeasurement,
      yMeasurement,
      point,
      this.closestSegments
    );
  }

  private updateClosestSegments(point: Vector3) {
    this.updateClosestHorizontalSegment(point);
    this.updateClosestVerticalSegment(point);
  }

  private updateClosestVerticalSegment(point: Vector3) {
    this.closestSegments.verticalSegment =
      this.toolProvider.getNearestVerticalSegmentToPoint(point);
  }

  private updateClosestHorizontalSegment(point: Vector3) {
    this.closestSegments.horizontalSegment =
      this.toolProvider.getNearestHorizontalSegmentToPoint(point);
  }
}
