import { select, Store } from "@ngrx/store";
import { PointerState } from "webcad/collision";
import { addVectors2, rotateVector2, subVectors2, Vector2 } from "webcad/math";
import {
  Aabb2,
  expandAabb,
  getEmptyAaabb,
  IsPointInsideOfShape,
  projectPointOnSegment,
  Segment,
  SegmentType,
} from "webcad/models";
import { ActionType } from "../../model";
import {
  getStampList,
  holeExistsInPerforation,
  Perforation,
} from "../../model/perforation.model";
import { ShapeWithHoles } from "../../model/shape-with-holes";
import { PerforationIntersection } from "../../providers/colliders/drawingPerforationCollider";
import { ClosestSegments } from "../../providers/mevaco-pointer.provider";
import { SceneProvider } from "../../providers/scene.provider";
import { TranslationProvider } from "../../providers/translation.provider";
import {
  AddShapeToAdd,
  AddShapeToRemove,
  ChangeActionType,
} from "../../store/actions";
import { SetHintMessage } from "../../store/actions/drawing.actions";
import { SetPerforationCollider } from "../../store/actions/snap-options.actions";
import { getPerforationArea, MevacoState } from "../../store/reducers";
import { Tool } from "../tool.interface";
import {Color3, Epsilon, Mesh, PolygonMeshBuilder, StandardMaterial, Vector2 as B_Vector2} from "@babylonjs/core";
export class PerforationAdjustmentTool extends Tool {
  private lastPerforation: Perforation = undefined;
  private holeMeshes: Mesh[] = [];
  private perforationArea: ShapeWithHoles[];

  constructor(
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private translationProvider: TranslationProvider
  ) {
    super();
    this.store.pipe(select(getPerforationArea)).subscribe((perforationArea) => {
      this.perforationArea = perforationArea;
    });
  }

  activate() {
    this.store.dispatch(new SetPerforationCollider(true));
    this.store.dispatch(
      new SetHintMessage(
        this.translationProvider.translate("perforationAdjustmentToolHint")
      )
    );
  }

  reset() {
    this.onCancel();
    this.activate();
  }

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {}

  onMouseClick(pointerState: PointerState) {
    if (!!pointerState && !!pointerState.intersection) {
      const perforation = (pointerState.intersection as PerforationIntersection)
        .perforation;
      if (perforation) {
        const aabb: Aabb2 = this.createAaBbToAdd(
          perforation,
          pointerState.position
        );
        // aabb.max = addVectors2(aabb.max, {x: 0.0005, y: 0.0005});
        // aabb.min = addVectors2(aabb.min, {x: -0.0005, y: -0.0005});
        if (!aabb) {
          return;
        }
        let p1: Vector2 = { x: aabb.min.x, y: aabb.min.y };
        let p2: Vector2 = { x: aabb.max.x, y: aabb.min.y };
        let p3: Vector2 = { x: aabb.max.x, y: aabb.max.y };
        let p4: Vector2 = { x: aabb.min.x, y: aabb.max.y };
        if (!!perforation.rotation) {
          p1 = rotateVector2(p1, perforation.rotation);
          p2 = rotateVector2(p2, perforation.rotation);
          p3 = rotateVector2(p3, perforation.rotation);
          p4 = rotateVector2(p4, perforation.rotation);
        }
        const lineShape: Segment[] = [
          {
            type: SegmentType.line,
            begin: {
              x: pointerState.position.x + p1.x,
              y: pointerState.position.y + p1.y,
            },
            end: {
              x: pointerState.position.x + p2.x,
              y: pointerState.position.y + p2.y,
            },
          },
          {
            type: SegmentType.line,
            begin: {
              x: pointerState.position.x + p2.x,
              y: pointerState.position.y + p2.y,
            },
            end: {
              x: pointerState.position.x + p3.x,
              y: pointerState.position.y + p3.y,
            },
          },
          {
            type: SegmentType.line,
            begin: {
              x: pointerState.position.x + p3.x,
              y: pointerState.position.y + p3.y,
            },
            end: {
              x: pointerState.position.x + p4.x,
              y: pointerState.position.y + p4.y,
            },
          },
          {
            type: SegmentType.line,
            begin: {
              x: pointerState.position.x + p4.x,
              y: pointerState.position.y + p4.y,
            },
            end: {
              x: pointerState.position.x + p1.x,
              y: pointerState.position.y + p1.y,
            },
          },
        ];
        const exists = holeExistsInPerforation(
          rotateVector2(
            pointerState.position,
            !!perforation ? -perforation.rotation : 0
          ),
          perforation
        );
        this.store.dispatch(
          new ChangeActionType(!exists ? ActionType.ADD : ActionType.REMOVE)
        );
        this.store.dispatch(
          !exists
            ? new AddShapeToAdd(lineShape)
            : new AddShapeToRemove(lineShape)
        );
      }
    }
  }

  onMouseDown(pointerState: PointerState) {}

  onMouseMove(pointerState: PointerState) {
    const perforationIntersection: PerforationIntersection =
      pointerState.intersection as PerforationIntersection;
    if (!!perforationIntersection) {
      const perforation = perforationIntersection.perforation;
      const stamp = perforationIntersection.stamp;
      if (this.lastPerforation !== perforation) {
        this.removeMeshes();
        if (!!perforation) {
          for (const s of stamp.polygons) {
            const shape: B_Vector2[] = [];
            for (const p of s) {
              shape.push(new B_Vector2(p.x, p.y));
            }

            const polygonBuilder = new PolygonMeshBuilder(
              "basic_perforation_shape",
              shape,
              this.sceneProvider.getScene()
            );
            const mesh = polygonBuilder.build();
            mesh.addRotation(0, 0, perforationIntersection.stampRotation);
            mesh.addRotation(-Math.PI / 2, 0, 0);
            mesh.renderingGroupId = 3;
            mesh.onBeforeRenderObservable.add(() => {
              mesh.getEngine().setDepthFunction(mesh.getEngine()._gl.ALWAYS);
            });
            mesh.onAfterRenderObservable.add(() => {
              mesh.getEngine().setDepthFunction(mesh.getEngine()._gl.LESS);
            });
            const mat = new StandardMaterial(
              "mat",
              this.sceneProvider.getScene()
            );
            mesh.material = mat;
            this.holeMeshes.push(mesh);
          }
        }
      }
      const color = holeExistsInPerforation(
        rotateVector2(
          pointerState.position,
          !!perforation ? -perforation.rotation : 0
        ),
        perforation
      )
        ? new Color3(1, 0, 0)
        : new Color3(0, 1, 0);
      for (let i = 0; i < this.holeMeshes.length; i++) {
        const holeMesh = this.holeMeshes[i];
        holeMesh.position.set(
          pointerState.position.x,
          pointerState.position.y,
          pointerState.position.z - 0.0005
        );
        (holeMesh.material as StandardMaterial).diffuseColor = color;
        (holeMesh.material as StandardMaterial).specularColor = color;
      }
      this.lastPerforation = perforation;
    }
  }

  onMouseUp(pointerState: PointerState) {}

  removeMeshes(): void {
    for (let i = 0; i < this.holeMeshes.length; i++) {
      const holeMesh = this.holeMeshes[i];
      holeMesh.dispose();
    }
    this.holeMeshes = [];
  }

  onCancel() {
    this.store.dispatch(new SetPerforationCollider(false));
    this.removeMeshes();
    this._dirty = false;
  }

  onConfirm() {}

  createAaBbToAdd(perforation: Perforation, position: Vector2): Aabb2 {
    let aabb: Aabb2 = getEmptyAaabb();
    const polygons = getStampList(perforation, true).reduce(
      (acc, x) => acc.concat(x.polygons),
      []
    );
    for (let i = 0; i < polygons.length; i++) {
      const polygon = polygons[i];
      for (let j = 0; j < polygon.length; j++) {
        const v: Vector2 = polygon[j];
        aabb = expandAabb(aabb, v);
      }
    }
    aabb.max = addVectors2(aabb.max, { x: 0.0005, y: 0.0005 });
    aabb.min = addVectors2(aabb.min, { x: -0.0005, y: -0.0005 });
    let shouldCreate = false;
    for (const shapeWithHoles of this.perforationArea) {
      const polygon = [...shapeWithHoles.conture];
      for (const c of shapeWithHoles.holes) {
        polygon.push(...c);
      }
      if (IsPointInsideOfShape(position, polygon)) {
        return aabb;
      }
      for (const s of shapeWithHoles.conture) {
        const pp = projectPointOnSegment(position, s);
        if (pp) {
          let posToPp = subVectors2(pp, position);
          if (!!perforation.rotation) {
            posToPp = rotateVector2(posToPp, -perforation.rotation);
          }
          if (Math.abs(posToPp.y) > Math.abs(posToPp.x)) {
            if (Math.abs(posToPp.y) < perforation.tile.p.y) {
              aabb = expandAabb(aabb, {
                x: 0,
                y: perforation.tile.p.y * Math.sign(posToPp.y),
              });
              shouldCreate = true;
            }
          } else {
            if (Math.abs(posToPp.x) < perforation.tile.p.x) {
              aabb = expandAabb(aabb, {
                x: perforation.tile.p.x * Math.sign(posToPp.x),
                y: 0,
              });
              shouldCreate = true;
            }
          }
        }
      }
      for (const h of shapeWithHoles.holes) {
        for (const s of h) {
          const pp = projectPointOnSegment(position, s);
          if (pp) {
            let posToPp = subVectors2(pp, position);
            if (!!perforation.rotation) {
              posToPp = rotateVector2(posToPp, -perforation.rotation);
            }
            if (Math.abs(posToPp.y) > Math.abs(posToPp.x)) {
              if (Math.abs(posToPp.y) < perforation.tile.p.y) {
                aabb = expandAabb(aabb, {
                  x: 0,
                  y: perforation.tile.p.y * Math.sign(posToPp.y),
                });
                shouldCreate = true;
              }
            } else {
              if (Math.abs(posToPp.x) < perforation.tile.p.x) {
                aabb = expandAabb(aabb, {
                  x: perforation.tile.p.x * Math.sign(posToPp.x),
                  y: 0,
                });
                shouldCreate = true;
              }
            }
          }
        }
      }
    }
    return shouldCreate ? aabb : null;
  }

  isDirty() {
    return this._dirty;
  }
}
