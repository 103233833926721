import {LineIntersect2D} from './utils';

export interface Vector2 {
    x: number;
    y: number;
}

export function addVectors2(a: Vector2, b: Vector2): Vector2 {
    return {
        x: a.x + b.x,
        y: a.y + b.y
    };
}

export function subVectors2(a: Vector2, b: Vector2): Vector2 {
    return {
        x: a.x - b.x,
        y: a.y - b.y
    };
}

export function multiplyVectors2(a: Vector2, b: Vector2): Vector2 {
    return {
        x: a.x * b.x,
        y: a.y * b.y
    };
}

export function lengthVector2(a: Vector2): number {
    return Math.sqrt(a.x * a.x + a.y * a.y);
}

export function multiplyVector2byScalar(a: Vector2, s: number): Vector2 {
    return {
        x: a.x * s,
        y: a.y * s
    };
}

export function multiplyVector2ByFloats(a: Vector2, xScale: number, yScale: number): Vector2 {
    return {
        x: a.x * xScale,
        y: a.y * yScale
    };
}

export function dotVector2(a: Vector2, b: Vector2): number {
    if (a === null) {
        return 0;
    }

    if (b === null) {
        return 0;
    }

    return a.x * b.x + a.y * b.y;
}

export function sqrLengthVector2(a: Vector2): number {
    return a.x * a.x + a.y * a.y;
}

export function sqrDistanceVector2(a: Vector2, b: Vector2): number {
    const d = subVectors2(a, b);
    const dist = sqrLengthVector2(d);
    return dist;
}

export function distanceVector2(a: Vector2, b: Vector2): number {
    return Math.sqrt(sqrDistanceVector2(a, b));
}

export function normalizeVector2(a: Vector2): Vector2 {
    const length = lengthVector2(a);
    return multiplyVector2byScalar(a, 1 / length);
}


export function getDirectionFromAngle(angle: number): Vector2 {
    return {
        x: Math.cos(angle),
        y: Math.sin(angle)
    };
}

export function getLeftPerpendicularVector2(v: Vector2): Vector2 {
    return {
        x: -v.y,
        y: v.x
    };
}

export function getRightPerpendicularVector2(v: Vector2): Vector2 {
    return {
        x: v.y,
        y: -v.x
    };
}

export function crossVector2(a: Vector2, b: Vector2): number {
    return (a.x * b.y - a.y * b.x);
}

export function getDistanceOfPointFromLine(origin: Vector2, direction: Vector2, point: Vector2): number {
    const n = normalizeVector2(direction);
    const vectorFromPointToOrigin = subVectors2(origin, point);
    const vector = subVectors2(vectorFromPointToOrigin, multiplyVector2byScalar(n, dotVector2(vectorFromPointToOrigin, n)));
    return lengthVector2(vector);
}

export function pointOnLineProjection(point: Vector2, lineBegin: Vector2, direction: Vector2): Vector2 {
    const n = normalizeVector2(direction);
    const vectorFromPointToOrigin = subVectors2(lineBegin, point);
    return subVectors2(lineBegin, multiplyVector2byScalar(n, dotVector2(vectorFromPointToOrigin, n)));
}

export function projectPointOnLine(origin: Vector2, direction: Vector2, point: Vector2, maxDist?: number): Vector2 {
    const n = normalizeVector2(direction);
    const vectorFromPointToOrigin = subVectors2(origin, point);
    const vector = subVectors2(vectorFromPointToOrigin, multiplyVector2byScalar(n, dotVector2(vectorFromPointToOrigin, n)));
    if (maxDist) {
        if (lengthVector2(vector) < maxDist) {
            return addVectors2(point, vector);
        } else {
            return null;
        }
    } else {
        return addVectors2(point, vector);
    }

}

export function copyVector2(a: Vector2): Vector2 {
    return {
        x: a.x,
        y: a.y
    };
}

export function isPointBetween(start: Vector2, end: Vector2, point: Vector2): boolean {
    /*
    const startToEndDir = normalizeVector2( subVectors2(end, start) );
    const startToPointDir = normalizeVector2( subVectors2(point, start) );
    // check if points are colinear / aligned
    const cross = crossVector2(startToEndDir, startToPointDir);
    if (cross < -0.00001 || cross > 0.00001) {
        return false;
    }

    // check if point is between start and end
    const endToPointDir = normalizeVector2( subVectors2(point, end) );
    const startDot = dotVector2(startToEndDir, startToPointDir);
    const endDot = -dotVector2(startToEndDir, endToPointDir);
    //NaN means that points are on the same position an normalize returns NaN
    if ((startDot > -0.001 || isNaN(startDot )) && (endDot >  -0.001 || isNaN(endDot ))) {
        return true;
    }
    return false;

     */
    const sp = subVectors2(point, start);
    const ep = subVectors2(point, end);
    const se = subVectors2(start, end);
    return lengthVector2(sp) + lengthVector2(ep) < lengthVector2(se) + 0.00001;
}

export function compareVector2(a: Vector2, b: Vector2, epsilon: number): boolean {
    return sqrDistanceVector2(a, b) < (epsilon * epsilon);
}

export function projectPointToSegment(segmentBegin: Vector2, direction: Vector2, point: Vector2, maxDist: number = null): Vector2 {
    const mDist = maxDist * maxDist;
    const n = normalizeVector2(direction);
    const vectorFromPointToOrigin = subVectors2(segmentBegin, point);
    const e2 = subVectors2(point, segmentBegin);
    const valDp = dotVector2(direction, e2);
    const dot = dotVector2(vectorFromPointToOrigin, n);
    const vector = subVectors2(vectorFromPointToOrigin, multiplyVector2byScalar(n, dot));
    const dist = Math.abs(sqrLengthVector2(vector));
    const recArea = dotVector2(direction, direction);
    const val = dotVector2(direction, vectorFromPointToOrigin);
    const length = sqrLengthVector2(direction);
    if (maxDist) {
        if (val > 0 && val < recArea && dist < mDist) {
            return addVectors2(segmentBegin, multiplyVector2byScalar(multiplyVector2byScalar(direction, valDp), (1 / length)));
        }
    } else {
        return addVectors2(segmentBegin, multiplyVector2byScalar(multiplyVector2byScalar(direction, valDp), (1 / length)));
    }
    return null;
}

export function CosOfAngleBetween2Vectors2D(vec1: Vector2, vec2: Vector2): number {
    return dotVector2(vec1, vec2) / (lengthVector2(vec1) * lengthVector2(vec2));
}

export function SinOfAngleBetween2Vectors2D(vec1: Vector2, vec2: Vector2): number {
    return crossVector2(normalizeVector2(vec1), normalizeVector2(vec2));
}

export function Vectors2Equal(a: Vector2, b: Vector2): boolean {
    if (a === b) return true;
    if (a === null || b === null) return false;
    var dX = a.x - b.x;
    var dY = a.y - b.y;
    return dX * dX + dY * dY < 0.0000001;
}

export function rotateVector2(v: Vector2, angle: number): Vector2 {
    const c = Math.cos(angle);
    const s = Math.sin(angle);
    return {
        x: c * v.x - s * v.y,
        y: s * v.x + c * v.y
    };
}

export function isPointInsideShape2D(p: Vector2, shape: Vector2[]) {
    const rayDir = {x: 1, y: 0};
    let count = 0;
    for (let i = 0; i < shape.length; i++) {
        const current = shape[i];
        const next = shape[(i + 1) % shape.length];
        const dir = subVectors2(next, current);
        const intersection = LineIntersect2D(rayDir, p, dir, current);
        if (!!intersection) {
            count++;
        }
    }
    return count % 2 !== 0;
}

export function inside2D(point: Vector2, vs: Vector2[]) {
    // ray-casting algorithm based on
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

    const x = point.x, y = point.y;

    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        const xi = vs[i].x, yi = vs[i].y;
        const xj = vs[j].x, yj = vs[j].y;

        const intersect = ((yi > y) !== (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) { inside = !inside; }
    }

    return inside;
}

export function toPolarSystemWithOrigin(v:Vector2, origin:Vector2):Vector2 {
    const x:number = v.x-origin.x;
    const y:number = v.y-origin.y;
    return {
        x: Math.atan2(y,x),
        y: Math.sqrt( x*x+y*y)
    }
}

export function toPolarSystem(v:Vector2):Vector2 {
    return {
        x: Math.atan2(v.y, v.x),
        y: lengthVector2(v)
    }
}