import { combineLatest } from "rxjs";
import { PointerState } from "webcad/collision";
import { RotateCamera } from "webcad/components";
import { vector3ToBabylon3 } from "webcad/utils/cast";
import { Tool } from "../tools/tool.interface";
import { createLineSystemWithDepthOffset } from "../visualizers/line-system";
import { CursorType } from "./colliders/cursor.type";
import { MevacoCollider } from "./colliders/mevaco.collider";
import { MevacoPointerProvider } from "./mevaco-pointer.provider";
import { SceneProvider } from "./scene.provider";
import { ToolProvider } from "./tool.provider";
import {Color4, LinesMesh, Nullable, Observer, Scene, Vector3} from "@babylonjs/core";
export class PointerMeshProvider {
  private edgeSnapMesh: LinesMesh;
  private nodeAndGridSnapMesh: LinesMesh;
  private intersectionSnapMesh: LinesMesh;
  private noneSnapMesh: LinesMesh;
  private snapMesh: LinesMesh;
  private meshSize = 0.05;
  private scene: Scene;
  private sceneRenderSub: Nullable<Observer<Scene>>;

  constructor(
    private sceneProvider: SceneProvider,
    private pointerProvider: MevacoPointerProvider,
    private toolProvider: ToolProvider
  ) {
    this.sceneProvider.getSubscription().subscribe((value) => {
      if (this.sceneRenderSub) {
        this.scene.onBeforeRenderObservable.remove(this.sceneRenderSub);
        this.sceneRenderSub = null;
      }
      if (this.nodeAndGridSnapMesh) {
        this.nodeAndGridSnapMesh.dispose();
        this.nodeAndGridSnapMesh = null;
      }
      if (this.edgeSnapMesh) {
        this.edgeSnapMesh.dispose();
        this.edgeSnapMesh = null;
      }
      if (this.intersectionSnapMesh) {
        this.intersectionSnapMesh.dispose();
        this.intersectionSnapMesh = null;
      }
      if (this.noneSnapMesh) {
        this.noneSnapMesh.dispose();
        this.noneSnapMesh = null;
      }
      if (value !== null) {
        this.scene = value;
        this.sceneRenderSub = this.scene.onBeforeRenderObservable.add(
          (ed, es) => {
            const activeCamera = this.scene.activeCamera as unknown;
            const scale = 0.004 * (activeCamera as RotateCamera).radius;
            if (this.nodeAndGridSnapMesh) {
              this.nodeAndGridSnapMesh.scaling = new Vector3(
                scale * 20,
                scale * 20,
                1
              );
            }
            if (this.edgeSnapMesh) {
              this.edgeSnapMesh.scaling = new Vector3(
                scale * 20,
                scale * 20,
                1
              );
            }
            if (this.intersectionSnapMesh) {
              this.intersectionSnapMesh.scaling = new Vector3(
                scale * 40,
                scale * 40,
                1
              );
            }
            if (this.noneSnapMesh) {
              this.noneSnapMesh.scaling = new Vector3(
                scale * 40,
                scale * 40,
                1
              );
            }
          }
        );
        this.initializeMeshes();
      }
    });
    combineLatest([
      this.pointerProvider.pointerState,
      this.toolProvider.getToolObservable(),
    ]).subscribe(([pointerState, tool]) => {
      this.onPointerStateChange(pointerState, tool);
    });
  }

  initializeMeshes() {
    const positions = [
      [
        new Vector3(0, -this.meshSize, 0),
        new Vector3(0, this.meshSize, 0),
      ],
      [
        new Vector3(-this.meshSize, 0, 0),
        new Vector3(this.meshSize, 0, 0),
      ],
      [
        new Vector3(-this.meshSize, -this.meshSize, 0),
        new Vector3(this.meshSize, -this.meshSize, 0),
      ],
      [
        new Vector3(this.meshSize, -this.meshSize, 0),
        new Vector3(this.meshSize, this.meshSize, 0),
      ],
      [
        new Vector3(this.meshSize, this.meshSize, 0),
        new Vector3(-this.meshSize, this.meshSize, 0),
      ],
      [
        new Vector3(-this.meshSize, this.meshSize, 0),
        new Vector3(-this.meshSize, -this.meshSize, 0),
      ],
    ];
    const blue = new Color4(0, 0, 1, 1);
    const gray = new Color4(0.3, 0.3, 0.3, 1);
    const colors = [
      [gray, gray],
      [gray, gray],
      [blue, blue],
      [blue, blue],
      [blue, blue],
      [blue, blue],
    ];
    this.nodeAndGridSnapMesh = createLineSystemWithDepthOffset(
      "snapMesh",
      {
        lines: positions,
        colors: colors,
      },
      this.scene,
      -0.0006
    );
    this.nodeAndGridSnapMesh.isPickable = false;
    this.nodeAndGridSnapMesh.onBeforeRenderObservable.add(() => {
      this.scene.getEngine().setDepthBuffer(false);
    });
    this.nodeAndGridSnapMesh.onAfterRenderObservable.add(() => {
      this.scene.getEngine().setDepthBuffer(true);
    });
    this.nodeAndGridSnapMesh.isVisible = false;
    const edgeSnapPositions = [
      [
        new Vector3(-this.meshSize, -this.meshSize, 0),
        new Vector3(this.meshSize, -this.meshSize, 0),
      ],
      [
        new Vector3(this.meshSize, -this.meshSize, 0),
        new Vector3(-this.meshSize, this.meshSize, 0),
      ],
      [
        new Vector3(-this.meshSize, this.meshSize, 0),
        new Vector3(this.meshSize, this.meshSize, 0),
      ],
      [
        new Vector3(this.meshSize, this.meshSize, 0),
        new Vector3(-this.meshSize, -this.meshSize, 0),
      ],
    ];
    const edgeColors = [
      [blue, blue],
      [blue, blue],
      [blue, blue],
      [blue, blue],
    ];
    this.edgeSnapMesh = createLineSystemWithDepthOffset(
      "edgeSnapMesh",
      {
        lines: edgeSnapPositions,
        colors: edgeColors,
      },
      this.scene,
      -0.0006
    );
    this.edgeSnapMesh.isPickable = false;
    this.edgeSnapMesh.isVisible = false;
    const intersectionSnapMeshPositions: Vector3[][] = [
      [
        new Vector3(-this.meshSize, -this.meshSize, 0),
        new Vector3(this.meshSize, this.meshSize, 0),
      ],
      [
        new Vector3(this.meshSize, -this.meshSize, 0),
        new Vector3(-this.meshSize, this.meshSize, 0),
      ],
    ];
    const intersectionSnapMeshColors: Color4[][] = [
      [blue, blue],
      [blue, blue],
    ];
    this.intersectionSnapMesh = createLineSystemWithDepthOffset(
      "intersectionSnapMesh",
      {
        lines: intersectionSnapMeshPositions,
        colors: intersectionSnapMeshColors,
      },
      this.scene,
      -0.0006
    );
    this.intersectionSnapMesh.isPickable = false;
    this.intersectionSnapMesh.isVisible = false;

    const noneSnapMeshPositions: Vector3[][] = [
      [
        new Vector3(0, -this.meshSize, 0),
        new Vector3(0, this.meshSize, 0),
      ],
      [
        new Vector3(-this.meshSize, 0, 0),
        new Vector3(this.meshSize, 0, 0),
      ],
    ];
    const noneSnapMeshColors: Color4[][] = [
      [gray, gray],
      [gray, gray],
    ];
    this.noneSnapMesh = createLineSystemWithDepthOffset(
      "noneSnapMesh",
      {
        lines: noneSnapMeshPositions,
        colors: noneSnapMeshColors,
      },
      this.scene,
      -0.0006
    );
    this.noneSnapMesh.isPickable = false;
    this.noneSnapMesh.isVisible = false;

    this.snapMesh = this.nodeAndGridSnapMesh;
  }

  onPointerStateChange(pointerState: PointerState, tool: Tool) {
    const collider: MevacoCollider = pointerState.intersection
      ? (pointerState.intersection.collider as MevacoCollider)
      : null;
    const objectUnderPointer: any = collider ? collider.object : null;
    const cursor: CursorType =
      collider &&
      (collider.cursorType as CursorType) !== null &&
      objectUnderPointer
        ? collider.cursorType
        : CursorType.none;
    const shouldDisplay =
      !!this.snapMesh && tool !== this.toolProvider.selectTool;
    // Cursor
    if (!!this.snapMesh) {
      this.snapMesh.isVisible = false;
    }
    if (shouldDisplay) {
      switch (cursor) {
        case CursorType.point:
          this.snapMesh = this.nodeAndGridSnapMesh;
          this.snapMesh.isVisible = true;
          break;
        case CursorType.cross:
          this.snapMesh = this.intersectionSnapMesh;
          this.snapMesh.isVisible = true;
          break;
        case CursorType.envelope:
          this.snapMesh = this.edgeSnapMesh;
          this.snapMesh.isVisible = true;
          break;
        case CursorType.none:
          this.snapMesh = this.noneSnapMesh;
          this.snapMesh.isVisible = true;
          break;
      }
      this.snapMesh.position = vector3ToBabylon3(pointerState.position);
    }
  }

  disableSnapMesh() {
    this.snapMesh.isVisible = false;
  }
}
