import { Webcad } from 'webcad/core';
import { Vector3, sqrDistanceVector2 } from 'webcad/math';
import { AngleMeasurementVisualizer } from 'webcad/measurements';
import { ObjectUnderPoint } from 'webcad/models/ObjectUnderPoint';
import { CameraModel } from 'webcad/models/camera.model';
import {ChangeType, detectChangesInMap} from 'webcad/utils';
import { ModelVisualizer } from 'webcad/visualizers';
import { AngleMeasurementsViewModel } from '../model/view-model/angle-measurements.viewModel';

import { Node } from '@babylonjs/core';
export class AngleMeasurementsVisualizer
  implements ModelVisualizer<AngleMeasurementsViewModel> {
  private model: AngleMeasurementsViewModel;
  private cameraModel: CameraModel;
  private rootNode: Node;
  private webcad: Webcad;
  private measurementsVisualizers: Map<number, AngleMeasurementVisualizer>;

  constructor() {
    this.measurementsVisualizers = new Map<
      number,
      AngleMeasurementVisualizer
    >();
  }

  init(
    rootNode: Node,
    model: AngleMeasurementsViewModel,
    webcad: Webcad
  ): Promise<void> {
    this.rootNode = rootNode;
    this.model = model;
    this.webcad = webcad;
    this.cameraModel = this.webcad.viewState.camera;
    return Promise.resolve();
  }

  updateVisualization(newModel: AngleMeasurementsViewModel): void {
    detectChangesInMap(
      this.model.measurements,
      newModel.measurements,
      (type, key) => {
        switch (type) {
          case ChangeType.Added:
            const newVisualizer: AngleMeasurementVisualizer =
              new AngleMeasurementVisualizer();
            newVisualizer.init(
              this.rootNode,
              newModel.measurements.get(key),
              this.webcad
            );
            this.measurementsVisualizers.set(key, newVisualizer);
            break;
          case ChangeType.Changed:
            this.measurementsVisualizers
              .get(key)
              .updateVisualization(newModel.measurements.get(key));
            break;
          case ChangeType.Removed:
            this.measurementsVisualizers.get(key).dispose();
            this.measurementsVisualizers.delete(key);
            break;
        }
      },
      (key) => {
        if (this.cameraModel !== this.webcad.viewState.camera) {
          this.measurementsVisualizers
            .get(key)
            .updateVisualization(newModel.measurements.get(key));
        }
      }
    );
    if (newModel.state !== this.model.state) {
      const keys = Array.from(newModel.measurements.keys());
      for (const k of keys) {
        const measurement = newModel.measurements.get(k);
        newModel.measurements.set(k, {
          ...measurement,
          visible: newModel.state,
        });
        this.measurementsVisualizers
          .get(k)
          .updateVisualization(newModel.measurements.get(k));
      }
    }
    this.model = newModel;
    this.cameraModel = this.webcad.viewState.camera;
  }

  dispose(): void {}

  getObjectUnderPoint(point: Vector3, maxDist: number): ObjectUnderPoint {
    const objects: ObjectUnderPoint[] = [];
    this.measurementsVisualizers.forEach((v, k, m) => {
      const obj = v.getObjectUnderPoint(point, maxDist);
      if (obj) {
        objects.push(obj);
      }
    });
    let closestDist = Number.POSITIVE_INFINITY;
    let closest: ObjectUnderPoint = null;
    for (const o of objects) {
      const dist = sqrDistanceVector2(point, o.point);
      if (dist < closestDist) {
        closestDist = dist;
        closest = o;
      }
    }
    return closest;
  }
}
