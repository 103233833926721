import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslationProvider} from '../../../providers/translation.provider';
import {Action, select, Store} from '@ngrx/store';
import {
  ExpandedMetalOpenMeshChange,
  ExpandedMetalSizeChange,
  ExpandedMetalTypeChange,
  ExpandedMetalTypeOfCutChange,
  getExpandedMetalPossibleTypeOfCuts,
  getSelectedExpandedMetalOpenMesh,
  getSelectedExpandedMetalTypeOfCut,
  MevacoState,
  SetFeedrate,
  SetStegcluster,
} from '../../../store';
import {PossibleOptionsService} from '../../../services/possible-options.service';
import {Dropdown, DropdownOption} from '../../dropdown/dropdown.component';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';


@Component({
  selector: 'expanded-metal-configurator',
  templateUrl: './expanded-metal-configurator.component.html',
  styleUrls: ['./expanded-metal-configurator.component.css']
})
export class ExpandedMetalConfiguratorComponent implements OnInit, OnDestroy{

  public expandedMetalTypeDropdown: Dropdown;
  public expandedMetalSizeDropdown: Dropdown;
  //public expandedMetalTypeOfCutDropdown: Dropdown;

  public stegcluster = this.store.pipe(select((state) => state.model.productConfiguration.extendedMetal.stegcluster));
  public schmalPossible = this.store.pipe(select((state) => state.model.productConfiguration.configuration.schmalPossible));
  public breitPossible = this.store.pipe(select((state) => state.model.productConfiguration.configuration.breitPossible));
  public minFeedrate = this.store.pipe(select(state => state.model.productConfiguration.configuration.minFeedrate));
  public maxFeedrate = this.store.pipe(select(state => state.model.productConfiguration.configuration.maxFeedrate));
  public feedrate = this.store.pipe(select((state) => state.model.productConfiguration.extendedMetal.feedrate));

  public lsRandom = {
    disabled: false,
    selected: false,
    hidden: false,
    transparent: false,
    select: () => {
      if (this.wsOnKnots.selected) {
        this.store.dispatch(new ExpandedMetalTypeOfCutChange('A'));
      } else {
        this.store.dispatch(new ExpandedMetalTypeOfCutChange('D'));
      }
    },
    shoRelations: (show: boolean) => {
      this.wsSymmetric.transparent = show;
    }
  };
  public wsRandom = {
    disabled: false,
    selected: false,
    hidden: false,
    transparent: false,
    select: () => {
      if (this.lsOnKnots.selected) {
        this.store.dispatch(new ExpandedMetalTypeOfCutChange('E'));
      } else {
        this.store.dispatch(new ExpandedMetalTypeOfCutChange('D'));
      }
    },
    shoRelations: (show: boolean) => {
      this.lsSymmetric.transparent = show;
    }
  };
  public lsSymmetric = {
    disabled: false,
    selected: false,
    hidden: false,
    transparent: false,
    select: () => {
      if (this.wsOnKnots.selected) {
        this.store.dispatch(new ExpandedMetalTypeOfCutChange('B'));
      } else {
        this.store.dispatch(new ExpandedMetalTypeOfCutChange('F'));
      }
    },
    shoRelations: (show: boolean) => {
      this.wsRandom.transparent = show;
    }
  };
  public wsSymmetric = {
    disabled: false,
    selected: false,
    hidden: false,
    transparent: false,
    select: () => {
      this.store.dispatch(new ExpandedMetalTypeOfCutChange('F'));
    },
    shoRelations: (show: boolean) => {
      this.lsOnKnots.transparent = show;
      this.lsRandom.transparent = show;
    }
  };
  public symmetricBig = {
    disabled: false,
    selected: false,
    hidden: false,
    transparent: false,
    select: () => {
      this.store.dispatch(new ExpandedMetalOpenMeshChange('Big'));
    },
    shoRelations: (show: boolean) => {
    }
  };
  public symmetricSmall = {
    disabled: false,
    selected: false,
    hidden: false,
    transparent: false,
    select: () => {
      this.store.dispatch(new ExpandedMetalOpenMeshChange('Small'));
    },
    shoRelations: (show: boolean) => {
    }
  };
  public lsOnKnots = {
    disabled: false,
    selected: false,
    hidden: false,
    transparent: false,
    select: () => {
      if (this.wsOnKnots.selected) {
        this.store.dispatch(new ExpandedMetalTypeOfCutChange('C'));
      } else {
        this.store.dispatch(new ExpandedMetalTypeOfCutChange('E'));
      }
    },
    shoRelations: (show: boolean) => {
      this.wsSymmetric.transparent = show;
    }
  };
  public wsOnKnots = {
    disabled: false,
    selected: false,
    hidden: false,
    transparent: false,
    select: () => {
      if (this.lsOnKnots.selected) {
        this.store.dispatch(new ExpandedMetalTypeOfCutChange('C'));
      } else if (this.lsSymmetric.selected) {
        this.store.dispatch(new ExpandedMetalTypeOfCutChange('B'));
      } else {
        this.store.dispatch(new ExpandedMetalTypeOfCutChange('A'));
      }
    },
    shoRelations: (show: boolean) => {
      this.wsSymmetric.transparent = show;
    }
  };
  public onKnotsOpen = {
    disabled: false,
    selected: false,
    hidden: false,
    transparent: false,
    select: () => {
      this.store.dispatch(new ExpandedMetalTypeOfCutChange('H'));
    },
    shoRelations: (show: boolean) => {
    }
  };
  public onKnotsClose = {
    disabled: false,
    selected: false,
    hidden: false,
    transparent: false,
    select: () => {
      this.store.dispatch(new ExpandedMetalTypeOfCutChange('G'));
    },
    shoRelations: (show: boolean) => {
    }
  };
  public custom = {
    disabled: false,
    selected: false,
    hidden: false,
    transparent: false,
    select: () => {
      this.store.dispatch(new ExpandedMetalTypeOfCutChange('M'));
    },
    shoRelations: (show: boolean) => {
    }
  };


  private sub: Subscription[];


  constructor(
    private translationProvider: TranslationProvider,
    private store: Store<MevacoState>,
    private possibleOptionsService: PossibleOptionsService
  ) {
  }

  ngOnDestroy() {
    this.sub.forEach(s => s.unsubscribe());
    this.sub = [];
  }

  ngOnInit() {
    this.expandedMetalTypeDropdown = this.createDropDown(
      this.translate('Shape'),
      this.store.pipe(
        select((state) => state.model.productConfiguration.extendedMetal.type)
      ) as Observable<string>,
      this.getExpandedMetalTypeOptions(),
      (value) => new ExpandedMetalTypeChange(value),
      this.possibleOptionsService.possibleExpandedMetalTypes
    );

    this.expandedMetalSizeDropdown = this.createDropDown(
      this.translate('EM_Format'),
      this.store.pipe(
        select((state) => state.model.productConfiguration.extendedMetal.size)
      ) as Observable<string>,
      this.getExpandedMetalSizeOptions(),
      (value) => new ExpandedMetalSizeChange(value),
      this.possibleOptionsService.possibleExpandedMetalSizes
    );

    this.sub = [
      combineLatest([
        this.store.pipe(select(getSelectedExpandedMetalTypeOfCut)),
        this.store.pipe(select(getSelectedExpandedMetalOpenMesh)),
      ]).subscribe( ([toc, om]) => this.showTypeOfCut(toc, om)),
      this.store.pipe(select(getExpandedMetalPossibleTypeOfCuts)).subscribe( typeOfCuts => this.disableTypeofCuts(typeOfCuts)),
  ];

  }
  translate(text: string, module: string = 'configurator') {
    return this.translationProvider.translate(text, module);
  }

  createDropDown(
    name: string,
    value: Observable<string>,
    options: Observable<DropdownOption[]>,
    actionFactory: (string) => Action,
    possibleOptions: Observable<string[]> = of(null)
  ) {
    return new Dropdown(
      name,
      options,
      value,
      (v) => this.store.dispatch(actionFactory(v)),
      null,
      possibleOptions
    );
  }

  private getExpandedMetalTypeOptions(): Observable<DropdownOption[]> {
    return this.store.pipe(select((store) => store.model.dataset)).pipe(
      map((dataset) => {
        if (!dataset) {
          return [
            new DropdownOption(
              'text',
              '',
              true,
              '',
              this.translate('Shape')
            ),
          ];
        }
        return Object.keys( dataset.expandedMetals ).map(
          (expMatType) =>
            new DropdownOption(
              'withImage',
              {
                name: this.translate(expMatType),
                imgUrl:
                  dataset.extendedMetalAssetsUrl +
                  dataset.expandedMetalsIcons[expMatType],
              },
              true,
              expMatType,
              this.translate('Shape')
            )
        );
      })
    );
  }

  private getExpandedMetalSizeOptions(): Observable<DropdownOption[]> {
    return combineLatest([
      this.store.pipe( select((store) => store.model.dataset) ),
      this.store.pipe( select( (store) => store.model.productConfiguration.extendedMetal.type))
    ]).pipe(
      map(([dataset, expMetType]) => {
        if (!dataset || !expMetType) {
          return [
            new DropdownOption(
              'text',
              '',
              true,
              '',
              this.translate('EM_Format')
            ),
          ];
        }

        const sizes = Object.keys(dataset.expandedMetals[expMetType]);
        sizes.sort((a, b) => {
          const [a1, a2] = a.split(' x ');
          const [b1, b2] = b.split(' x ');
          return ( +a1 - +b1) * 1000 + +a2 - +b2;
        });
        return sizes.map(
          (size) =>
            new DropdownOption(
              'text',
              size,
              true,
              size,
              this.translate('EM_Format')
            )
        );
      })
    );
  }

  private showTypeOfCut(toc: string, openMesh: string) {
    this.lsRandom.selected = false;
    this.wsRandom.selected = false;
    this.lsSymmetric.selected = false;
    this.wsSymmetric.selected = false;
    this.symmetricBig.selected = false;
    this.symmetricSmall.selected = false;
    this.lsOnKnots.selected = false;
    this.wsOnKnots.selected = false;
    this.onKnotsClose.selected = false;
    this.onKnotsOpen.selected = false;
    this.custom.selected = false;

    this.symmetricBig.hidden = toc !== 'B' && toc !== 'F';
    this.symmetricSmall.hidden = toc !== 'B' && toc !== 'F';

    this.onKnotsClose.hidden = toc !== 'C' && toc !== 'G' && toc !== 'H';
    this.onKnotsOpen.hidden =  toc !== 'C' && toc !== 'G' && toc !== 'H';
    switch (toc) {
      case 'D':
        this.lsRandom.selected = true;
        this.wsRandom.selected = true;
        break;
      case 'A':
        this.lsRandom.selected = true;
        this.wsOnKnots.selected = true;
        break;
      case 'E':
        this.lsOnKnots.selected = true;
        this.wsRandom.selected = true;
        break;
      case 'C':
        this.lsOnKnots.selected = true;
        this.wsOnKnots.selected = true;
        break;
      case 'B':
        this.lsSymmetric.selected = true;
        this.wsOnKnots.selected = true;
        break;
      case 'F':
        this.lsSymmetric.selected = true;
        this.wsSymmetric.selected = true;
        break;
      case 'G':
        this.onKnotsClose.selected = true;
        break;
      case 'H':
        this.onKnotsOpen.selected = true;
        break;
      case 'M':
        this.custom.selected = true;
        break;
    }

    if (openMesh === 'Small') {
      this.symmetricSmall.selected = true;
    }
    if (openMesh === 'Big') {
      this.symmetricBig.selected = true;
    }
  }
  disableTypeofCuts(typeOfCuts: string[]) {
    this.lsRandom.disabled = !typeOfCuts.some( toc => toc === 'A' || toc === 'D');
    this.wsRandom.disabled = !typeOfCuts.some( toc => toc === 'E' || toc === 'D');
    this.lsSymmetric.disabled = !typeOfCuts.some( toc => toc === 'F' || toc === 'B');
    this.wsSymmetric.disabled = !typeOfCuts.some( toc => toc === 'F');
    this.symmetricBig.disabled = false;
    this.symmetricSmall.disabled = false;
    this.lsOnKnots.disabled = !typeOfCuts.some( toc => toc === 'C' || toc === 'E');
    this.wsOnKnots.disabled = !typeOfCuts.some( toc => toc === 'A' || toc === 'B' || toc === 'C');
    this.onKnotsClose.disabled = !typeOfCuts.some( toc => toc === 'G');
    this.onKnotsOpen.disabled = !typeOfCuts.some( toc => toc === 'H');
    this.custom.disabled = !typeOfCuts.some( toc => toc === 'M');
  }

  setStegcluster(stegcluster: string): void {
    this.store.dispatch( new SetStegcluster(stegcluster) );
  }

  setFeedrate(val: string) {
    this.store.dispatch( new SetFeedrate(val) );
  }
}
