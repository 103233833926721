import { Store, select } from "@ngrx/store";
import { PointerState } from "webcad/collision";
import { Vector3 } from "webcad/math";
import {
  LineSegmentsFromPath,
  Segment,
  aabbOfPolylines,
  getPointsFromSegment,
} from "webcad/models";
import { ActionType } from "../../../model";
import { UnderPointerType } from "../../../model/pointer-state.model";
import { MevacoCollider } from "../../../providers/colliders/mevaco.collider";
import { SegmentCollider } from "../../../providers/colliders/segment.collider";
import {
  ClosestSegments,
  MevacoPointerProvider,
} from "../../../providers/mevaco-pointer.provider";
import { SceneProvider } from "../../../providers/scene.provider";
import { TranslationProvider } from "../../../providers/translation.provider";
import { AddShapeToAdd, ChangeActionType } from "../../../store/actions";
import {
  RequestRender,
  SetHintMessage,
} from "../../../store/actions/drawing.actions";
import {
  MevacoState,
  getImportedShape,
  getPlate,
} from "../../../store/reducers";
import { createLineSystemWithDepthOffset } from "../../../visualizers/line-system";
import { DifferentiationTool } from "../../differentiation-tool.interface";
import {Color4, LinesMesh, Scene, Vector3 as B_Vector3} from "@babylonjs/core";
export class SelectPerforationTool extends DifferentiationTool {
  private scene: Scene;
  private lastObjectUnderPointer: MevacoCollider;
  private pointerState: PointerState;
  private ctrl: boolean;
  private PointerDown: (pointerPosition: Vector3, ctrl?: boolean) => void;
  private PointerClick: (pointerPosition: Vector3, ctrl?: boolean) => void;
  private PointerMove: (pointerPosition: Vector3, ctrl?: boolean) => void;
  private PointerUp: (pointerPosition: Vector3, ctrl?: boolean) => void;
  private shape: Segment[][];
  private currentSelected: Segment[][] = [];
  private dragging: boolean = false;
  private depth: number;
  private currentImportOutlineMesh: LinesMesh;

  constructor(
    private pointerController: MevacoPointerProvider,
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private translationProvider: TranslationProvider
  ) {
    super();
    this.sceneProvider.getSubscription().subscribe((scene) => {
      if (scene !== null) {
        this.scene = scene;
      }
      this.store.dispatch(new RequestRender());
    });
    this.store.pipe(select(getPlate)).subscribe((plate) => {
      this.depth = plate.depth;
    });
    this.pointerController.pointerState.subscribe((v) => {
      this.ctrl = v.ctrl;
      let objectUnderPointer: MevacoCollider = this.lastObjectUnderPointer;
      this.pointerState = v;
      if (this.pointerState) {
        // if (!this.dragging) {
        //   this._canDrag = this.canDrag(v.position);
        // }
        objectUnderPointer = this.pointerState.intersection
          ? (this.pointerState.intersection.collider as MevacoCollider)
          : null;
        this.lastObjectUnderPointer = objectUnderPointer;
      }
      if (!objectUnderPointer) {
        this.PointerClick = this.onMouseClickOnGridOrNone;
        this.PointerDown = this.onMouseDownOnGridOrNone;
        this.PointerUp = this.onMouseUpOnGridOrNone;
        this.PointerMove = this.onMouseMoveOnGridOrNone;
      } else {
        switch (objectUnderPointer.objectType) {
          case UnderPointerType.NODE:
            this.PointerClick = this.onMouseClickOnNode;
            this.PointerDown = this.onMouseDownOnNode;
            this.PointerUp = this.onMouseUpOnNode;
            this.PointerMove = this.onMouseMoveOnNode;
            break;
          case UnderPointerType.SEGMENT:
            this.PointerClick = this.onMouseClickOnSegment;
            this.PointerDown = this.onMouseDownOnSegment;
            this.PointerUp = this.onMouseUpOnSegment;
            this.PointerMove = this.onMouseMoveOnSegment;
            break;
          case UnderPointerType.HELP_LINE:
            this.PointerClick = this.onMouseClickOnHelpLine;
            this.PointerDown = this.onMouseDownOnHelpLine;
            this.PointerUp = this.onMouseUpOnHelpLine;
            this.PointerMove = this.onMouseMoveOnHelpLine;
            break;
          case UnderPointerType.MEASUREMENT:
            this.PointerClick = this.onMouseClickOnMeasurement;
            this.PointerDown = this.onMouseDownOnMeasurement;
            this.PointerUp = this.onMouseUpOnMeasurement;
            this.PointerMove = this.onMouseMoveOnMeasurement;
            break;
          case UnderPointerType.ANGLE_MEASUREMENT:
            this.PointerClick = this.onMouseClickOnAngleMeasurement;
            this.PointerDown = this.onMouseDownOnAngleMeasurement;
            this.PointerUp = this.onMouseUpOnAngleMeasurement;
            this.PointerMove = this.onMouseMoveOnAngleMeasurement;
            break;
          case UnderPointerType.MOUNTING:
            this.PointerClick = this.onMouseClickOnMounting;
            this.PointerDown = this.onMouseDownOnMounting;
            this.PointerUp = this.onMouseUpOnMounting;
            this.PointerMove = this.onMouseMoveOnMounting;
            break;
          case UnderPointerType.PERFORATION_AREA:
            this.PointerClick = this.onMouseClickOnPerforationArea;
            this.PointerDown = this.onMouseDownOnPerforationArea;
            this.PointerUp = this.onMouseUpOnPerforationArea;
            this.PointerMove = this.onMouseMoveOnPerforationArea;
            break;
          default:
            this.PointerClick = this.onMouseClickOnGridOrNone;
            this.PointerDown = this.onMouseDownOnGridOrNone;
            this.PointerUp = this.onMouseUpOnGridOrNone;
            this.PointerMove = this.onMouseMoveOnGridOrNone;
            break;
        }
      }
    });
    this.store.pipe(select(getImportedShape)).subscribe((v) => {
      this.shape = v;
    });
  }

  activate() {
    this.store.dispatch(new ChangeActionType(ActionType.ADD));
    this.store.dispatch(
      new SetHintMessage(
        this.translationProvider.translate("startPerforationImportHint")
      )
    );
  }

  isDirty(): boolean {
    return this.currentSelected.length > 0;
  }

  onCancel() {
    this.currentSelected = [];
    this.visualizeCurrentSelection();
  }

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {}

  onConfirm() {
    const currentSelected = this.currentSelected;
    const aabb = aabbOfPolylines(this.currentSelected);
    const shape = LineSegmentsFromPath([
      aabb.min,
      { x: aabb.max.x, y: aabb.min.y },
      aabb.max,
      { x: aabb.min.x, y: aabb.max.y },
    ]);
    this.store.dispatch(new AddShapeToAdd(shape));
    this.reset();
  }

  onMouseClick(pointerState: PointerState) {
    this.PointerClick(pointerState.position, pointerState.ctrl);
    if (this.currentSelected.length > 0) {
      this.onConfirm();
    }
  }

  onMouseClickOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnMounting(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnNode(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnSegment(pointerPosition: Vector3, ctrl?: boolean) {
    if (this.pointerState.intersection) {
      if (
        (this.pointerState.intersection.collider as SegmentCollider).segment
      ) {
        const segment = (
          this.pointerState.intersection.collider as SegmentCollider
        ).segment;
        for (const c of this.shape) {
          if (c.includes(segment)) {
            if (this.currentSelected.includes(c)) {
              this.currentSelected.splice(
                this.currentSelected.findIndex((v) => v === c),
                1
              );
            } else {
              this.currentSelected.push(c);
            }
            this.visualizeCurrentSelection();
            return;
          }
        }
      }
    }
  }

  onMouseDown(pointerState: PointerState) {}

  onMouseDownOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnMounting(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnNode(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnSegment(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMove(pointerState: PointerState) {}

  onMouseMoveOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnMounting(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnNode(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnSegment(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUp(pointerState: PointerState) {}

  onMouseUpOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnMounting(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnNode(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnSegment(pointerPosition: Vector3, ctrl?: boolean) {}

  reset() {
    this.onCancel();
  }

  private visualizeCurrentSelection() {
    if (this.currentImportOutlineMesh) {
      this.currentImportOutlineMesh.dispose();
      this.currentImportOutlineMesh = null;
    }
    if (this.currentSelected) {
      const outlinePoints: B_Vector3[][] = [];
      const colors: Color4[][] = [];
      const color = new Color4(0, 1, 0, 1);

      for (const conture of this.currentSelected) {
        const outline: B_Vector3[] = [];
        const outlineColor: Color4[] = [];
        for (const p of conture) {
          const points = getPointsFromSegment(p);
          for (const point of points) {
            outline.push(
              new B_Vector3(point.x, point.y, -this.depth / 2)
            );
            outlineColor.push(color);
          }
        }
        outline.push(outline[0]);
        outlineColor.push(outlineColor[0]);
        outlinePoints.push(outline);
        colors.push(outlineColor);
      }
      this.currentImportOutlineMesh = createLineSystemWithDepthOffset(
        "import Outline",
        {
          lines: outlinePoints,
          colors: colors,
        },
        this.scene,
        -0.0006
      );
      this.currentImportOutlineMesh.isPickable = false;
    }
  }
}
