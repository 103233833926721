import { Webcad } from "webcad";
import { InstancedMesh } from "webcad/core/instanced-mesh";
import { Vector2, Vector3 } from "webcad/math";
import { getPointsFromSegment, Segment } from "webcad/models";
import { CameraModel } from "webcad/models/camera.model";
import { ObjectUnderPoint } from "webcad/models/ObjectUnderPoint";
import { ModelVisualizer } from "webcad/visualizers";
import {
  createMountingMeshFrom,
  Mounting,
  MountingAttachment,
  mountingToPoints,
  scaleMounting,
} from "../model/mounting.model";
import {
  BendingNode,
  findNodeThatContains,
} from "../model/view-model/bending-node.viewModel";
import { MountingViewModel } from "../model/view-model/mounting.viewModel";
import { createLineSystemWithDepthOffsetSingleColor } from "./line-system";
import {Effect, Node} from "@babylonjs/core";
import {
  Engine,
  LinesMesh,
  Scene,
  VertexBuffer,
  Buffer,
  ShaderMaterial,
  AbstractMesh,
  BoundingInfo,
  Vector4,
  Vector3 as B_Vector3
} from "@babylonjs/core";

export class MountingsVisualizer implements ModelVisualizer<MountingViewModel> {
  constructor() {
    this.mountingMeshes = [];
    this.mountingOutlines = null;
  }

  private model: MountingViewModel;
  private cameraModel: CameraModel;
  private rootNode: Node;
  private webcad: Webcad;
  private mountingMeshes: InstancedMesh[];
  private mountingOutlines: LinesMesh;
  private engine: Engine;

  private static createHoleInteriorMesh(
    mounting: Mounting,
    shape: Segment[],
    scene: Scene,
    depth: number,
    mountingsPositions: Vector2[],
    rotation: number
  ): InstancedMesh {
    const mesh = new InstancedMesh(
      "interior",
      mountingsPositions.length,
      scene
    );
    const shapePositions = mountingToPoints(mounting, shape, rotation);
    const positions: number[] = [];
    const indices: number[] = [];
    let ind = 0;
    for (let j = 0; j < shapePositions.length; j++) {
      const vector1 = shapePositions[j];
      const vector2 = shapePositions[(j + 1) % shapePositions.length];
      positions.push(vector2.x, vector2.y, depth / 2);
      positions.push(vector1.x, vector1.y, depth / 2);
      positions.push(vector2.x, vector2.y, -depth / 2);
      positions.push(vector1.x, vector1.y, -depth / 2);

      indices.push(ind, ind + 3, ind + 1);
      indices.push(ind + 2, ind + 3, ind);
      ind += 4;
    }

    mesh.setVerticesData(VertexBuffer.PositionKind, positions, false);
    mesh.setIndices(indices);

    const mPositions: number[] = [];
    for (let i = 0; i < mountingsPositions.length; i++) {
      const mountingsPosition = mountingsPositions[i];
      mPositions.push(mountingsPosition.x);
      mPositions.push(mountingsPosition.y);
    }
    const instancesBuffer = new Buffer(
      scene.getEngine(),
      mPositions,
      false,
      2,
      false,
      true
    );
    (mesh as any)._instancesBuffer = instancesBuffer;
    const positionsVertexBuffer = instancesBuffer.createVertexBuffer(
      "instancePos",
      0,
      2
    );
    mesh.setVerticesBuffer(positionsVertexBuffer);

    return mesh;
  }

  removeMeshes(): void {
    for (let i = 0; i < this.mountingMeshes.length; i++) {
      this.mountingMeshes[i].dispose();
    }
    if (!!this.mountingOutlines) {
      this.mountingOutlines.dispose();
    }
    this.mountingMeshes = [];
    this.mountingOutlines = null;
  }

  init(
    rootNode: Node,
    model: MountingViewModel,
    webcad: Webcad
  ): Promise<void> {
    this.rootNode = rootNode;
    this.model = model;
    this.webcad = webcad;
    this.engine = this.rootNode.getEngine();
    this.cameraModel = this.webcad.viewState.camera;
    // this.createMaterial(this.rootNode.getScene());
    createShaders();
    return Promise.resolve();
  }

  private createMaterial(scene: Scene) {
    const material = new ShaderMaterial(
      "shader",
      scene,
      {
        vertex: "mounting",
        fragment: "mounting",
      },
      {
        attributes: ["position", "instancePos"],
        uniforms: ["worldViewProjection", "worldView"],
      }
    );
    return material;
  }

  private createInteriorMaterial(scene: Scene) {
    const material = new ShaderMaterial(
      "shader",
      scene,
      {
        vertex: "mountingInterior",
        fragment: "mountingInterior",
      },
      {
        attributes: ["position", "instancePos"],
        uniforms: ["worldViewProjection", "worldView"],
      }
    );
    material.backFaceCulling = false;
    return material;
  }
  /*
    createMountingVisualization(newModel: MountingViewModel, key: number) {
      let world: BABYLON.Matrix = BABYLON.Matrix.Identity();
      let offsetoffset: BABYLON.Matrix = BABYLON.Matrix.Identity();
      const mountingAttachment = newModel.mountings.get(key);
      if (mountingAttachment && mountingAttachment.mountingRef.form !== null) {
        let perforationNode: BendingNode;
        if (mountingAttachment.position) {
          perforationNode = findNodeThatContains(mountingAttachment.position, newModel.bendingTree);
          if (perforationNode) {
            offset = BABYLON.Matrix.RotationZ(mountingAttachment.rotation)
              .multiply(BABYLON.Matrix.Translation(mountingAttachment.position.x, mountingAttachment.position.y, 0));
            world = perforationNode.world;

            const outlines = this.createOutlineVisualization(mountingAttachment, newModel.shapes, key, this.rootNode.getScene(), newModel.depth);
            outlines._worldMatrix.copyFrom(world.multiply(offset));
            this.mountingOutlines.set(key, outlines);
            const mounting = this.createMountingHoleVisualization(mountingAttachment, newModel.shapes, key, this.rootNode.getScene(), newModel.depth, perforationNode.flatRegion, world, offset);
            this.mountingMeshes.set(key, mounting);
          }
        }
      }
    }
  */
  updateVisualization(newModel: MountingViewModel): void {
    if (
      this.model.mountings !== newModel.mountings ||
      this.model.bendingTree !== newModel.bendingTree
    ) {
      this.removeMeshes();
      const scene = this.rootNode.getScene();
      const groups = groupMountings(newModel.mountings, newModel.bendingTree);
      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];
        const positions = group.mountings.map((m) => m.position);
        const mesh = createMountingMeshFrom(
          group.mountings[0].mountingRef,
          group.mountings[0].shape,
          scene,
          newModel.depth,
          group.mountings[0].rotation,
          positions
        );
        mesh.freezeWorldMatrix();
        mesh._worldMatrix = group.node.world;
        mesh.renderingGroupId = 2;
        mesh.metadata = mesh.metadata || {};
        mesh.metadata.renderPriority = 1;
        mesh.metadata.region = group.node && group.node.flatRegion;
        mesh.occlusionType = AbstractMesh.OCCLUSION_TYPE_NONE;
        mesh.setBoundingInfo(
          new BoundingInfo(
            new B_Vector3(
              -Number.MAX_VALUE,
              -Number.MAX_VALUE,
              -Number.MAX_VALUE
            ),
            new B_Vector3(
              Number.MAX_VALUE,
              Number.MAX_VALUE,
              Number.MAX_VALUE
            )
          )
        );
        mesh.material = this.createMaterial(scene);
        mesh.onBeforeRenderObservable.add(() => {
          const gl = mesh.getEngine()._gl;
          gl.enable(gl.POLYGON_OFFSET_FILL);
          gl.polygonOffset(-2, -2);
          this.engine.setColorWrite(!group.mountings[0].possible);
        });

        mesh.onAfterRenderObservable.add(() => {
          const gl = mesh.getEngine()._gl;
          gl.disable(gl.POLYGON_OFFSET_FILL);
          this.engine.setColorWrite(true);
        });

        this.mountingMeshes.push(mesh as InstancedMesh);

        const interiorMesh = MountingsVisualizer.createHoleInteriorMesh(
          group.mountings[0].mountingRef,
          group.mountings[0].shape,
          scene,
          newModel.depth,
          positions,
          group.mountings[0].rotation
        );

        interiorMesh.freezeWorldMatrix();
        interiorMesh._worldMatrix = group.node.world;
        interiorMesh.renderingGroupId = 2;
        interiorMesh.metadata = interiorMesh.metadata || {};
        interiorMesh.metadata.renderPriority = 0;
        interiorMesh.metadata.region = group.node && group.node.flatRegion;
        interiorMesh.occlusionType = AbstractMesh.OCCLUSION_TYPE_NONE;
        interiorMesh.setBoundingInfo(
          new BoundingInfo(
            new B_Vector3(
              -Number.MAX_VALUE,
              -Number.MAX_VALUE,
              -Number.MAX_VALUE
            ),
            new B_Vector3(
              Number.MAX_VALUE,
              Number.MAX_VALUE,
              Number.MAX_VALUE
            )
          )
        );
        interiorMesh.material = this.createInteriorMaterial(scene);
        this.mountingMeshes.push(interiorMesh as InstancedMesh);
      }
      if (newModel.outlineState) {
        this.mountingOutlines = this.createOutlineVisualization(
          newModel,
          scene
        );
      }
    }
    /*
    if (this.model.state !== newModel.state) {
      this.mountingMeshes.forEach((v) => v.getChildMeshes().forEach( m => m.isVisible = newModel.state));
      this.mountingOutlines.forEach((v) => v.isVisible = newModel.state);
    }
    if (this.model.outlineState !== newModel.outlineState) {
      this.mountingOutlines.forEach((v) => v.isVisible = newModel.state && newModel.outlineState);
    }
    */
    this.model = newModel;
    this.cameraModel = this.webcad.viewState.camera;
  }

  dispose(): void {
    this.removeMeshes();
  }

  createOutlineVisualization(
    model: MountingViewModel,
    scene: Scene
  ): LinesMesh {
    const groups = groupMountings(model.mountings, model.bendingTree);
    const lines: B_Vector3[][] = [];
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      const shape = scaleMounting(
        group.mountings[0].mountingRef,
        model.shapes.get(group.mountings[0].mountingRef.form),
        group.mountings[0].rotation
      );
      for (let j = 0; j < group.mountings.length; j++) {
        lines.push([]);
        const position = group.mountings[j].position;
        for (const s of shape) {
          const points = getPointsFromSegment(s);
          for (let k = 0; k < points.length; k++) {
            const v = points[k];
            lines[lines.length - 1].push(
              new B_Vector3(
                v.x + position.x,
                v.y + position.y,
                -model.depth / 2
              )
            );
          }
        }
        lines[lines.length - 1].push(lines[lines.length - 1][0]);
      }
    }
    const mesh = createLineSystemWithDepthOffsetSingleColor(
      "mounting Outline",
      {
        lines: lines,
        color: new Vector4(0, 0, 1, 1),
      },
      scene,
      -0.0006
    );
    mesh.freezeWorldMatrix();
    mesh.alwaysSelectAsActiveMesh = true;
    mesh.renderingGroupId = 3;
    mesh.setBoundingInfo(
      new BoundingInfo(
        new B_Vector3(
          -Number.MAX_VALUE,
          -Number.MAX_VALUE,
          -Number.MAX_VALUE
        ),
        new B_Vector3(
          Number.MAX_VALUE,
          Number.MAX_VALUE,
          Number.MAX_VALUE
        )
      )
    );
    return mesh;
  }
  /*
  createMountingHoleVisualization(model: MountingAttachment, shapes: Map<string, Segment[]>, key: number, scene: BABYLON.Scene, thickness: number, flatRegion: Region, world: BABYLON.Matrix, offsetMatix: BABYLON.Matrix): BABYLON.Node {
    const node = new BABYLON.Node('mounting', scene);
    const positions = mountingToPoints(model.mountingRef, model.shape, 0);
    const mesh = buildMesh([positions], scene, thickness, [{x: 0, y: 0}]);
    mesh.name = 'mounting: ' + key.toString();

    mesh.material = this.createMaterial(this.rootNode.getScene(), offsetMatix);
    mesh.freezeWorldMatrix();
    mesh.onBeforeRenderObservable.add((v) => {
      this.engine.setColorWrite(!model.possible);
      const gl = mesh.getEngine()._gl;
      gl.enable(gl.POLYGON_OFFSET_FILL);
      gl.polygonOffset(-2, -2);
    });

    mesh.onAfterRenderObservable.add((ed, es) => {
      this.engine.setColorWrite(true);
      const gl = mesh.getEngine()._gl;
      gl.disable(gl.POLYGON_OFFSET_FILL);
    });
    mesh.alwaysSelectAsActiveMesh = true;

    mesh.parent = node;
    mesh.renderingGroupId = 2;
    mesh.metadata = mesh.metadata || {};
    mesh.metadata.region = flatRegion;
    mesh.metadata.renderPriority = 1;
    mesh._worldMatrix.copyFrom(world);


    const pos = new Array<number>();
    const indices: number[] = [];
    let ind = 0;
    for (let j = 0; j < positions.length; j++) {
      const vector1 = positions[j];
      const vector2 = positions[(j + 1) % positions.length];
      pos.push(vector2.x, vector2.y, thickness / 2);
      pos.push(vector1.x, vector1.y, thickness / 2);
      pos.push(vector2.x, vector2.y, -thickness / 2);
      pos.push(vector1.x, vector1.y, -thickness / 2);

      indices.push(ind, ind + 3, ind + 1);
      indices.push(ind + 2, ind + 3, ind );
      ind += 4;
    }
    const vertexData = new BABYLON.VertexData();
    vertexData.positions = pos;
    vertexData.indices = indices;
    const interior = new BABYLON.Mesh('mounting interior', scene);
    vertexData.applyToMesh(interior);
    interior.material = new BABYLON.ShaderMaterial('shader', scene, {
        vertex: 'mountingInterior',
        fragment: 'mountingInterior',
      },
      {
        attributes: ['position'],
        uniforms: ['worldViewProjection', 'offset']
      });

    (interior.material as ShaderMaterial).setMatrix('offset', offsetMatix);

    interior.parent = node;
    interior.renderingGroupId = 2;
    interior.metadata = interior.metadata || {};
    interior.metadata.region = flatRegion;
    interior.metadata.renderPriority = 0;
    interior.alwaysSelectAsActiveMesh = true;
    interior.freezeWorldMatrix();
    interior._worldMatrix.copyFrom(world);

    return node;


  }
*/
  getObjectUnderPoint(point: Vector3, maxDist: number): ObjectUnderPoint {
    const objects: ObjectUnderPoint[] = [];

    return null;
  }
}

function createShaders() {
  Effect.ShadersStore["mountingVertexShader"] =
    "\r\n" +
    "precision highp float;\r\n" +
    "attribute vec3 position;\r\n" +
    "attribute vec2 instancePos;\r\n" +
    "uniform mat4 worldViewProjection;\r\n" +
    "void main(void) {\r\n" +
    "    vec3 pos = vec3(position.x,position.y,position.z) + vec3(instancePos.x, instancePos.y, 0);\r\n" +
    "    gl_Position = worldViewProjection * vec4(pos, 1.0);\r\n" +
    "}\r\n";
  Effect.ShadersStore["mountingFragmentShader"] =
    "\r\n" +
    "precision highp float;\r\n" +
    "void main(void) {\r\n" +
    "    gl_FragColor = vec4(1.0,0.3,0.3,0.4);\r\n" +
    "}\r\n";
  Effect.ShadersStore["mountingInteriorVertexShader"] =
    "\r\n" +
    "precision highp float;\r\n" +
    "attribute vec3 position;\r\n" +
    "attribute vec2 instancePos;\r\n" +
    "uniform mat4 worldViewProjection;\r\n" +
    "void main(void) {\r\n" +
    "    vec3 pos = vec3(position.x,position.y,position.z) + vec3(instancePos.x, instancePos.y, 0);\r\n" +
    "    gl_Position = worldViewProjection * vec4(pos, 1.0);\r\n" +
    "}\r\n";
  Effect.ShadersStore["mountingInteriorFragmentShader"] =
    "\r\n" +
    "precision highp float;\r\n" +
    "void main(void) {\r\n" +
    "    gl_FragColor = vec4(0.5,0.5,0.5,1.0);\r\n" +
    "}\r\n";
}

interface MountingGroup {
  node: BendingNode;
  mountings: MountingAttachment[];
}

function groupMountings(
  mountings: MountingAttachment[],
  bendingTree: BendingNode
): MountingGroup[] {
  const groups: MountingGroup[] = [];
  for (let i = 0; i < mountings.length; i++) {
    const mounting = mountings[i];
    const node = findNodeThatContains(mounting.position, bendingTree);
    if (!node) {
      continue;
    }
    const rotation = mounting.rotation;
    const fittingGroup = groups.find(
      (group) =>
        group.node === node &&
        group.mountings[0].shape === mounting.shape &&
        group.mountings[0].rotation === rotation &&
        group.mountings[0].mountingRef.id === mounting.mountingRef.id
    );
    if (!!fittingGroup) {
      fittingGroup.mountings.push(mounting);
    } else {
      groups.push({
        node,
        mountings: [mounting],
      });
    }
  }
  return groups;
}
