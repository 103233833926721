import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  getCustomOffsetX,
  getCustomOffsetY,
  getCustomStampEndX,
  getCustomStampEndY,
  getCustomStampForm,
  getCustomStampFormType,
  getCustomStampLengthString,
  getCustomStampOffsetX,
  getCustomStampOffsetY,
  getCustomStampRotation,
  getCustomStampStartX,
  getCustomStampStartY,
  getCustomStampWidthString,
  getPerforationCustomGeom,
  getRuleActions,
  getSingleToolsFormsForCustomStamp,
  getSingleToolsFormTypes,
  getSingleToolsLengthsDefinedForCustomStamp,
  getSingleToolsLengthsForCustomStamp,
  getSingleToolsWidthsForCustomStamp,
  MevacoState,
  selectedCustomStamp,
} from '../../store/reducers';
import {
  createDefaultStamp,
  getPerforationCustomGeomAabb,
  PerforationCustomGeom,
} from '../../model/product-configuration/perforation-custom-geom';
import {
  EnableCustomStamp,
  SetCustomOffsetX,
  SetCustomOffsetY,
  SetCustomStampEndX,
  SetCustomStampEndY,
  SetCustomStampForm,
  SetCustomStampFormType,
  SetCustomStampLength,
  SetCustomStampOffsetX,
  SetCustomStampOffsetY,
  SetCustomStampRotation,
  SetCustomStampStartX,
  SetCustomStampStartY,
  SetCustomStampWidth,
  SetSelectedCustomStamp,
} from '../../store/actions/custom-geom.actions';
import { Dropdown, DropdownOption } from '../dropdown/dropdown.component';
import { Subscription } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import {
  RedoState,
  ResetShowCustomPattern,
  ShowCustomPatternEditor,
  UndoState,
} from '../../store/actions';
import { PlateMaterialType } from '../../model';
import { View3dProvider } from '../../providers/view3d.provider';
import { PossibleOptionsService } from '../../services/possible-options.service';
import { TranslationProvider } from '../../providers/translation.provider';
import { MessageVisualizerStatus } from '../../model/message-visualizer-status.enum';
import { anyActionOfType, RuleActions } from '../../model/rule-action.model';
import { ShapeSvgPathService } from '../../services/shape-svg-path.service';
import { FormControl } from '@angular/forms';
import {SliderControl} from '../slider/slider.control';
import {Aabb2, getEmptyAaabb} from '../../../webcad/models';

@Component({
  selector: 'custom-pattern-editor',
  templateUrl: './custom-pattern-editor.component.html',
  styleUrls: ['./custom-pattern-editor.component.css'],
})
export class CustomPatternEditorComponent implements OnInit, OnDestroy {
  public MessageVisualizerStatus = MessageVisualizerStatus;
  public messageVisualizerStatus = MessageVisualizerStatus.NONE;
  public messageVisualizerOpened = false;
  public offsetXControl = new SliderControl(
    this.store.pipe( select(getCustomOffsetX) ),
    v => this.store.dispatch(new SetCustomOffsetX({ offsetX: +v }))
  );
  public offsetYControl = new SliderControl(
    this.store.pipe( select(getCustomOffsetY) ),
    v => this.store.dispatch(new SetCustomOffsetY({ offsetY: +v }))
  );
  public selectedStampIndex = this.store.pipe(select(selectedCustomStamp));
  public offsetStampXControl = new SliderControl(
    this.selectedStampIndex.pipe(
      switchMap((index) =>
        this.store.pipe(select(getCustomStampOffsetX, index))
      )
    ),
    v => this.store.dispatch(new SetCustomStampOffsetX({ offsetX: +v }))
  );
  public offsetStampYControl = new SliderControl(
    this.selectedStampIndex.pipe(
      switchMap((index) =>
        this.store.pipe(select(getCustomStampOffsetY, index))
      )
    ),
    v => this.store.dispatch(new SetCustomStampOffsetY({ offsetY: +v }))
  );

  public startXControl = new SliderControl(
    this.selectedStampIndex.pipe(
      switchMap((index) =>
        this.store.pipe(select(getCustomStampStartX, index))
      )
    ),
    v => this.store.dispatch(new SetCustomStampStartX({ startX: +v }))
  );

  public startYControl = new SliderControl(
    this.selectedStampIndex.pipe(
      switchMap((index) =>
        this.store.pipe(select(getCustomStampStartY, index))
      )
    ),
    v => this.store.dispatch(new SetCustomStampStartY({ startY: +v }))
  );
  public endXControl = new SliderControl(
    this.selectedStampIndex.pipe(
      switchMap((index) =>
        this.store.pipe(select(getCustomStampEndX, index))
      )
    ),
    v => this.store.dispatch(new SetCustomStampEndX({ endX: +v }))
  );
  public endYControl = new SliderControl(
    this.selectedStampIndex.pipe(
      switchMap((index) =>
        this.store.pipe(select(getCustomStampEndY, index))
      )
    ),
    v => this.store.dispatch(new SetCustomStampEndY({ endY: +v }))
  );

  private lastAabb: Aabb2;
  private subscriptions = new Subscription();

  public PlateMaterialType = PlateMaterialType;
  public data = this.store
    .pipe(
      select(getPerforationCustomGeom),
      filter((data) => !!data)
    )
    .pipe(
      map((data) => ({
        ...data,
        stumps: data.stumps.map((stamp) => stamp || createDefaultStamp()),
      }))
    );
  public formTypeDropdown = new Dropdown(
    this.translate('custom_pattern_form_type'),
    this.store.pipe(
      select(getSingleToolsFormTypes),
      filter((data) => !!data),
      map((names) =>
        names.map((name) => new DropdownOption('text', this.translate('custom_pattern_' + name), true, name))
      )
    ),
    this.selectedStampIndex.pipe(
      switchMap((index) =>
        this.store.pipe(select(getCustomStampFormType, index))
      )
    ),
    (value) => this.store.dispatch(new SetCustomStampFormType({ type: value }))
  );

  public formDropdown = new Dropdown(
    this.translate('custom_pattern_form'),
    this.selectedStampIndex.pipe(
      switchMap((index) =>
        this.store.pipe(select(getSingleToolsFormsForCustomStamp, index))
      ),
      filter((data) => data && data.length > 0),
      map((singleTools) =>
        singleTools.map(
          (singleTool) =>
            new DropdownOption(
              'withSvgPath',
              {
                name: this.translate(singleTool.form),
                svgPath: this.shapeSvgPathService.getPath(
                  singleTool.form,
                  singleTool.lenght,
                  singleTool.width
                ),
              },
              true,
              singleTool.form
            )
        )
      )
    ),
    this.selectedStampIndex.pipe(
      switchMap((index) => this.store.pipe(select(getCustomStampForm, index)))
    ),
    (value) => this.store.dispatch(new SetCustomStampForm({ form: value })),
    null,
    this.possibleOptionsService.possibleCustomPatternForms
  );

  public widthDropdown = new Dropdown(
    this.translate('custom_pattern_form_width'),
    this.selectedStampIndex.pipe(
      switchMap((index) =>
        this.store.pipe(select(getSingleToolsWidthsForCustomStamp, index))
      ),
      filter((data) => data && data.length > 0),
      map((widths) =>
        widths.map(
          (width) => new DropdownOption('text', width, true, width.toString())
        )
      )
    ),
    this.selectedStampIndex.pipe(
      switchMap((index) =>
        this.store.pipe(select(getCustomStampWidthString, index))
      )
    ),
    (value) => this.store.dispatch(new SetCustomStampWidth({ width: +value })),
    null,
    this.possibleOptionsService.possibleCustomPatternW
  );

  public showLengthDropdown = this.selectedStampIndex.pipe(
    switchMap((index) =>
      this.store.pipe(select(getSingleToolsLengthsDefinedForCustomStamp, index))
    )
  );

  public lengthDropdown = new Dropdown(
    'length',
    this.selectedStampIndex.pipe(
      switchMap((index) =>
        this.store.pipe(select(getSingleToolsLengthsForCustomStamp, index))
      ),
      filter((data) => data && data.length > 0),
      map((lengths) =>
        lengths.map(
          (length) =>
            new DropdownOption('text', length, true, length.toString())
        )
      )
    ),
    this.selectedStampIndex.pipe(
      switchMap((index) =>
        this.store.pipe(select(getCustomStampLengthString, index))
      )
    ),
    (value) =>
      this.store.dispatch(new SetCustomStampLength({ length: +value })),
    null,
    this.possibleOptionsService.possibleCustomPatternL
  );

  rotation = this.selectedStampIndex.pipe(
    switchMap((index) => this.store.pipe(select(getCustomStampRotation, index)))
  );

  editorPopup: '' | 'save' | 'load' = '';

  constructor(
    private store: Store<MevacoState>,
    private view3dProvider: View3dProvider,
    private possibleOptionsService: PossibleOptionsService,
    private translationProvider: TranslationProvider,
    private shapeSvgPathService: ShapeSvgPathService
  ) {}

  ngOnInit() {

    this.subscriptions.add(
      this.store
        .pipe(select(getRuleActions))
        .subscribe((rules: RuleActions) => {
          if (anyActionOfType(rules, MessageVisualizerStatus.ERROR)) {
            this.messageVisualizerStatus = MessageVisualizerStatus.ERROR;
            return;
          } else if (anyActionOfType(rules, MessageVisualizerStatus.WARNING)) {
            this.messageVisualizerStatus = MessageVisualizerStatus.WARNING;
          } else {
            this.messageVisualizerStatus = MessageVisualizerStatus.NONE;
          }
        })
    );

    this.lastAabb = getEmptyAaabb();

    this.subscriptions.add(
      this.data.pipe(filter((data) => !!data)).subscribe((geom) => {
        const aabb = getPerforationCustomGeomAabb(geom);
        if (
          aabb.min.x !== this.lastAabb.min.x ||
          aabb.min.y !== this.lastAabb.min.y ||
          aabb.max.x !== this.lastAabb.max.x ||
          aabb.max.y !== this.lastAabb.max.y
        ) {
          this.view3dProvider.zoomToFitAabb(aabb);
          this.lastAabb = aabb;
        }
      })
    );
  }

  setRotation(rotation: string) {
    this.store.dispatch(new SetCustomStampRotation({ rotation: +rotation }));
  }
  toggleMessVisualizer() {
    this.messageVisualizerOpened = !this.messageVisualizerOpened;
  }

  enableStamp(i: number) {
    this.store.dispatch(new EnableCustomStamp(i));
  }

  close() {
    this.store.dispatch(new ShowCustomPatternEditor(false));
  }

  selectStampIndex(i: number) {
    this.store.dispatch(new SetSelectedCustomStamp(i));
  }

  translate(text: string, module: string = 'designer') {
    return this.translationProvider.translate(text, module);
  }

  undo() {
    this.store.dispatch(new UndoState());
  }

  redo() {
    this.store.dispatch(new RedoState());
  }

  resetCustomPattern() {
    this.store.dispatch(new ResetShowCustomPattern());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    this.offsetXControl.omDestroy();
    this.offsetYControl.omDestroy();
    this.offsetStampXControl.omDestroy();
    this.offsetStampYControl.omDestroy();
    this.startXControl.omDestroy();
    this.startYControl.omDestroy();
    this.endXControl.omDestroy();
    this.endYControl.omDestroy();
  }
}
