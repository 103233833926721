import {Vector3} from 'webcad';
import { PointerState } from 'webcad/collision';
import {ClosestSegments, MevacoPointerProvider} from '../providers/mevaco-pointer.provider';
import {DifferentiationTool} from './differentiation-tool.interface';
import {SetTool, SetToolOffset} from '../store/actions/tools.actions';
import {
  getSelectedExpandedMetalTypeOfCut,
  MevacoState, PRODUCT_CONFIGURATION_SAVED_SUCCESSFULLY,
} from '../store';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {ExpandedMetalModeToolModel} from '../model/tool/expanded-metal-mode-tool.model';
import {SetHintMessage} from '../store/actions/drawing.actions';
import {TranslationProvider} from '../providers/translation.provider';
import {SetPatternOffset} from '../store/actions/current-position.actions';
import {map} from 'rxjs/operators';
import {MevacoPage} from '../model/mevaco-page.model';
import {ToolModel} from "../model";

export function expandedMetalModeToolReducer(mevacoPage: MevacoPage, action: any): MevacoPage {
  if (
    action.type === PRODUCT_CONFIGURATION_SAVED_SUCCESSFULLY &&
    mevacoPage.drawing?.tool?.name === 'ExpandedMetalTool'
  ) {
    return {
      ...mevacoPage,
      drawing: {
        ...mevacoPage.drawing,
        tool: {
          ...mevacoPage.drawing.tool,
          offset: null
        } as ToolModel
      }
    };
  }
  return mevacoPage;
}

export class ExpandedMetalModeTool extends DifferentiationTool {
  private dragStart: PointerState;
  private subscriptions: Subscription[] = [];
  private customCutSelected: boolean;

  constructor(
    private store: Store<MevacoState>,
    private translationProvider: TranslationProvider
  ) {
    super();
    this.store.select(getSelectedExpandedMetalTypeOfCut).pipe( map(toc => toc === 'M') ).subscribe(
      customCutSelected => this.customCutSelected = customCutSelected
    );
  }

  onMouseDownOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseMoveOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseUpOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseClickOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseDownOnNode(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseMoveOnNode(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseUpOnNode(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseClickOnNode(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseDownOnSegment(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseMoveOnSegment(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseUpOnSegment(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseClickOnSegment(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseDownOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseMoveOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseUpOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseClickOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseDownOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseMoveOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseUpOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseClickOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseDownOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseMoveOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseUpOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseClickOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseDownOnMounting(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseMoveOnMounting(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseUpOnMounting(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseClickOnMounting(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseDownOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseMoveOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseUpOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseClickOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {
  }
  onMouseMove(pointerState: PointerState) {
    if (this.dragStart) {
      this.store.dispatch(new SetToolOffset(pointerState.position));
    }
  }
  onMouseClick(pointerState: PointerState) {
    this.store.dispatch(new SetPatternOffset(pointerState.position));
  }
  onMouseDown(pointerState: PointerState) {
    if ( this.customCutSelected ) {
      this.dragStart = pointerState;
    }
  }
  onMouseUp(pointerState: PointerState) {
    if (this.dragStart) {
      this.store.dispatch(new SetPatternOffset(pointerState.position));
    }
    this.dragStart = null;
  }
  onClosestSegmentsChanged(closestSegments: ClosestSegments) {
  }
  reset() {
      throw new Error('Method not implemented.');
  }
  activate() {
    const tool: ExpandedMetalModeToolModel = {
      name: 'ExpandedMetalTool',
      offset: null,
    };
    this.store.dispatch(
      new SetHintMessage(
        this.translationProvider.translate('dragPatternInfo')
      )
    );
    this.store.dispatch( new SetTool( tool ));
  }
  cleanup() {
    this.subscriptions.forEach( s => s.unsubscribe());
    this.subscriptions = [];
    this.store.dispatch( new SetTool( null));
    this.dragStart = null;
  }

  onCancel() {
    this.cleanup();
  }
  onConfirm() {
    this.cleanup();
  }
  isDirty(): boolean {
      return false;
  }
}

