import {Action} from '@ngrx/store';
import {Vector2} from 'webcad/math';
export const CHANGE_PATTERN_OFFSET = 'CHANGE_PATTERN_OFFSET';

export class ChangePatternOffset implements Action {
  readonly type: string = CHANGE_PATTERN_OFFSET;

  constructor(public payload: Vector2) {
  }
}

export const SET_PATTERN_OFFSET = 'SET_PATTERN_OFFSET';
export class SetPatternOffset implements Action {
  readonly type: string = SET_PATTERN_OFFSET;

  constructor(public payload: Vector2) {
  }
}
