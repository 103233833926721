import { select, Store } from "@ngrx/store";
import { MeasurementsManager, Vector3, Webcad } from "webcad";
import { PointerState } from "webcad/collision";
import { addVectors3, multiplyVector3byScalar } from "webcad/math";
import { MeasurementModelManager } from "webcad/measurements";
import { Segment, SegmentType } from "webcad/models";
import { ActionType } from "../../../model/shape-action.model";
import { MeasurementsManagerProvider } from "../../../providers/measurements-manager.provider";
import { ClosestSegments } from "../../../providers/mevaco-pointer.provider";
import { SceneProvider } from "../../../providers/scene.provider";
import { ToolProvider } from "../../../providers/tool.provider";
import { TranslationProvider } from "../../../providers/translation.provider";
import { WebcadProvider } from "../../../providers/webcad.provider";
import { AddShapeToAdd, AddShapeToRemove } from "../../../store/actions";
import {
  RequestRender,
  SetHintMessage,
} from "../../../store/actions/drawing.actions";
import {
  getCurrentActionType,
  getPlate,
  MevacoState,
} from "../../../store/reducers";
import { createLineWithDepthOffset } from "../../../visualizers/line-system";
import { DrawingTool } from "../../drawing-tool.interface";
import { getNewStartPosition, updateXYMeasurements } from "../../utils";
import {Scene, Vector3 as B_Vector3, Color4 as B_Color4, LinesMesh} from "@babylonjs/core";
export enum RectangleToolStep {
  SET_START,
  SET_END,
}

export class RectangleTool extends DrawingTool {
  public mode: ActionType = ActionType.ADD;
  private scene: Scene;
  private webcad: Webcad;
  private xMeasurement: MeasurementModelManager;
  private measurementsManager: MeasurementsManager;
  private startPosition: Vector3;
  private yMeasurement: MeasurementModelManager;
  private heightRectangleMeasurement: MeasurementModelManager;
  private widthRectangleMeasurement: MeasurementModelManager;
  private pointer: Vector3;
  private step: RectangleToolStep;
  private closestSegments: ClosestSegments;
  private currentMesh: LinesMesh;
  private maxHeight: number;
  private maxWidth: number;

  constructor(
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private webcadProvider: WebcadProvider,
    private measurementsManagerProvider: MeasurementsManagerProvider,
    private toolProvider: ToolProvider,
    private translationProvider: TranslationProvider
  ) {
    super();
    this.sceneProvider.getSubscription().subscribe((value) => {
      this.scene = value;
    });
    this.webcadProvider.getObservable().subscribe((value) => {
      this.webcad = value;
    });
    this.measurementsManagerProvider.getSubsciption().subscribe((value) => {
      if (value) {
        this.measurementsManager = value;
        this.disposeMeasurements();
      }
    });
    this.init();
  }

  private init() {
    this.store.pipe(select(getCurrentActionType)).subscribe((value) => {
      this.mode = value;
      this.reset();
    });
    this.store.pipe(select(getPlate)).subscribe((plate) => {
      this.maxHeight = plate.height;
      this.maxWidth = plate.width;
    });
  }

  activate() {
    console.log("ACTIVATE");
    this.step = RectangleToolStep.SET_START;
    this.store.dispatch(new SetHintMessage(this.translate("startRectangle"))); // Click left mouse button to place the first corner of the Rectangle.
    if (this.measurementsManager) {
      if (!this.xMeasurement) {
        this.xMeasurement = this.measurementsManager.getMeasurementModel();
        this.xMeasurement.setInputCallbacks(
          (value: number) => {
            this.onXMeasurementValueChange(value);
            if (!this.startPosition) {
              this.setStartPosition();
            }
            return value;
          },
          (value: number) => {
            this.onXMeasurementValueChange(value);
            this.placeRectangle();
            return value;
          }
        );
      }
      if (!this.yMeasurement) {
        this.yMeasurement = this.measurementsManager.getMeasurementModel();
        this.yMeasurement.setInputCallbacks(
          (value: number) => {
            this.onYMeasurementValueChange(value);
            if (!this.startPosition) {
              this.setStartPosition();
            }
            return value;
          },
          (value: number) => {
            this.onYMeasurementValueChange(value);
            this.placeRectangle();
            return value;
          }
        );
      }
      if (!this.heightRectangleMeasurement) {
        this.heightRectangleMeasurement =
          this.measurementsManager.getMeasurementModel();
        this.heightRectangleMeasurement.setInputCallbacks(
          (value: number) => {
            this.setHeight(value);
            return value;
          },
          (value: number) => {
            this.setHeight(value);
            this.placeRectangle();
            return value;
          }
        );
      }
      if (!this.widthRectangleMeasurement) {
        this.widthRectangleMeasurement =
          this.measurementsManager.getMeasurementModel();
        this.widthRectangleMeasurement.setInputCallbacks(
          (value: number) => {
            this.setWidth(value);
            return value;
          },
          (value: number) => {
            this.setWidth(value);
            this.placeRectangle();
            return value;
          }
        );
      }
    }
  }

  onMouseDown(pointerState: PointerState) {}

  onMouseUp(pointerState: PointerState) {}

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {
    this.closestSegments = closestSegments;
    if (this.pointer) {
      this.updateXYMeasurements(
        this.xMeasurement,
        this.yMeasurement,
        this.pointer
      );
      this.store.dispatch(new RequestRender());
    }
  }

  onMouseMove(pointerState: PointerState) {
    this.pointer = pointerState.position;
    if (this.step === RectangleToolStep.SET_START) {
      this.updateXYMeasurements(
        this.xMeasurement,
        this.yMeasurement,
        this.pointer
      );
    } else {
      this.updateRectangleVisualization();
      this.updateXYMeasurements(
        this.xMeasurement,
        this.yMeasurement,
        this.pointer
      );
      this.updateHeightAndWidthMeasurements();
    }
  }

  private updateHeightAndWidthMeasurements() {
    if (!!this.heightRectangleMeasurement && !!this.widthRectangleMeasurement) {
      const leftDown = this.startPosition;
      const rightUp = this.pointer;
      const leftUp = { x: this.startPosition.x, y: rightUp.y, z: rightUp.z };
      const rightDown = {
        x: rightUp.x,
        y: this.startPosition.y,
        z: this.startPosition.z,
      };
      const yModel = this.yMeasurement.getModel();
      const yDir = yModel.visible
        ? multiplyVector3byScalar(this.yMeasurement.getModel().direction, -1)
        : { x: rightUp.x - this.startPosition.x > 0 ? 1 : -1, y: 0, z: 0 };
      this.heightRectangleMeasurement.updateMeasurement(
        rightDown,
        rightUp,
        yDir,
        this.maxHeight,
        true
      );
      this.widthRectangleMeasurement.updateMeasurement(
        leftUp,
        rightUp,
        { x: 0, y: leftUp.y - this.startPosition.y > 0 ? 1 : -1, z: 0 },
        this.maxWidth,
        true
      );
    }
  }

  private updateXYMeasurements(
    XMeasurement: MeasurementModelManager,
    YMeasurement: MeasurementModelManager,
    point: Vector3
  ): void {
    updateXYMeasurements(
      XMeasurement,
      YMeasurement,
      point,
      this.closestSegments
    );
  }

  updateRectangleVisualization() {
    if (this.currentMesh) {
      this.currentMesh.dispose();
      this.currentMesh = null;
    }
    const leftDown = this.startPosition;
    const rightUp = this.pointer;
    const leftUp = { x: this.startPosition.x, y: rightUp.y, z: rightUp.z };
    const rightDown = {
      x: rightUp.x,
      y: this.startPosition.y,
      z: this.startPosition.z,
    };
    const leftDownV3 = new B_Vector3(leftDown.x, leftDown.y, leftDown.z);
    const rightDownV3 = new B_Vector3(
      rightDown.x,
      rightDown.y,
      rightDown.z
    );
    const rightUpV3 = new B_Vector3(rightUp.x, rightUp.y, rightUp.z);
    const leftUpV3 = new B_Vector3(leftUp.x, leftUp.y, leftUp.z);
    const positions = [
      leftDownV3,
      rightDownV3,
      rightUpV3,
      leftUpV3,
      leftDownV3,
    ];
    const red = new B_Color4(1, 0, 0, 1);
    const colors = [red, red, red, red, red];
    this.currentMesh = createLineWithDepthOffset(
      "WorkingLineRectangle",
      {
        points: positions,
        colors: colors,
      },
      this.scene,
      -0.0006
    );
    this.store.dispatch(new RequestRender());
  }

  onMouseClick(pointerState: PointerState) {
    if (this.step === RectangleToolStep.SET_START) {
      this.setStartPosition();
    } else {
      this.placeRectangle();
    }
  }

  reset() {
    console.log("RESET");
    this.onCancel();
    this.activate();
  }

  placeRectangle(): void {
    console.log("PLACE RECTANGLE");
    const leftDown = this.startPosition;
    const rightUp = this.pointer;
    const leftUp = { x: this.startPosition.x, y: rightUp.y };
    const rightDown = { x: rightUp.x, y: this.startPosition.y };
    const lineShape: Segment[] = [
      {
        type: SegmentType.line,
        begin: leftDown,
        end: rightDown,
      },
      {
        type: SegmentType.line,
        begin: rightDown,
        end: rightUp,
      },
      {
        type: SegmentType.line,
        begin: rightUp,
        end: leftUp,
      },
      {
        type: SegmentType.line,
        begin: leftUp,
        end: leftDown,
      },
    ];
    this.store.dispatch(
      this.mode === ActionType.ADD
        ? new AddShapeToAdd(lineShape)
        : new AddShapeToRemove(lineShape)
    );
    this.reset();
    // this.toolProvider.setTool(null);
  }

  private onXMeasurementValueChange(value: number) {
    if (this.step === RectangleToolStep.SET_START) {
      const newPos = getNewStartPosition(this.xMeasurement, value);
      this.pointer = newPos;
      this.updateClosestHorizontalSegment(newPos);
      this.updateXYMeasurements(this.xMeasurement, this.yMeasurement, newPos);
    } else {
      const newPos = getNewStartPosition(this.xMeasurement, value);
      this.pointer = newPos;
      this.updateClosestHorizontalSegment(newPos);
      this.updateXYMeasurements(this.xMeasurement, this.yMeasurement, newPos);
      this.updateHeightAndWidthMeasurements();
      this.updateRectangleVisualization();
    }
  }

  private onYMeasurementValueChange(value: number) {
    if (this.step === RectangleToolStep.SET_START) {
      const newPos = getNewStartPosition(this.yMeasurement, value);
      this.pointer = newPos;
      this.updateClosestVerticalSegment(newPos);
      this.updateXYMeasurements(this.xMeasurement, this.yMeasurement, newPos);
    } else {
      const newPos = getNewStartPosition(this.yMeasurement, value);
      this.pointer = newPos;
      this.updateClosestVerticalSegment(newPos);
      this.updateXYMeasurements(this.xMeasurement, this.yMeasurement, newPos);
      this.updateHeightAndWidthMeasurements();
      this.updateRectangleVisualization();
    }
  }

  private setStartPosition() {
    this.startPosition = this.pointer;
    this.step = RectangleToolStep.SET_END;
    this.measurementsManager.focusVisualizer(null);
    this._dirty = true;
    this.store.dispatch(
      new SetHintMessage(this.translationProvider.translate("endRectangle"))
    );
  }

  private setWidth(value: number) {
    if (value === 0) {
      return;
    }
    if (this.step === RectangleToolStep.SET_END) {
      const height = Math.abs(this.startPosition.y - this.pointer.y);
      const widthSign = Math.sign(this.startPosition.x - this.pointer.x);
      const heightSign = Math.sign(this.startPosition.y - this.pointer.y);
      this.pointer = addVectors3(
        addVectors3(
          this.startPosition,
          multiplyVector3byScalar(
            {
              x: 0,
              y: -heightSign,
              z: 0,
            },
            height
          )
        ),
        multiplyVector3byScalar({ x: -widthSign, y: 0, z: 0 }, value)
      );
      this.updateClosestHorizontalSegment(this.pointer);
      this.updateClosestVerticalSegment(this.pointer);
      this.updateXYMeasurements(
        this.xMeasurement,
        this.yMeasurement,
        this.pointer
      );
      this.updateHeightAndWidthMeasurements();
      this.updateRectangleVisualization();
    }
  }

  private setHeight(value: number) {
    if (value === 0) {
      return;
    }
    if (this.step === RectangleToolStep.SET_END) {
      const width = Math.abs(this.startPosition.x - this.pointer.x);
      const sign = Math.sign(this.startPosition.x - this.pointer.x);
      const heightSign = Math.sign(this.startPosition.y - this.pointer.y);
      this.pointer = addVectors3(
        addVectors3(
          this.startPosition,
          multiplyVector3byScalar(
            {
              x: -sign,
              y: 0,
              z: 0,
            },
            width
          )
        ),
        multiplyVector3byScalar({ x: 0, y: -heightSign, z: 0 }, value)
      );
      this.updateClosestHorizontalSegment(this.pointer);
      this.updateClosestVerticalSegment(this.pointer);
      this.updateXYMeasurements(
        this.xMeasurement,
        this.yMeasurement,
        this.pointer
      );
      this.updateHeightAndWidthMeasurements();
      this.updateRectangleVisualization();
    }
  }

  private disposeMeasurements() {
    console.log("DISPOSE MEASUREMENTS");
    if (this.xMeasurement) {
      this.xMeasurement.disposeModel();
      this.xMeasurement = null;
    }
    if (this.yMeasurement) {
      this.yMeasurement.disposeModel();
      this.yMeasurement = null;
    }
    if (this.heightRectangleMeasurement) {
      this.heightRectangleMeasurement.disposeModel();
      this.heightRectangleMeasurement = null;
    }
    if (this.widthRectangleMeasurement) {
      this.widthRectangleMeasurement.disposeModel();
      this.widthRectangleMeasurement = null;
    }
  }

  private updateClosestVerticalSegment(point: Vector3) {
    this.closestSegments.verticalSegment =
      this.toolProvider.getNearestVerticalSegmentToPoint(point);
  }

  private updateClosestHorizontalSegment(point: Vector3) {
    this.closestSegments.horizontalSegment =
      this.toolProvider.getNearestHorizontalSegmentToPoint(point);
  }

  onCancel() {
    console.log("ON CANCEL");
    if (this.currentMesh) {
      console.log("DISPOSE CURRENT MESH");
      this.currentMesh.dispose();
      this.currentMesh = null;
    }
    this.disposeMeasurements();
    this.step = RectangleToolStep.SET_START;
    this.startPosition = null;

    this._dirty = false;
  }

  onConfirm() {
    if (this.step === RectangleToolStep.SET_END) {
      this.placeRectangle();
    }
  }

  isDirty(): boolean {
    return this._dirty;
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }
}
