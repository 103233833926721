import { Store, select } from "@ngrx/store";
import { BehaviorSubject, Subscription } from "rxjs";
import { PointerState } from "webcad/collision";
import {
  Vector2,
  Vector3,
  addVectors2,
  distanceVector2,
  getLeftPerpendicularVector2,
  multiplyVector2byScalar,
  normalizeVector2,
  sqrDistanceVector2,
  subVectors2,
} from "webcad/math";
import { MeasurementsManager } from "webcad/measurements";
import {
  IsPointInsideOfShape,
  Segment,
  SegmentType,
  getAngleInArcSegment,
  getShortestPathBetweenTwoPolylines,
  movePolyline,
  projectPointOnSegment,
} from "webcad/models";
import { ActionType } from "../../../model";
import {
  Mounting,
  MountingAttachment,
  createMountingMeshFrom,
  scaleMounting,
} from "../../../model/mounting.model";
import { ConfigurationMaterial } from "../../../model/product-configuration/configuration-material.model";
import { ShapeWithHoles } from "../../../model/shape-with-holes";
import { MeasurementsManagerProvider } from "../../../providers/measurements-manager.provider";
import { ClosestSegments } from "../../../providers/mevaco-pointer.provider";
import { SceneProvider } from "../../../providers/scene.provider";
import { ToolProvider } from "../../../providers/tool.provider";
import { TranslationProvider } from "../../../providers/translation.provider";
import {
  RequestRender,
  SetHintMessage,
} from "../../../store/actions/drawing.actions";
import { AddMountings } from "../../../store/actions/mountings.actions";
import {
  MevacoState,
  getMaterial,
  getMountingHoles,
  getMountingsModel,
  getPerforationArea,
  getShapeWithHoles,
} from "../../../store/reducers";
import { DrawingTool } from "../../drawing-tool.interface";
import {Color3, Mesh, Scene, StandardMaterial, Vector3 as B_Vector3} from "@babylonjs/core";
enum LastSetVariable {
  numberOfHoles,
  spacing,
}

export class AssistedMountingTool extends DrawingTool {
  mode: ActionType;
  public edgeDist: BehaviorSubject<number>;
  public cornerDist: BehaviorSubject<number>;
  public numberOfHoles: BehaviorSubject<number>;
  public spacing: BehaviorSubject<number>;
  public rotation: BehaviorSubject<number>;
  private canPlace: BehaviorSubject<boolean>;
  private cantBuildMat: StandardMaterial;
  private canBuildMat: StandardMaterial;
  private scene: Scene;
  private closestSegments: ClosestSegments;
  private mountingModel: Mounting;
  private currentMesh: Mesh;
  private measurementManager: MeasurementsManager;
  private point: Vector3;
  private mountingModelSub: Subscription;
  private perforationAreaSub: Subscription;
  private mountingHolesSub: Subscription;
  private contoursAreaSub: Subscription;
  private perforationArea: ShapeWithHoles[];
  private shapeWithHoles: ShapeWithHoles;
  private mountingHoles: MountingAttachment[];
  private currentDrawnMeshes: Mesh[] = [];
  public _numberOfHoles: number;
  public _spacing: number;
  private numberOfHolesSub: Subscription;
  private spacingSub: Subscription;
  private positions: Vector2[] = [];
  private lastSet: LastSetVariable = null;
  private mountingVertices: Vector2[];
  private mountingShape: Segment[];
  private materialCode: string;
  private material: ConfigurationMaterial;
  private shape: Segment[];

  constructor(
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private measurementManagerProvider: MeasurementsManagerProvider,
    private toolProvider: ToolProvider,
    private translationProvider: TranslationProvider
  ) {
    super();
    this.edgeDist = new BehaviorSubject(0);
    this.cornerDist = new BehaviorSubject(0);
    this.numberOfHoles = new BehaviorSubject(0);
    this.spacing = new BehaviorSubject(0);
    this.rotation = new BehaviorSubject(0);
    this.canPlace = new BehaviorSubject(false);
    this.sceneProvider.getSubscription().subscribe((scene) => {
      if (this.currentMesh) {
        this.currentMesh.dispose();
        this.currentMesh = null;
      }
      if (this.cantBuildMat) {
        this.cantBuildMat.dispose();
        this.cantBuildMat = null;
      }
      if (this.canBuildMat) {
        this.canBuildMat.dispose();
        this.canBuildMat = null;
      }
      this.scene = scene;
      if (scene) {
        this.cantBuildMat = new StandardMaterial(
          "cantBuildMat",
          this.scene
        );
        this.cantBuildMat.diffuseColor = Color3.Red();
        this.cantBuildMat.specularColor = Color3.Red();
        this.canBuildMat = new StandardMaterial(
          "canBuildMat",
          this.scene
        );
        this.canBuildMat.diffuseColor = Color3.Green();
        this.canBuildMat.specularColor = Color3.Green();
        this.canBuildMat.alpha = 1;
        if (
          this.mountingModel &&
          this.mountingModel.form &&
          this.mountingModel.width &&
          this.scene
        ) {
          this.currentMesh = createMountingMeshFrom(
            this.mountingModel,
            this.mountingShape,
            this.scene,
            this.thickness(),
            0,
            [{ x: 0, y: 0 }]
          );
          this.currentMesh.material = this.canBuildMat;
          this.currentMesh.isVisible = false;
          // this.currentMesh.onBeforeRenderObservable.add((ed, es) => {
          //   this.scene.getEngine().setDepthBuffer(false);
          //   this.scene.getEngine().setStencilBuffer(true);
          //   this.scene.getEngine().setStencilFunction(BABYLON.Engine.ALWAYS);
          //   this.scene.getEngine().setStencilFunctionReference(2);
          //   this.scene.getEngine().setStencilMask(0xFF);
          // });
          // this.currentMesh.onAfterRenderObservable.add((ed, es) => {
          //   this.scene.getEngine().setStencilBuffer(false);
          //   this.scene.getEngine().setDepthBuffer(true);
          // });
        }
      }
    });
    this.measurementManagerProvider.getSubsciption().subscribe((value) => {
      if (value) {
        this.measurementManager = value;
      }
    });
    this.store.pipe(select(getMaterial)).subscribe((v) => {
      this.material = v;
      this.materialCode = v && v.materialcodeLomoe;
    });
  }

  setNumberOfHoles(newNumberOfHoles: string) {
    const num: number = Number(newNumberOfHoles);
    if (!isNaN(num) && newNumberOfHoles !== "") {
      this.removeMeshes();
      this.calculatePossibleMountingsPositions(this.point, num);
      this.visualizeMountings();
    }
  }

  setSpacing(newSpacing: string) {
    const num: number = Number(newSpacing);
    if (!isNaN(num) && newSpacing !== "") {
      this.removeMeshes();
      this.calculatePossibleMountingsPositions(this.point, null, num);
      this.visualizeMountings();
    }
  }

  setEdgeDist(newEdgeDist: string) {
    const num: number = Number(newEdgeDist);
    if (!isNaN(num) && newEdgeDist !== "") {
      this.edgeDist.next(num);
      this.calculatePossibleMountingsPositions(this.point);
      this.visualizeMountings();
    }
  }

  setCornerDist(newCornerDist: string) {
    const num: number = Number(newCornerDist);
    if (!isNaN(num) && newCornerDist !== "") {
      this.cornerDist.next(num);
      this.calculatePossibleMountingsPositions(this.point);
      this.visualizeMountings();
    }
  }

  setRotation(newRotation: string) {
    const num: number = Number(newRotation);
    if (!isNaN(num) && newRotation !== "") {
      const rads = (num * Math.PI) / 180;
      this.rotation.next(rads);
      this.shape = scaleMounting(this.mountingModel, this.mountingShape, rads);
      this.calculatePossibleMountingsPositions(this.point);
      this.visualizeMountings();
    }
  }

  thickness(): number {
    return this.material && this.material.materialThickness
      ? this.material.materialThickness / 1000
      : 0.001;
  }

  activate() {
    this.mountingModelSub = this.store
      .pipe(select(getMountingsModel))
      .subscribe((v) => {
        if (this.currentMesh) {
          this.currentMesh.dispose();
          this.currentMesh = null;
        }
        if (
          v &&
          v.mounting.form !== null &&
          v.mounting.width !== null &&
          this.scene
        ) {
          this.mountingModel = v.mounting;
          this.mountingVertices = v.verticesMap.get(this.mountingModel.form);
          this.mountingShape = v.shapeMap.get(this.mountingModel.form);
          this.shape = scaleMounting(
            this.mountingModel,
            this.mountingShape,
            this.rotation.getValue()
          );
          this.currentMesh = createMountingMeshFrom(
            this.mountingModel,
            this.mountingShape,
            this.scene,
            this.thickness(),
            0,
            [{ x: 0, y: 0 }]
          );
          this.currentMesh.isVisible = false;
        }
      });
    this.perforationAreaSub = this.store
      .pipe(select(getPerforationArea))
      .subscribe((v) => (this.perforationArea = v));
    this.contoursAreaSub = this.store
      .pipe(select(getShapeWithHoles))
      .subscribe((v) => (this.shapeWithHoles = v));
    this.mountingHolesSub = this.store
      .pipe(select(getMountingHoles))
      .subscribe((v) => (this.mountingHoles = Array.from(v.values())));
    this.numberOfHolesSub = this.numberOfHoles.subscribe((v) => {
      this._numberOfHoles = v;
    });
    this.spacingSub = this.spacing.subscribe((v) => {
      this._spacing = v;
    });
    this.store.dispatch(
      new SetHintMessage(this.translate("startAssistedMountingTool"))
    ); // Move mouse near edge
  }

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {
    this.closestSegments = closestSegments;
  }

  onMouseClick(pointerState: PointerState) {
    this.onConfirm();
  }

  onMouseDown(pointerState: PointerState) {}

  onMouseMove(pointerState: PointerState) {
    this.point = pointerState.position;
    this.calculatePossibleMountingsPositions(this.point);
    this.removeMeshes();
    this.store.dispatch(
      new SetHintMessage(this.translate("startAssistedMountingTool"))
    );
    this.visualizeMountings();
    if (this.canPlace.getValue()) {
      this.store.dispatch(
        new SetHintMessage(this.translate("confirmAssistedMountingTool"))
      ); // Click to confirm placement
    }
  }

  onMouseUp(pointerState: PointerState) {}

  reset() {
    this.onCancel();
    this.activate();
  }

  canPlaceMountingHoleInPosition(): boolean {
    if (
      !this.mountingModel ||
      !this.mountingModel.form ||
      !this.mountingModel.width
    ) {
      return false;
    }
    const minDist = this.material.materialThickness / 500;
    const sqrDist = minDist * minDist;

    for (const position of this.positions) {
      const globalShape = movePolyline(this.shape, position);
      if (this.closestSegments) {
        if (this.closestSegments.verticalSegment) {
          const seg = getShortestPathBetweenTwoPolylines(globalShape, [
            this.closestSegments.verticalSegment.segment,
          ]);
          if (!!seg) {
            const sqrDistFromVerticalSegment = sqrDistanceVector2(
              seg.begin,
              seg.end
            );
            if (sqrDistFromVerticalSegment < sqrDist) {
              return false;
            }
          }
        }
        if (this.closestSegments.horizontalSegment) {
          const seg = getShortestPathBetweenTwoPolylines(globalShape, [
            this.closestSegments.horizontalSegment.segment,
          ]);
          if (!!seg) {
            const sqrDistFromVerticalSegment = sqrDistanceVector2(
              seg.begin,
              seg.end
            );
            if (sqrDistFromVerticalSegment < sqrDist) {
              return false;
            }
          }
        }
      }
      if (this.shapeWithHoles) {
        if (this.shapeWithHoles.conture) {
          if (!IsPointInsideOfShape(position, this.shapeWithHoles.conture)) {
            return false;
          }
        }
        for (const c of this.shapeWithHoles.holes) {
          if (IsPointInsideOfShape(position, c)) {
            return false;
          }
        }
      }
      const otherShapes: Segment[][] = this.positions
        .filter((v) => v !== position)
        .map((v) => movePolyline(this.shape, v));
      for (const shape of otherShapes) {
        const seg = getShortestPathBetweenTwoPolylines(globalShape, shape);
        const sqrDistFromVerticalSegment = sqrDistanceVector2(
          seg.begin,
          seg.end
        );
        if (sqrDistFromVerticalSegment < sqrDist) {
          return false;
        }
      }
      if (this.mountingHoles && this.mountingHoles.length > 0) {
        for (const p of this.mountingHoles) {
          const seg = getShortestPathBetweenTwoPolylines(
            globalShape,
            movePolyline(
              scaleMounting(p.mountingRef, p.shape, p.rotation),
              p.position
            )
          );
          const sqrDistFromMounting = sqrDistanceVector2(seg.begin, seg.end);
          if (sqrDistFromMounting < sqrDist) {
            return false;
          }
        }
      }
      if (this.perforationArea) {
        for (let i = 0; i < this.perforationArea.length; i++) {
          const shape = this.perforationArea[i];
          if (shape.conture) {
            for (const s of shape.conture) {
              const pp = projectPointOnSegment(position, s);
              if (pp) {
                const dist = distanceVector2(pp, position);
                if (dist <= minDist) {
                  return false;
                }
              }
            }
          }
        }
      }
    }
    return true;
  }

  private removeMeshes() {
    if (this.currentDrawnMeshes) {
      for (const m of this.currentDrawnMeshes) {
        m.dispose();
      }
    }
    this.currentDrawnMeshes = [];
  }

  private calculatePossibleMountingsPositions(
    position: Vector3,
    newNumberOfHoles?: number,
    newSpacing?: number
  ) {
    if (
      !this.closestSegments ||
      (!this.closestSegments.verticalSegment &&
        !this.closestSegments.horizontalSegment) ||
      !position ||
      (this._numberOfHoles === 0 && this._spacing === 0)
    ) {
      if (newNumberOfHoles) {
        this.numberOfHoles.next(newNumberOfHoles);
        this.spacing.next(0);
        this.lastSet = LastSetVariable.numberOfHoles;
      }
      if (newSpacing) {
        this.spacing.next(newSpacing);
        this.numberOfHoles.next(0);
        this.lastSet = LastSetVariable.spacing;
      }
      return;
    }
    let numberOfHoles = this._numberOfHoles;
    let spacing = this._spacing / 1000;
    const dir = normalizeVector2({
      x: Math.cos(this.rotation.getValue()),
      y: Math.sin(this.rotation.getValue()),
    });
    let realFirstPos: Vector2;
    // closest of closestSegments
    const distToHorizontal = this.closestSegments.horizontalSegment
      ? sqrDistanceVector2(
          position,
          this.closestSegments.horizontalSegment.projectedPoint
        )
      : Number.POSITIVE_INFINITY;
    const distToVertical = this.closestSegments.verticalSegment
      ? sqrDistanceVector2(
          position,
          this.closestSegments.verticalSegment.projectedPoint
        )
      : Number.POSITIVE_INFINITY;
    const closestSegment =
      distToHorizontal < distToVertical
        ? this.closestSegments.horizontalSegment
        : this.closestSegments.verticalSegment;
    //
    let distToDivide = 0;
    if (closestSegment.segment.type === SegmentType.line) {
      const realCornerDist =
        Math.sqrt(
          this.cornerDist.getValue() * this.cornerDist.getValue() -
            (this.edgeDist.getValue() - this.edgeDist.getValue())
        ) / 1000;
      distToDivide =
        distanceVector2(
          closestSegment.segment.begin,
          closestSegment.segment.end
        ) -
        2 * realCornerDist;
    } else if (closestSegment.segment.type === SegmentType.arc) {
      const r =
        closestSegment.segment.beginAngle < closestSegment.segment.endAngle
          ? closestSegment.segment.radius - this.edgeDist.getValue() / 1000
          : closestSegment.segment.radius + this.edgeDist.getValue() / 1000;
      distToDivide =
        getAngleInArcSegment(closestSegment.segment) * r -
        (2 * this.cornerDist.getValue()) / 1000;
    }
    const pos: Vector2[] = [];
    if (newSpacing) {
      numberOfHoles = Math.round(distToDivide / newSpacing) + 1;
      this.numberOfHoles.next(numberOfHoles);
      this.spacing.next(newSpacing);
      this.lastSet = LastSetVariable.spacing;
    } else if (newNumberOfHoles) {
      spacing = distToDivide / (newNumberOfHoles - 1);
      this.numberOfHoles.next(newNumberOfHoles);
      this.spacing.next(Math.round(spacing * 100000) / 100);
      this.lastSet = LastSetVariable.numberOfHoles;
    } else if (this.lastSet === LastSetVariable.numberOfHoles) {
      spacing = distToDivide / (numberOfHoles - 1);
      this.spacing.next(Math.round(spacing * 100000) / 100);
    } else if (this.lastSet === LastSetVariable.spacing) {
      numberOfHoles = Math.round(distToDivide / spacing) + 1;
      this.numberOfHoles.next(numberOfHoles);
    }
    let step: Vector2 = null;
    if (closestSegment.segment.type === SegmentType.line) {
      const segmentDir = normalizeVector2(
        subVectors2(closestSegment.segment.end, closestSegment.segment.begin)
      );
      const realCornerDist =
        Math.sqrt(
          this.cornerDist.getValue() * this.cornerDist.getValue() -
            (this.edgeDist.getValue() - this.edgeDist.getValue())
        ) / 1000;
      step = multiplyVector2byScalar(segmentDir, spacing);
      realFirstPos = addVectors2(
        addVectors2(
          closestSegment.segment.begin,
          multiplyVector2byScalar(segmentDir, realCornerDist)
        ),
        multiplyVector2byScalar(
          getLeftPerpendicularVector2(segmentDir),
          this.edgeDist.getValue() / 1000
        )
      );
      pos.push(realFirstPos);
      for (let i = 1; i < numberOfHoles; i++) {
        pos.push(addVectors2(pos[i - 1], step));
      }
    } else if (closestSegment.segment.type === SegmentType.arc) {
      const r =
        closestSegment.segment.beginAngle < closestSegment.segment.endAngle
          ? closestSegment.segment.radius - this.edgeDist.getValue() / 1000
          : closestSegment.segment.radius + this.edgeDist.getValue() / 1000;
      let arcEdgeDistAsAngle = this.cornerDist.getValue() / 1000 / r;
      let angleStep =
        (getAngleInArcSegment({
          ...closestSegment.segment,
          radius: r,
        }) -
          2 * arcEdgeDistAsAngle) /
        (numberOfHoles - 1);
      if (closestSegment.segment.beginAngle > closestSegment.segment.endAngle) {
        angleStep *= -1;
        arcEdgeDistAsAngle *= -1;
      }
      for (let i = 0; i < numberOfHoles; i++) {
        const alpha =
          closestSegment.segment.beginAngle +
          arcEdgeDistAsAngle +
          i * angleStep;
        pos.push({
          x: closestSegment.segment.origin.x + Math.cos(alpha) * r,
          y: closestSegment.segment.origin.y + Math.sin(alpha) * r,
        });
      }
    }
    this.positions = [...pos];
    this.canPlace.next(this.canPlaceMountingHoleInPosition());
  }

  private visualizeMountings() {
    this.removeMeshes();
    if (this.currentMesh) {
      for (const p of this.positions) {
        const newMesh = new Mesh(
          "x: " + p.x.toString() + " y: " + p.y.toString(),
          this.scene,
          null,
          this.currentMesh
        );
        newMesh.isVisible = true;
        newMesh.position = new B_Vector3(p.x, p.y, 0);
        newMesh.material = this.canPlace.getValue()
          ? this.canBuildMat
          : this.cantBuildMat;
        newMesh.renderingGroupId = 3;
        newMesh.rotation.z = this.rotation.getValue();
        // newMesh.onBeforeRenderObservable.add((ed, es) => {
        //   this.scene.getEngine().setDepthBuffer(false);
        //   this.scene.getEngine().setStencilBuffer(true);
        //   this.scene.getEngine().setStencilFunction(BABYLON.Engine.ALWAYS);
        //   this.scene.getEngine().setStencilFunctionReference(2);
        //   this.scene.getEngine().setStencilMask(0xFF);
        // });
        // newMesh.onAfterRenderObservable.add((ed, es) => {
        //   this.scene.getEngine().setStencilBuffer(false);
        //   this.scene.getEngine().setDepthBuffer(true);
        // });
        this.currentDrawnMeshes.push(newMesh);
      }
      this.store.dispatch(new RequestRender());
    }
  }

  onCancel() {
    this.removeMeshes();
    if (this.contoursAreaSub) {
      this.contoursAreaSub.unsubscribe();
      this.contoursAreaSub = null;
    }
    if (this.mountingHolesSub) {
      this.mountingHolesSub.unsubscribe();
      this.mountingHolesSub = null;
    }
    if (this.mountingModelSub) {
      this.mountingModelSub.unsubscribe();
      this.mountingModelSub = null;
    }
    if (this.numberOfHolesSub) {
      this.numberOfHolesSub.unsubscribe();
      this.numberOfHolesSub = null;
    }
    if (this.perforationAreaSub) {
      this.perforationAreaSub.unsubscribe();
      this.perforationAreaSub = null;
    }
    if (this.spacingSub) {
      this.spacingSub.unsubscribe();
      this.spacingSub = null;
    }
    this._dirty = false;
  }

  onConfirm() {
    if (this.canPlace.getValue()) {
      this.store.dispatch(
        new AddMountings(
          this.positions.map((v) => {
            return {
              position: v,
              vertices: this.mountingVertices,
              shape: this.mountingShape,
              mountingRef: this.mountingModel,
              rotation: this.rotation.getValue(),
              possible: this.mountingModel.materialCode === this.materialCode,
            };
          })
        )
      );
    }
  }

  isDirty(): boolean {
    return this._dirty;
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }
}
