import { ErrorHandler } from "@angular/core";
import { Store, select } from "@ngrx/store";
import {
  MeasurementModelManager,
  MeasurementsManager,
  Vector3,
  Webcad,
  getLeftPerpendicularVector2,
  lengthVector2,
  subVectors2,
} from "webcad";
import { PointerState } from "webcad/collision";
import { Segment, SegmentType } from "webcad/models";
import { ActionType, Plate, PointNode } from "../../model";
import { MeasurementsManagerProvider } from "../../providers/measurements-manager.provider";
import { ClosestSegments } from "../../providers/mevaco-pointer.provider";
import { SceneProvider } from "../../providers/scene.provider";
import { ToolProvider } from "../../providers/tool.provider";
import { TranslationProvider } from "../../providers/translation.provider";
import { WebcadProvider } from "../../providers/webcad.provider";
import {
  AddShapeToAdd,
  AddShapeToRemove,
  MevacoState,
  getCurrentActionType,
  getNodesState,
  getPlate,
} from "../../store";
import {
  RequestRender,
  SetHintMessage,
} from "../../store/actions/drawing.actions";
import { Curve } from "../../utils/Curve";
import { createLineWithDepthOffset } from "../../visualizers/line-system";
import { DrawingTool } from "../drawing-tool.interface";
import { getNewStartPosition, updateXYMeasurements } from "../utils";
import {Color4, Engine, LinesMesh, Node, Scene, Vector3 as B_Vector3} from "@babylonjs/core";

export class CircleTool extends DrawingTool {
  constructor(
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private webcadProvider: WebcadProvider,
    private toolProvider: ToolProvider,
    private translationProvider: TranslationProvider,
    private measurementManagerProvider: MeasurementsManagerProvider,
    private appErrorHandler: ErrorHandler
  ) {
    super();
    this.sceneProvider.getSubscription().subscribe((value) => {
      this.scene = value;
      if (this.scene) {
        this.rootNode = new Node("measurmentNode", this.scene);
        this.engine = this.rootNode.getEngine();
      }
    });
    this.webcadProvider.getObservable().subscribe((value) => {
      this.webcad = value;
    });
    this.measurementManagerProvider.getSubsciption().subscribe((value) => {
      if (value) {
        this.measurementManager = value;
      }
    });
    this.init();
  }

  public mode: ActionType = ActionType.REMOVE;
  private originPosition: Vector3;
  private radius: number;
  private currentMesh: LinesMesh;
  private nodes: PointNode[];
  private nodesArray: PointNode[];
  private radiusMeasurement: MeasurementModelManager;
  private verticalMeasurement: MeasurementModelManager;
  private horizontalMeasurement: MeasurementModelManager;
  private rootNode: Node;
  private point: Vector3;
  private engine: Engine;
  private maxWidth: number;
  private maxHeight: number;
  private scene: Scene;
  private webcad: Webcad;
  private measurementManager: MeasurementsManager;
  private closestSegments: ClosestSegments;
  private lastPointerState: PointerState;

  setRadius(value: number): void {
    const mesh = this.currentMesh;
    if (this.currentMesh) {
      if (this.originPosition) {
        this.currentMesh.dispose();
        this.updateCircleVisualization(value);
        this.radiusMeasurement.setLength(value);
        this.radiusMeasurement.setVisible(true);
        this.store.dispatch(new RequestRender());
      }
    }
  }

  setHorizontal(value: number): void {
    const newPoint = getNewStartPosition(this.horizontalMeasurement, value);
    this.point = newPoint;
    this.updateClosestHorizontalSegment(this.point);
    this.updateXYMeasurements(
      this.horizontalMeasurement,
      this.verticalMeasurement,
      newPoint
    );
    this.store.dispatch(new RequestRender());
  }

  setVertical(value: number): void {
    const newPoint = getNewStartPosition(this.verticalMeasurement, value);
    this.point = newPoint;
    this.updateClosestVerticalSegment(this.point);
    this.updateXYMeasurements(
      this.horizontalMeasurement,
      this.verticalMeasurement,
      newPoint
    );
    this.store.dispatch(new RequestRender());
  }

  activate() {
    this.store.dispatch(new SetHintMessage(this.translate("startCircle"))); //Click left mouse button to place the center of the Circle
    if (!this.radiusMeasurement) {
      this.radiusMeasurement = this.measurementManager.getMeasurementModel();
      this.radiusMeasurement.setInputCallbacks(
        (value: number) => {
          this.setRadius(value);
          return value;
        },
        (value: number) => {
          this.setRadius(value);
          this.placeCircle();
          return value;
        }
      );
    }
    if (!this.horizontalMeasurement) {
      this.horizontalMeasurement =
        this.measurementManager.getMeasurementModel();
      this.horizontalMeasurement.setInputCallbacks(
        (value: number) => {
          this.setHorizontal(value);
          return value;
        },
        (value: number) => {
          this.setHorizontal(value);
          this.placeOrigin();
          return value;
        }
      );
    }
    if (!this.verticalMeasurement) {
      this.verticalMeasurement = this.measurementManager.getMeasurementModel();
      this.verticalMeasurement.setInputCallbacks(
        (value: number) => {
          this.setVertical(value);
          return value;
        },
        (value: number) => {
          this.setVertical(value);
          this.placeOrigin();
          return value;
        }
      );
    }
  }

  init() {
    this.store.pipe(select(getNodesState)).subscribe((nodes) => {
      if (nodes) {
        if (typeof nodes.values !== "function") {
          this.appErrorHandler.handleError(
            new Error("Unexpected value of nodes: " + JSON.stringify(nodes))
          );
          this.nodes = [];
          this.nodesArray = [];
        } else {
          this.nodes = nodes;
          this.nodesArray = Array.from(this.nodes.values());
        }
      }
    });
    this.store
      .pipe(select(getCurrentActionType))
      .subscribe((value) => (this.mode = value));
    this.store.pipe(select(getPlate)).subscribe((plate: Plate) => {
      this.maxHeight = plate.height;
      this.maxWidth = plate.width;
    });
  }

  onMouseDown(pointerState: PointerState) {}

  onMouseUp(pointerState: PointerState) {}

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {
    this.closestSegments = closestSegments;
    if (this.point && !this.originPosition) {
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    }
  }

  onMouseMove(pointerState: PointerState) {
    if (!this.radiusMeasurement) {
      this.radiusMeasurement = this.measurementManager.getMeasurementModel();
    }
    if (this.closestSegments) {
      if (!this.horizontalMeasurement) {
        this.horizontalMeasurement =
          this.measurementManager.getMeasurementModel();
      }
      if (!this.verticalMeasurement) {
        this.verticalMeasurement =
          this.measurementManager.getMeasurementModel();
      }
    }
    this.point = pointerState.position;
    this.lastPointerState = pointerState;
    if (this.currentMesh) {
      this.currentMesh.dispose();
    }
    if (!this.originPosition && this.closestSegments) {
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        pointerState.position
      );
    }
    if (this.originPosition) {
      const radius = Math.abs(
        lengthVector2(subVectors2(this.originPosition, pointerState.position))
      );
      this.updateCircleVisualization(radius);
      const startToEnd = subVectors2(
        this.originPosition,
        pointerState.position
      );
      const dirV2 = getLeftPerpendicularVector2(startToEnd);
      const dir = new B_Vector3(
        dirV2.x,
        dirV2.y,
        this.originPosition.z
      ).normalize();
      const end = {
        x: pointerState.position.x,
        y: pointerState.position.y,
        z: this.originPosition.z,
      };
      this.updateMeasurmentVisualization(end, dir);
    }
    // this.radiusMeasurement.focus();
  }

  updateCircleVisualization(radius: number) {
    this.radius = radius;
    const curve = new Curve(
      this.originPosition,
      this.radius,
      0,
      Math.PI * 2,
      false
    );
    const pathPoints = curve.getPoints();
    const points: B_Vector3[] = [];
    for (const p of pathPoints) {
      points.push(new B_Vector3(p.x, p.y, this.originPosition.z * 2));
    }
    points.push(points[0]);
    const red = new Color4(1, 0, 0, 1);
    const colors = [];

    for (let i = 0; i < points.length; i++) {
      colors.push(red);
    }
    this.currentMesh = createLineWithDepthOffset(
      "currentLine",
      { points: points, colors: colors },
      this.scene,
      -0.0006
    );
  }

  updateMeasurmentVisualization(end: Vector3, direction: Vector3) {
    this.radiusMeasurement.updateMeasurement(
      this.originPosition,
      end,
      direction,
      undefined,
      true
    );
  }

  onMouseClick(pointerState: PointerState) {
    if (!this.originPosition) {
      this.placeOrigin();
      this._dirty = true;
    } else {
      this.placeCircle();
    }
  }

  placeOrigin() {
    this.store.dispatch(new SetHintMessage(this.translate("endCircle"))); // Press TAB to type in the length of the radius. Press ESC to cancel.
    this.originPosition = this.point;
    this._dirty = true;
    this.horizontalMeasurement.setVisible(false);
    this.verticalMeasurement.setVisible(false);
    this.measurementManager.focusVisualizer(null);
    this.onMouseMove(this.lastPointerState);
  }

  placeCircle() {
    const x = Math.cos(0) * this.radius + this.originPosition.x;
    const y = Math.sin(0) * this.radius + this.originPosition.y;
    const pt: Vector3 = { x: x, y: y, z: this.point.z };
    const lineShape: Segment[] = [
      {
        begin: pt,
        end: pt,
        origin: this.originPosition,
        beginAngle: 0,
        endAngle: 2 * Math.PI,
        radius: this.radius,
        type: SegmentType.arc,
      },
    ];
    this.store.dispatch(
      this.mode === ActionType.ADD
        ? new AddShapeToAdd(lineShape)
        : new AddShapeToRemove(lineShape)
    );
    this.reset();
    this.activate();
  }

  reset() {
    this.onCancel();
    this.activate();
  }

  updateXYMeasurements(
    XMeasurement: MeasurementModelManager,
    YMeasurement: MeasurementModelManager,
    point: Vector3
  ) {
    updateXYMeasurements(
      XMeasurement,
      YMeasurement,
      point,
      this.closestSegments
    );
  }

  updateClosestSegments(point: Vector3) {
    this.updateClosestHorizontalSegment(point);
    this.updateClosestVerticalSegment(point);
  }

  updateClosestVerticalSegment(point: Vector3) {
    this.closestSegments.verticalSegment =
      this.toolProvider.getNearestVerticalSegmentToPoint(point);
  }

  updateClosestHorizontalSegment(point: Vector3) {
    this.closestSegments.horizontalSegment =
      this.toolProvider.getNearestHorizontalSegmentToPoint(point);
  }

  onCancel() {
    if (this.currentMesh) {
      this.currentMesh.dispose();
      console.log("DISPOSE CURRENT MESH");
    }
    if (this.radiusMeasurement) {
      this.radiusMeasurement.disposeModel();
      console.log("DISPOSE RADIUS MEASUREMENT");
      this.radiusMeasurement = null;
    }
    if (this.horizontalMeasurement) {
      this.horizontalMeasurement.disposeModel();
      console.log("DISPOSE HORIZONTAL MEASUREMENT");
      this.horizontalMeasurement = null;
    }
    if (this.verticalMeasurement) {
      this.verticalMeasurement.disposeModel();
      console.log("DISPOSE VERTICAL MEASUREMENT");
      this.verticalMeasurement = null;
    }
    this.originPosition = null;
    this.radius = null;
    this.currentMesh = null;
    this._dirty = false;
  }

  onConfirm() {
    if (this.originPosition) {
      this.placeCircle();
    }
  }

  isDirty() {
    return this._dirty;
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }
}
