import * as earcut from "earcut";
import { Webcad } from "webcad/core";
import { InstancedMesh } from "webcad/core/instanced-mesh";
import {
  addVectors2,
  createCircle,
  dotProductVector3,
  multiplyVector2byScalar,
  normalizeVector2,
  rotateVector2,
  subVectors2,
  Vector2,
  Vector3,
} from "webcad/math";
import { transformDirection, transformPos } from "webcad/math/matrix4";
import { Aabb2 } from "webcad/models";
import { CameraModel } from "webcad/models/camera.model";
import { ModelVisualizer } from "webcad/visualizers";
import {
  getPerforationPositions,
  getStampList,
} from "../model/perforation.model";
import { getRotatedStampAabb, StampModel } from "../model/stamp.model";
import {
  BendingNode,
  findNodeThatContains,
} from "../model/view-model/bending-node.viewModel";
import { PerforationVisualizerViewModel } from "../model/view-model/perforation-visualizer.viewModel";
import {
  Effect,
  Engine,
  Mesh,
  Node,
  Scene,
  Texture,
  VertexData,
  Buffer,
  ShaderMaterial,
  VertexBuffer, Vector4, Matrix, Color3,
  Vector3 as B_Vector3, BoundingInfo
} from "@babylonjs/core";

/**
 * Stencil Buffer: (ref & mask) OP (stencil & mask)
 * IF you want to check for hole check for value "2" in Stencil
 * i.e (2 && FF) NOT_EQUAL (2 && FF)
 *  so if there is NO 2 In Stencil you can draw hole
 */
export class PerforationVisualizer
  implements ModelVisualizer<PerforationVisualizerViewModel>
{
  constructor() {}
  protected model: PerforationVisualizerViewModel;
  protected parentNode: Node;
  private instancedHole: InstancedMesh[];
  private borderInstancedHole: InstancedMesh[];
  private engine: Engine;
  private punchTexture: Texture;
  circlePunchTexture: Texture;
  private webcad: Webcad;

  private static createHoleMesh(polygons, scene): Mesh {
    console.log("CREATE HOLE MESH");
    /*
    const holeMeshes: BABYLON.Mesh[] = [];
    const orginalEpsilon = (BABYLON as any).Epsilon;
    for (const s of polygons) {
      const shape: BABYLON.Vector2[] = [];
      for (const p of s) {
        shape.push(new BABYLON.Vector2(p.x, p.y));
      }
      (BABYLON as any).Epsilon = 0.00001;

      const polygonBuilder = new BABYLON.PolygonMeshBuilder('basic_perforation_shape', shape, scene);
      holeMeshes.push(polygonBuilder.build());
    }
    (BABYLON as any).Epsilon = orginalEpsilon;
    return  holeMeshes.length > 0 ? BABYLON.Mesh.MergeMeshes(holeMeshes, true, true) : null;
*/
    const mesh = new Mesh("HoleMesh", scene);
    const positions: number[] = [];
    const indices: number[] = [];
    let startIndex = 0;
    for (const s of polygons) {
      const vertices: number[] = [];
      for (const p of s) {
        vertices.push(p.x, p.y);
        positions.push(p.x, 0, p.y);
      }
      const tris = earcut(vertices);
      for (let i = 0; i < tris.length; i++) {
        indices.push(tris[i] + startIndex);
      }
      startIndex += vertices.length / 2;
    }
    const vertexData = new VertexData();
    vertexData.positions = positions;
    vertexData.indices = indices;
    vertexData.applyToMesh(mesh);
    return mesh;
  }

  private static createHoleInteriorMesh(
    polygons: Vector2[][],
    scene: Scene,
    depth: number
  ): Mesh {
    console.log("CREATE HOLE INTERIOR MESH");
    const mesh: Mesh = new Mesh("interior", scene);
    const positions: number[] = [];
    const indices: number[] = [];
    let ind = 0;
    for (const s of polygons) {
      for (let i = 0; i < s.length; i++) {
        const vector1 = s[i];
        const vector2 = s[(i + 1) % s.length];
        positions.push(vector2.x, vector2.y, depth / 2);
        positions.push(vector1.x, vector1.y, depth / 2);
        positions.push(vector2.x, vector2.y, -depth / 2);
        positions.push(vector1.x, vector1.y, -depth / 2);

        indices.push(ind, ind + 1, ind + 3);
        indices.push(ind + 3, ind + 2, ind);
        ind += 4;
      }
    }
    const vertexData = new VertexData();
    vertexData.positions = positions;
    vertexData.indices = indices;
    vertexData.applyToMesh(mesh);
    return mesh;
  }

  updateVisualization(newModel: PerforationVisualizerViewModel): void {
    if (this.model !== newModel) {
      this.disposePerforation();
      if (newModel.showPerforation) {
        this.createPerforation(newModel, this.parentNode.getScene());
      }
    }
    this.model = newModel;
  }

  disposePerforation() {
    if (this.instancedHole) {
      for (let i = 0; i < this.instancedHole.length; i++) {
        const instancedHoleElement = this.instancedHole[i];
        instancedHoleElement.isVisible = false;
        instancedHoleElement.setEnabled(false);
        instancedHoleElement.dispose();
        this.instancedHole[i] = null;
      }
      this.instancedHole = null;
    }

    if (this.borderInstancedHole) {
      for (let i = 0; i < this.borderInstancedHole.length; i++) {
        const instancedHoleElement = this.borderInstancedHole[i];
        instancedHoleElement.dispose();
      }
      this.borderInstancedHole = null;
    }
  }

  init(
    rootNode: Node,
    model: PerforationVisualizerViewModel,
    webcad: Webcad
  ): Promise<void> {
    this.model = model;
    this.parentNode = rootNode;
    this.engine = rootNode.getEngine();
    this.webcad = webcad;
    createPerforationShaders();
    this.punchTexture = new Texture(
      "assets/punch.png",
      this.parentNode.getScene()
    );
    this.circlePunchTexture = new Texture(
      "assets/circlepunch.png",
      this.parentNode.getScene(),
      false,
      true,
      Texture.LINEAR_LINEAR_MIPLINEAR
    );
    if (model.showPerforation) {
      this.createPerforation(model, rootNode.getScene());
    }
    return Promise.resolve();
  }

  private createPunchMesh(
    stamp: StampModel,
    count: number,
    scene: Scene,
    holesPositions: Float32Array,
    depth: number,
    rotation: number,
    aabb: Aabb2
  ) {
    console.log("CREATE PUNCH MESH");
    if (stamp.shape.toLowerCase() === "quadrat") {
      return this.createPunchRectMesh(
        stamp,
        count,
        scene,
        holesPositions,
        depth,
        rotation,
        aabb
      );
    } else {
      return this.createPunchRoundMesh(
        stamp,
        count,
        scene,
        holesPositions,
        depth,
        rotation,
        aabb
      );
    }
  }

  private createPunchRoundMesh(
    stamp: StampModel,
    count: number,
    scene: Scene,
    holesPositions: Float32Array,
    depth: number,
    rotation: number,
    aabb: Aabb2
  ) {
    console.log("CREATE PUNCH ROUND MESH");
    const stampAabb = getRotatedStampAabb(stamp, -1, rotation);
    const r = (stampAabb.max.x - stampAabb.min.x) / 2;
    const b = 0.003;
    const circle = createCircle(r);
    const vertices = [];
    const uvs = [];
    for (const v of circle) {
      vertices.push(...[v.x, 0, v.y]);
      uvs.push(0, 0);
      const inner = addVectors2(
        v,
        multiplyVector2byScalar(
          normalizeVector2(subVectors2({ x: 0, y: 0 }, v)),
          b
        )
      );
      vertices.push(...[inner.x, 0, inner.y]);
      uvs.push(1, 1);
    }
    const indices = [];
    const maxIndex = vertices.length / 3;
    for (let i = 0; i < maxIndex; i++) {
      const n = (i + 1) % maxIndex;
      const nn = (i + 2) % maxIndex;
      const nnn = (i + 3) % maxIndex;
      indices.push(...[n, i, nn]);
      indices.push(...[nn, nnn, n]);
    }
    // for (let i = 1; i < vertices.length - 1; i += 2) {
    //   const n = i + 2 % vertices.length - 1;
    //   indices.push(...[vertices.length, i, n]);
    // }
    const vertexData = new VertexData();
    vertexData.positions = vertices;
    vertexData.indices = indices;
    vertexData.uvs = uvs;
    const instancedHoleElement = new InstancedMesh(
      "instanced hole",
      count,
      scene
    );
    vertexData.applyToMesh(instancedHoleElement);
    const instancesBuffer = new Buffer(
      scene.getEngine(),
      holesPositions,
      false,
      2,
      false,
      true
    );
    (instancedHoleElement as any)._instancesBuffer = instancesBuffer;
    const positionsVertexBuffer = instancesBuffer.createVertexBuffer(
      "instancePos",
      0,
      2
    );
    instancedHoleElement.setVerticesBuffer(positionsVertexBuffer);
    instancedHoleElement.material = new ShaderMaterial(
      "shader",
      scene,
      {
        vertex: "punch",
        fragment: "punch",
      },
      {
        attributes: ["position", "instancePos", "uv"],
        uniforms: [
          "worldViewProjection",
          "worldView",
          "zPos",
          "u_texture",
          "projection",
        ],
      }
    );
    (<ShaderMaterial>instancedHoleElement.material).setTexture(
      "u_texture",
      this.circlePunchTexture
    );
    instancedHoleElement.setBoundingInfo( new BoundingInfo(
      new B_Vector3(aabb.min.x, aabb.min.y, -100),
      new B_Vector3(aabb.max.x, aabb.max.y, 100)
    ));
    // instancedHoleElement.material.alphaMode = BABYLON.Engine.ALPHA_SUBTRACT;
    instancedHoleElement.visibility = 0.5;
    // (<StandardMaterial>instancedHoleElement.material);
    instancedHoleElement.onBeforeRenderObservable.add((mesh) => {
      setZpos(this.webcad.viewState.camera, depth, mesh);
      // mesh.material.backFaceCulling = false;
      // this.engine.setColorWrite(true);
    });

    instancedHoleElement.onAfterRenderObservable.add(() => {
      // this.engine.setColorWrite(true);
    });

    instancedHoleElement.rotation.z = rotation;

    return instancedHoleElement;
  }

  private createPunchRectMesh(
    stamp: StampModel,
    count: number,
    scene: Scene,
    holesPositions: Float32Array,
    depth: number,
    rotation: number,
    aabb: Aabb2
  ) {
    console.log("CREATE PUNCH RECT MESH");
    const stampAabb = getRotatedStampAabb(stamp, -1, rotation);
    const u = (stampAabb.max.y - stampAabb.min.y) / 2;
    const r = (stampAabb.max.x - stampAabb.min.x) / 2;
    const b = 0.003;
    const vertices = [
      -r,
      0,
      -u,
      -r + b,
      0,
      -u,
      r - b,
      0,
      -u,
      r,
      0,
      -u,
      r,
      0,
      b + -u,
      r,
      0,
      u - b,
      r,
      0,
      u,
      r - b,
      0,
      u,
      -r + b,
      0,
      u,
      -r,
      0,
      u,
      -r,
      0,
      b - u,
      -r,
      0,
      -u + b,

      -r + b,
      0,
      -u + b,
      r - b,
      0,
      -u + b,
      r - b,
      0,
      u - b,
      -r + b,
      0,
      u - b,
    ];
    const indices = [
      11, 0, 12, 0, 1, 12,

      1, 2, 12, 12, 2, 13,

      2, 3, 13, 3, 4, 13,

      13, 4, 5, 13, 5, 14,

      5, 6, 14, 14, 6, 7,

      7, 15, 14, 7, 8, 15,

      8, 9, 15, 9, 10, 15,

      10, 12, 15, 10, 11, 12,

      12, 13, 14, 12, 14, 15,
    ];

    const uvs = [
      0, 0, 0.1, 0,

      0.9, 0, 1, 0, 1, 0.1,

      1, 0.9, 1, 1, 0.9, 1,

      0.1, 1, 0, 1, 0, 0.9,

      0, 0.1,

      0.1, 0.1, 0.9, 0.1, 0.9, 0.9, 0.1, 0.9,
    ];
    const vertexData = new VertexData();
    vertexData.positions = vertices;
    vertexData.indices = indices;
    vertexData.uvs = uvs;
    const instancedHoleElement = new InstancedMesh(
      "instanced hole",
      count,
      scene
    );
    vertexData.applyToMesh(instancedHoleElement);
    const instancesBuffer = new Buffer(
      scene.getEngine(),
      holesPositions,
      false,
      2,
      false,
      true
    );
    (instancedHoleElement as any)._instancesBuffer = instancesBuffer;
    const positionsVertexBuffer = instancesBuffer.createVertexBuffer(
      "instancePos",
      0,
      2
    );
    instancedHoleElement.setVerticesBuffer(positionsVertexBuffer);
    instancedHoleElement.material = new ShaderMaterial(
      "shader",
      scene,
      {
        vertex: "punch",
        fragment: "punch",
      },
      {
        attributes: ["position", "instancePos", "uv"],
        uniforms: [
          "worldViewProjection",
          "worldView",
          "zPos",
          "u_texture",
          "projection",
        ],
      }
    );
    (<ShaderMaterial>instancedHoleElement.material).setTexture(
      "u_texture",
      this.punchTexture
    );
    instancedHoleElement.setBoundingInfo( new BoundingInfo(
      new B_Vector3(aabb.min.x, aabb.min.y, -100),
      new B_Vector3(aabb.max.x, aabb.max.y, 100)
    ));
    // instancedHoleElement.material.alphaMode = Engine.ALPHA_SUBTRACT;
    instancedHoleElement.visibility = 0.5;
    instancedHoleElement.onBeforeRenderObservable.add((mesh) => {
      setZpos(this.webcad.viewState.camera, depth, mesh);
      mesh.material.backFaceCulling = false;
      this.engine.setColorWrite(true);
    });

    instancedHoleElement.onAfterRenderObservable.add(() => {
      this.engine.setColorWrite(true);
    });

    instancedHoleElement.rotation.z = rotation;

    return instancedHoleElement;
  }

  private createInstancedHolesMesh(
    totalCount: number,
    hole: Mesh,
    scene: Scene,
    holesPositions: Float32Array,
    depth: number,
    writeColor: boolean,
    rotation: number,
    aabb: Aabb2,
    selectedStamp: boolean
  ): InstancedMesh {
    console.log("CREATE INSTANCED HOLE MESH");
    const instancedHoleElement = new InstancedMesh(
      "instanced hole",
      totalCount,
      scene
    );
    instancedHoleElement.setVerticesData(
      VertexBuffer.PositionKind,
      hole.getVerticesData(VertexBuffer.PositionKind),
      false
    );
    instancedHoleElement.setIndices(hole.getIndices());
    const instancesBuffer = new Buffer(
      scene.getEngine(),
      holesPositions,
      false,
      2,
      false,
      true
    );
    (instancedHoleElement as any)._instancesBuffer = instancesBuffer;
    const positionsVertexBuffer = instancesBuffer.createVertexBuffer(
      "instancePos",
      0,
      2
    );
    instancedHoleElement.setVerticesBuffer(positionsVertexBuffer);
    instancedHoleElement.material = new ShaderMaterial(
      "shader",
      scene,
      {
        vertex: "hole",
        fragment: "hole",
      },
      {
        attributes: ["position", "instancePos"],
        uniforms: ["worldViewProjection", "worldView", "color", "zPos"],
      }
    );

    // instancedHoleElement.material.zOffset = -1;
    if (selectedStamp) {
      (instancedHoleElement.material as ShaderMaterial).setVector4(
        "color",
        new Vector4(0, 159 / 256, 227 / 256, 1)
      );
    } else {
      (instancedHoleElement.material as ShaderMaterial).setVector4(
        "color",
        writeColor
          ? new Vector4(0.3, 0.1, 0.1, 0.5)
          : new Vector4(0, 0, 0, 1)
      );
    }
    instancedHoleElement.material.backFaceCulling = true;
    instancedHoleElement.visibility = writeColor ? 0.9999 : 1; // enable alpha blend
    instancedHoleElement.setBoundingInfo( new BoundingInfo(
      new B_Vector3(aabb.min.x, aabb.min.y, -100),
      new B_Vector3(aabb.max.x, aabb.max.y, 100)
    ));

    instancedHoleElement.onBeforeRenderObservable.add((mesh: Mesh) => {
      mesh.material.backFaceCulling = false;
      mesh.getEngine().setColorWrite(writeColor);
      const gl = mesh.getEngine()._gl;
      gl.enable(gl.POLYGON_OFFSET_FILL);
      gl.polygonOffset(-2, -2);
      setZpos(this.webcad.viewState.camera, depth, mesh);
    });

    instancedHoleElement.onAfterRenderObservable.add((mesh: Mesh) => {
      mesh.getEngine().setColorWrite(true);
      const gl = mesh.getEngine()._gl;
      gl.disable(gl.POLYGON_OFFSET_FILL);
    });

    instancedHoleElement.rotation.z = rotation;

    return instancedHoleElement;
  }

  private createInstancedHolesInteriorMesh(
    totalCount: number,
    hole: Mesh,
    scene: Scene,
    holesPositions: Float32Array,
    rotation: number,
    aabb: Aabb2
  ): InstancedMesh {
    console.log("CREATE INSTANCED HOLES INTERIOR MESH");
    const instancedHoleElement = new InstancedMesh(
      "instanced hole interior",
      totalCount,
      scene
    );
    instancedHoleElement.setVerticesData(
      VertexBuffer.PositionKind,
      hole.getVerticesData(VertexBuffer.PositionKind),
      false
    );
    instancedHoleElement.setIndices(hole.getIndices());
    const instancesBuffer = new Buffer(
      scene.getEngine(),
      holesPositions,
      false,
      2,
      false,
      true
    );
    (instancedHoleElement as any)._instancesBuffer = instancesBuffer;
    const positionsVertexBuffer = instancesBuffer.createVertexBuffer(
      "instancePos",
      0,
      2
    );
    instancedHoleElement.setVerticesBuffer(positionsVertexBuffer);
    instancedHoleElement.material = new ShaderMaterial(
      "shader",
      scene,
      {
        vertex: "interior",
        fragment: "interior",
      },
      {
        attributes: ["position", "instancePos"],
        uniforms: ["worldViewProjection", "worldView"],
      }
    );

    instancedHoleElement.setBoundingInfo( new BoundingInfo(
      new B_Vector3(aabb.min.x, aabb.min.y, -100),
      new B_Vector3(aabb.max.x, aabb.max.y, 100)
    ));

    instancedHoleElement.rotation.z = rotation;

    return instancedHoleElement;
  }

  private getPerfPosForStamp(
    stampIndex: number,
    perfPos: {
      holes: Float32Array;
      border: number[];
      minX: number;
      maxX: number;
      minY: number;
      maskIndexes: number[];
      maxY: number;
    }
  ): {
    holes: Float32Array;
    border: number[];
  } {
    console.log("GET PERF POS FOR STAMP");
    const holes = [];

    for (let pi = 0; pi < perfPos.holes.length; pi += 2) {
      const index = perfPos.maskIndexes[pi / 2];
      if (index != stampIndex) {
        continue;
      }

      holes.push(perfPos.holes[pi]);
      holes.push(perfPos.holes[pi + 1]);
    }

    return { holes: new Float32Array(holes), border: perfPos.border };
  }

  private createPerforation(
    model: PerforationVisualizerViewModel,
    scene: Scene
  ): void {
    console.log({ model });

    this.instancedHole = [];
    this.borderInstancedHole = [];

    for (let i = 0; i < model.perforation.perforation.length; i++) {
      const perforation = model.perforation.perforation[i];
      if (!perforation.positions[0]) continue;
      const perfPos = getPerforationPositions(perforation, model.showBorder);
      console.log({ perfPos });
      const stamps = getStampList(perforation, false);
      console.log({ stamps });
      for (let i = 0; i < stamps.length; i++) {
        const stamp = stamps[i];
        if (stamp == null) continue;
        const constStampPerfPos = perforation.areEffectsEnabled
          ? this.getPerfPosForStamp(i, perfPos)
          : { holes: perfPos.holes, border: perfPos.border };
        console.log({ constStampPerfPos });
        let perforationNode: BendingNode = null;
        if (stamp.polygons && stamp.polygons[0] && stamp.polygons[0][0]) {
          const testPoint = addVectors2(
            perforation.positions[0],
            stamp.polygons[0][0]
          );
          perforationNode = findNodeThatContains(
            rotateVector2(testPoint, perforation.rotation),
            model.bendingTree
          );
        } else {
          continue;
        }

        if (!perforationNode) {
          debugger;
          console.error("Cannot find region that contains perforation area");
          //        perforationNode = model.bendingTree;
          continue;
        }

        if (
          model.perforationIndex !== null &&
          model.perforationIndex !== -1 &&
          model.perforationIndex != perforation.areaIndex
        ) {
          continue;
        }

        const hole: Mesh = PerforationVisualizer.createHoleMesh(
          stamp.polygons,
          scene
        );
        if (!hole) {
          return;
        }

        const totalCount = constStampPerfPos.holes.length / 2;
        console.log({ totalCount });

        if (constStampPerfPos.holes.length > 0) {
          const instancedHoleElement = perforation.punch
            ? this.createPunchMesh(
                stamp,
                totalCount,
                scene,
                constStampPerfPos.holes,
                model.plateDepth,
                perforation.rotation,
                {
                  min: { x: perfPos.minX, y: perfPos.minY },
                  max: { x: perfPos.maxX, y: perfPos.maxY },
                }
              )
            : this.createInstancedHolesMesh(
                totalCount,
                hole,
                scene,
                constStampPerfPos.holes,
                model.plateDepth,
                model.fillPerforation,
                perforation.rotation,
                {
                  min: { x: perfPos.minX, y: perfPos.minY },
                  max: { x: perfPos.maxX, y: perfPos.maxY },
                },
                stamp.index === model.selectedStamp
              );

          this.instancedHole.push(instancedHoleElement);
          instancedHoleElement.freezeWorldMatrix();
          instancedHoleElement.metadata = instancedHoleElement.metadata || {};
          instancedHoleElement.metadata.renderPriority = 1;
          instancedHoleElement.metadata.region =
            perforationNode && perforationNode.flatRegion;
          instancedHoleElement.renderingGroupId = 2;
          instancedHoleElement._worldMatrix.copyFrom(
            Matrix.RotationZ(perforation.rotation).multiply(
              perforationNode.world
            )
          );

          if (!perforation.punch) {
            const interior: Mesh =
              PerforationVisualizer.createHoleInteriorMesh(
                stamp.polygons,
                scene,
                model.plateDepth
              );
            const instancedInteriors = this.createInstancedHolesInteriorMesh(
              totalCount,
              interior,
              scene,
              constStampPerfPos.holes,
              perforation.rotation,
              {
                min: { x: perfPos.minX, y: perfPos.minY },
                max: { x: perfPos.maxX, y: perfPos.maxY },
              }
            );
            instancedInteriors.freezeWorldMatrix();
            instancedInteriors.metadata = instancedInteriors.metadata || {};
            instancedInteriors.metadata.renderPriority = 0;
            instancedInteriors.metadata.region =
              perforationNode && perforationNode.flatRegion;
            instancedInteriors.renderingGroupId = 2;
            instancedInteriors._worldMatrix.copyFrom(
              Matrix.RotationZ(perforation.rotation).multiply(
                perforationNode.world
              )
            );
            this.instancedHole.push(instancedInteriors);
            interior.dispose();
          }
        }

        if (constStampPerfPos.border.length > 0) {
          const borderInstancedHoleElement = perforation.punch
            ? this.createPunchMesh(
                stamp,
                constStampPerfPos.border.length / 2,
                scene,
                new Float32Array(constStampPerfPos.border),
                model.plateDepth,
                perforation.rotation,
                {
                  min: { x: perfPos.minX, y: perfPos.minY },
                  max: { x: perfPos.maxX, y: perfPos.maxY },
                }
              )
            : this.createInstancedHolesMesh(
                constStampPerfPos.border.length / 2,
                hole,
                scene,
                new Float32Array(constStampPerfPos.border),
                model.plateDepth,
                false,
                perforation.rotation,
                {
                  min: { x: perfPos.minX, y: perfPos.minY },
                  max: { x: perfPos.maxX, y: perfPos.maxY },
                },
                stamp.index === model.selectedStamp
              );

          borderInstancedHoleElement.freezeWorldMatrix();
          borderInstancedHoleElement.metadata =
            borderInstancedHoleElement.metadata || {};
          borderInstancedHoleElement.metadata.renderPriority = 1;
          borderInstancedHoleElement.metadata.region =
            perforationNode && perforationNode.flatRegion;
          borderInstancedHoleElement.renderingGroupId = 2;
          borderInstancedHoleElement._worldMatrix.copyFrom(
            Matrix.RotationZ(perforation.rotation).multiply(
              perforationNode.world
            )
          );

          borderInstancedHoleElement.renderOutline = true;
          borderInstancedHoleElement.outlineColor = new Color3(0, 0, 0);
          borderInstancedHoleElement.outlineWidth = 2;
          this.instancedHole.push(borderInstancedHoleElement);
        }

        hole.dispose();
      }
    }
  }

  dispose() {}
}

function createPerforationShaders() {
  if (!Effect.ShadersStore["holeVertexShader"]) {
    Effect.ShadersStore["holeVertexShader"] =
      "\r\n" +
      "precision highp float;\r\n" +
      "attribute vec3 position;\r\n" +
      "attribute vec2 instancePos;\r\n" +
      "uniform mat4 worldViewProjection;\r\n" +
      "uniform mat4 projection;\r\n" +
      "uniform mat4 worldView;\r\n" +
      "uniform float zPos;\r\n" +
      "void main(void) {\r\n" +
      "    vec3 pos = vec3(position.x,position.z,position.y) + vec3(instancePos.x, instancePos.y, zPos);\r\n" +
      "    gl_Position = worldViewProjection * vec4(pos, 1.0);\r\n" +
      "}\r\n";
    Effect.ShadersStore["holeFragmentShader"] =
      "\r\n" +
      "precision highp float;\r\n" +
      "varying vec4 depthPos;\r\n" +
      "uniform vec4 color;\r\n" +
      "void main(void) {\r\n" +
      "    gl_FragColor = color;\r\n" +
      "}\r\n";

    Effect.ShadersStore["punchVertexShader"] =
      "\r\n" +
      "precision highp float;\r\n" +
      "attribute vec3 position;\r\n" +
      "attribute vec2 instancePos;\r\n" +
      "uniform mat4 worldViewProjection;\r\n" +
      "uniform mat4 worldView;\r\n" +
      "uniform float zPos;\r\n" +
      "attribute vec2 uv;\r\n" +
      "varying vec2 v_texcoord;\r\n" +
      "void main(void) {\r\n" +
      "    vec3 pos = vec3(position.x,position.z,position.y) + vec3(instancePos.x, instancePos.y, zPos);\r\n" +
      "    gl_Position = worldViewProjection * vec4(pos, 1.0);\r\n" +
      "    v_texcoord = uv;\r\n" +
      "}\r\n";
    Effect.ShadersStore["punchFragmentShader"] =
      "\r\n" +
      "precision highp float;\r\n" +
      "uniform sampler2D u_texture;\r\n" +
      "varying vec2 v_texcoord;\r\n" +
      "void main(void) {\r\n" +
      "    gl_FragColor = texture2D(u_texture, v_texcoord) * vec4(1.0,1.0,1.0, 0.4);\r\n" +
      "    gl_FragDepth = (gl_FragCoord.z / gl_FragCoord.w - 0.000002) * gl_FragCoord.w;\r\n" +
      "}\r\n";
    Effect.ShadersStore["interiorVertexShader"] =
      "\r\n" +
      "precision highp float;\r\n" +
      "attribute vec3 position;\r\n" +
      "attribute vec2 uv;\r\n" +
      "attribute vec2 instancePos;\r\n" +
      "uniform mat4 worldViewProjection;\r\n" +
      // 'varying vec2 vUV;\r\n' +
      "void main(void) {\r\n" +
      "    vec3 pos = vec3(position.x,position.y,position.z) + vec3(instancePos.x, instancePos.y, 0);" +
      "    gl_Position = worldViewProjection * vec4(pos, 1.0);\r\n" +
      // '    vUV = uv;\r\n' +
      "}\r\n";
    Effect.ShadersStore["interiorFragmentShader"] =
      "\r\n" +
      "precision highp float;\r\n" +
      // 'varying vec2 vUV;\r\n' +
      // 'uniform sampler2D textureSampler;\r\n' +
      "void main(void) {\r\n" +
      // '    gl_FragColor = texture2D(textureSampler, vUV) * vec4(0.1,0.1,0.1,1.0);\r\n' +
      "    gl_FragColor = vec4(0.5,0.5,0.5,1.0);\r\n" +
      "}\r\n";
  }
}

function setZpos(camera: CameraModel, depth: number, mesh: Mesh) {
  let zPos = -depth / 2;
  if (camera) {
    const world = mesh.getWorldMatrix();
    const meshDir: Vector3 = transformDirection(camera.view, {
      x: world.m[8],
      y: world.m[9],
      z: world.m[10],
    });
    const meshPosition: Vector3 = transformPos(camera.view, {
      x: world.m[12],
      y: world.m[13],
      z: world.m[14],
    });
    if (dotProductVector3(meshPosition, meshDir) < 0) {
      zPos *= -1;
    }
  }
  (mesh.material as ShaderMaterial).setFloat("zPos", zPos);
}
