import {Element} from "../../model/product-configuration/element.model";
import {CHANGE_PATTERN_OFFSET, SET_PATTERN_OFFSET} from "../actions/current-position.actions";
import {addVectors2, Vector2} from "webcad/math";

export function currentPositionReducer( state: Element, action: any ): Element {
  switch (action.type) {
    case CHANGE_PATTERN_OFFSET:
      let offset: Vector2 = state.patternOffset || {x: 0, y: 0};
      offset = addVectors2(offset, action.payload);
      return {
        ...state,
        patternOffset: offset
      };
    case SET_PATTERN_OFFSET:
      return {
        ...state,
        patternOffset: action.payload
      };
  }
  return state;
}
