import { MeasurementModelManager, ModelVisualizer, Webcad } from 'webcad';
import { Vector3 } from 'webcad/math';
import { Plate, PlateMaterialType } from '../model/plate.model';
import { BendingNode } from '../model/view-model/bending-node.viewModel';
import { PlateVisualizerViewModel } from '../model/view-model/plate-visualizer.viewModel';
import { PlateMeshProvider } from '../providers/plate-mesh.provider';
import { hexToRgb } from '../utils/utils';
import {
  Color3,
  Engine,
  LinesMesh,
  Material,
  Mesh,
  Node,
  Scene,
  StandardMaterial,
  Texture,
  VertexData,
  Vector3 as B_Vector3
} from '@babylonjs/core';

export class PlateVisualizer
  implements ModelVisualizer<PlateVisualizerViewModel> {
  protected model: PlateVisualizerViewModel;
  protected parentNode: Node;
  private mesh: Mesh;
  private engine: Engine;
  private plateOutline: LinesMesh;
  private widthMeasurement: MeasurementModelManager;
  private heightMeasurement: MeasurementModelManager;
  private basicTextureMaterial: StandardMaterial;
  private shapeMaterial: StandardMaterial;
  private plateMaterial: Material;
  private colorizedMaterial: StandardMaterial;

  constructor(private plateMeshProvider: PlateMeshProvider) {}

  updateVisualization(newModel: PlateVisualizerViewModel): void {
    if (this.model !== newModel) {
      this.visualize(newModel);
    }
    this.plateMeshProvider.setPlate(newModel.plate);
    this.model = newModel;
  }

  visualize(newModel: PlateVisualizerViewModel) {
    if (this.mesh) {
      this.mesh.dispose();
    }
    if (this.plateOutline) {
      this.plateOutline.dispose();
    }
    this.plateMaterial = this.basicTextureMaterial;
    if (
      (newModel.plate.hexColorString.length > 0 &&
        newModel.plate.hexColorString !== this.model.plate.hexColorString) ||
      !this.colorizedMaterial
    ) {
      if (
        !!newModel.plate.hexColorString &&
        newModel.plate.hexColorString.length > 0
      ) {
        this.createMaterialFromModel(newModel.plate);
      }
    }
    if (
      !!this.colorizedMaterial &&
      !!newModel.plate.hexColorString &&
      newModel.plate.hexColorString.length > 0
    ) {
      this.plateMaterial = this.colorizedMaterial;
    }
    if (this.parentNode && this.parentNode.getScene()) {
      this.mesh = this.visualizePlate(newModel);
      if (this.mesh) {
        this.mesh.parent = this.parentNode;
        this.mesh.material =
          newModel.plate.plateMaterial === PlateMaterialType.shape
            ? this.shapeMaterial
            : this.plateMaterial;
      }
    }
  }

  init(
    rootNode: Node,
    model: PlateVisualizerViewModel,
    webcad: Webcad
  ): Promise<void> {
    this.model = model;
    // this.edgeState = model.flattened;
    this.parentNode = rootNode;
    this.engine = rootNode.getEngine();
    return this.createTexture(rootNode.getScene(), model.plate);
  }

  private visualizePlate(model: PlateVisualizerViewModel): Mesh {
    if (model && model.bendingTree && model.plate && model.plate.show) {
      return this.buildMeshThree(model.bendingTree, model.plate.depth);
    }
    return null;
  }

  createTexture(scene: Scene, model: Plate): Promise<void> {
    return new Promise<void>((resolve) => {
      if (!!model.hexColorString && model.hexColorString.length > 0) {
        this.createMaterialFromModel(model);
      }
      this.shapeMaterial = new StandardMaterial(
        'shape material',
        scene
      );
      this.shapeMaterial.emissiveColor = new Color3(
        253 / 255,
        255 / 255,
        190 / 255
      );

      this.basicTextureMaterial = new StandardMaterial('pbr', scene);
      this.basicTextureMaterial.emissiveColor = new Color3(
        0.7,
        0.7,
        0.7
      );
      const texture = new Texture('assets/aluminium2.jpg', scene);
      texture.onLoadObservable.add((ed, es) => {
        this.basicTextureMaterial.emissiveTexture = texture;
        this.basicTextureMaterial.emissiveColor = new Color3(
          0.1,
          0.1,
          0.1
        );
        resolve();
        if (this.model) {
          this.visualize(this.model);
        }
      });
      // this.basicTextureMaterial.specularColor = new BABYLON.Color3(0, 0, 0);

      // this.basicTextureMaterial.ambientColor = new BABYLON.Color3(1, 1, 1);
      // this.basicTextureMaterial.diffuseColor = new BABYLON.Color3(1, 1, 1);
      //      this.basicTextureMaterial.sideOrientation = BABYLON.Mesh.BACKSIDE;

      /*
      //this.basicTextureMaterial.baseColor = new BABYLON.Color3(1.000, 0.766, 0.336);
      this.basicTextureMaterial.metallic = 0.05;
      this.basicTextureMaterial.roughness = 0.1;

      this.basicTextureMaterial.environmentTexture = BABYLON.CubeTexture.CreateFromPrefilteredData("assets/environment.dds", scene);
      this.basicTextureMaterial.baseTexture = new BABYLON.Texture('assets/aluminium.jpg', scene);
      this.basicTextureMaterial.emissiveColor = new BABYLON.Color3(0.2, 0.2, 0.2);
      //this.basicTextureMaterial.reflectionColor = 0.5;

      (window as any).metallic = (m)=>{
        this.basicTextureMaterial.metallic = m;
      };
      (window as any).roughness = (r)=>{
        this.basicTextureMaterial.roughness = r;
      };
      */
      resolve();
    });
  }

  createMaterialFromModel(plate: Plate) {
    this.colorizedMaterial = new StandardMaterial(
      'plateMaterial',
      this.parentNode.getScene()
    );
    const rgb = hexToRgb(plate.hexColorString);
    const color = new Color3(rgb.r / 255, rgb.g / 255, rgb.b / 255);
    // this.colorizedMaterial.specularColor = color;
    // this.colorizedMaterial.specularPower = 3000;
    // this.colorizedMaterial.sideOrientation = BABYLON.Mesh.BACKSIDE;
    this.colorizedMaterial.emissiveColor = color;
    // this.colorizedMaterial.backFaceCulling = false;
    // this.colorizedMaterial.wireframe = true;
    // this.basicTextureMaterial.diffuseColor = color;
    // this.colorizedMaterial.emissiveColor = color;
  }

  dispose() {
    if (this.mesh) {
      this.mesh.dispose(false, true);
      this.mesh = null;
    }
    if (this.parentNode) {
      this.parentNode.dispose(false, true);
      this.parentNode = null;
    }
    this.basicTextureMaterial.emissiveTexture.dispose();
    // this.basicTextureMaterial.wireframe = true;
    this.basicTextureMaterial.dispose();
    this.basicTextureMaterial = null;
    if (this.plateOutline) {
      this.plateOutline.dispose();
      this.plateOutline = null;
    }
    if (this.widthMeasurement) {
      this.widthMeasurement.disposeModel();
      this.widthMeasurement = null;
    }
    if (this.heightMeasurement) {
      this.heightMeasurement.disposeModel();
      this.heightMeasurement = null;
    }
  }

  private buildMeshThree(node: BendingNode, thickness: number): Mesh {
    const region = node.flatRegion;
    const scene = this.parentNode.getScene();
    const mesh = new Mesh('plate', scene);
    mesh.material = this.plateMaterial;

    const vertices: number[] = [];
    const normals: number[] = [];
    const uvs: number[] = [];
    const colors: number[] = [];
    const indices: number[] = [];
    const lines: number[] = [];
    const t2 = thickness / 2;
    buffersFromRegion(
      region,
      indices,
      vertices,
      normals,
      uvs,
      colors,
      lines,
      (vertex) => {
        vertices.push(vertex.x, vertex.y, vertex.z - t2);
        normals.push(0, 0, 1);
        uvs.push(vertex.x * 4, vertex.y * 4);
      },
      (vertex) => {
        vertices.push(vertex.x, vertex.y, vertex.z + t2);
        normals.push(0, 0, -1);
        uvs.push(vertex.x * 4, vertex.y * 4);
      }
    );

    mesh.freezeWorldMatrix();
    mesh._worldMatrix.copyFrom(node.world);
    if (!!node.bendingLine) {
      const ba = node.bendingLine.bentParams.bendAllowance;
      const absAngle = Math.abs(node.bendingLine.angle);
      const angle = node.bendingLine.angle;
      const rOuter = node.bendingLine.bentParams.radius + thickness;
      const rInner = node.bendingLine.bentParams.radius;
      const rFront = angle < 0 ? rInner : rOuter;
      const rBack = angle < 0 ? rOuter : rInner;
      const offsetFront = angle < 0 ? rInner + t2 : rOuter - t2;
      const offsetBack = angle < 0 ? rOuter - t2 : rInner + t2;
      const n = angle < 0 ? 1 : -1;
      if (node.bendingRegion) {
        buffersFromRegion(
          node.bendingRegion,
          indices,
          vertices,
          normals,
          uvs,
          colors,
          lines,
          (vertex) => {
            const l = B_Vector3.TransformCoordinates(
              new B_Vector3(vertex.x, vertex.y, vertex.z),
              node.toLocal
            );
            const a = (absAngle * -l.x) / ba;
            const dirX = -Math.sin(a);
            const dirZ = Math.cos(a);
            const rotated = new B_Vector3(
              dirX * rFront,
              l.y,
              dirZ * rFront - offsetFront
            );
            const dir = new B_Vector3(dirX, 0, dirZ);
            if (angle > 0) {
              rotated.z = -rotated.z;
              dir.z = -dir.z;
            }

            const rotatedFlat = B_Vector3.TransformCoordinates(
              rotated,
              node.flatWorldR
            );
            const normalFlat = B_Vector3.TransformNormal(
              dir,
              node.flatWorldR
            );
            vertices.push(rotatedFlat.x, rotatedFlat.y, rotatedFlat.z);
            normals.push(n * normalFlat.x, n * normalFlat.y, n * normalFlat.z);
            uvs.push(vertex.x * 4, vertex.y * 4);
          },
          (vertex) => {
            const l = B_Vector3.TransformCoordinates(
              new B_Vector3(vertex.x, vertex.y, vertex.z),
              node.toLocal
            );
            const a = (absAngle * -l.x) / ba;
            const dirX = -Math.sin(a);
            const dirZ = Math.cos(a);
            const rotated = new B_Vector3(
              dirX * rBack,
              l.y,
              dirZ * rBack - offsetBack
            );
            const dir = new B_Vector3(dirX, 0, dirZ);
            if (angle > 0) {
              rotated.z = -rotated.z;
              dir.z = -dir.z;
            }
            const rotatedFlat = B_Vector3.TransformCoordinates(
              rotated,
              node.flatWorldR
            );
            const normalFlat = B_Vector3.TransformNormal(
              dir,
              node.flatWorldR
            );
            vertices.push(rotatedFlat.x, rotatedFlat.y, rotatedFlat.z);
            normals.push(
              -n * normalFlat.x,
              -n * normalFlat.y,
              -n * normalFlat.z
            );
            uvs.push(vertex.x * 4, vertex.y * 4);
          }
          //           vertex => {
          //             vertices.push(vertex.x, vertex.y, vertex.z - t2);
          //             normals.push(0, 0, 1);
          //             uvs.push(vertex.x*4, vertex.y*4);
          //           },
          //           vertex => {
          //             vertices.push(vertex.x, vertex.y, vertex.z + t2);
          //             normals.push(0, 0, -1);
          //             uvs.push(vertex.x*4, vertex.y*4);
          //           },
        );
      }
    }

    const newVertexData = new VertexData();
    newVertexData.indices = indices;
    newVertexData.positions = vertices;
    newVertexData.normals = normals;
    newVertexData.uvs = uvs;
    newVertexData.colors = colors;

    newVertexData.applyToMesh(mesh, false);

    mesh.renderingGroupId = 2;
    mesh.metadata = mesh.metadata || {};
    mesh.metadata.region = region;
    mesh.metadata.renderPriority = 2;
    // mesh.material.zOffset = 1;

    if (mesh) {
      mesh.onBeforeRenderObservable.add(() => {
        this.engine.setDepthFunctionToLess();
      });
    }
    mesh.onAfterRenderObservable.add(() => {
      this.engine.setDepthFunctionToLessOrEqual();
    });

    // if (mesh) {
    //   mesh.onBeforeRenderObservable.add(() => {
    //     this.engine.setColorWrite(true);
    //     this.engine.setDepthBuffer(true);
    //     this.engine.setStencilBuffer(true);
    //     this.engine.setStencilFunction(BABYLON.Engine.NOTEQUAL);
    //     this.engine.setStencilFunctionReference(2);
    //     this.engine.setStencilMask(0x00);
    //   });
    // }
    // mesh.onAfterRenderObservable.add(() => {
    //   this.engine.setStencilFunction(BABYLON.Engine.ALWAYS);
    //   this.engine.setStencilFunctionReference(1);
    //   this.engine.setStencilMask(0x00);
    //   this.engine.setStencilBuffer(false);
    // });

    if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i];
        this.buildMeshThree(child, thickness).parent = mesh;
      }
    }

    const outline = new LinesMesh('outline', scene, mesh);
    outline.color = new Color3(0 / 255, 66 / 255, 173 / 255);
    const outlineVertexData = new VertexData();
    outline.renderingGroupId = 3;
    outlineVertexData.positions = newVertexData.positions;
    outlineVertexData.indices = lines;
    outlineVertexData.applyToMesh(outline);
    return mesh;
  }
}

function buffersFromRegion(
  region,
  triangles: number[],
  vertices: number[],
  normals: number[],
  uvs: number[],
  colors: number[],
  lines: number[],
  addFrontVertex: (
    v: Vector3,
    vertices: number[],
    normals: number[],
    uvs: number[]
  ) => void,
  addBackVertex: (
    v: Vector3,
    vertices: number[],
    normals: number[],
    uvs: number[]
  ) => void
) {
  const vo1 = vertices.length / 3;
  for (let i = 0; i < region.vertices.length; i++) {
    // const vertex = region.vertices[i];
    // vertices.push(vertex.x, vertex.y, vertex.z - t2);
    // normals.push(0, 0, 1);
    // uvs.push(vertex.x, vertex.y);
    addFrontVertex(region.vertices[i], vertices, normals, uvs);
    colors.push(1.0, 1.0, 1.0, 1.0);
  }

  for (let i = 0; i < region.vertices.length; i++) {
    // const vertex = region.vertices[i];
    // vertices.push(vertex.x, vertex.y, vertex.z + t2);
    // normals.push(0, 0, -1);
    // uvs.push(vertex.x, vertex.y);
    addBackVertex(region.vertices[i], vertices, normals, uvs);
    colors.push(1.0, 1.0, 1.0, 1.0);
  }

  for (let i = 0; i < region.triangles.length; i += 3) {
    triangles.push(vo1 + region.triangles[i]);
    triangles.push(vo1 + region.triangles[i + 1]);
    triangles.push(vo1 + region.triangles[i + 2]);
  }
  const vo2 = vo1 + region.vertices.length;
  for (let i = 0; i < region.triangles.length; i += 3) {
    triangles.push(vo2 + region.triangles[i]);
    triangles.push(vo2 + region.triangles[i + 2]);
    triangles.push(vo2 + region.triangles[i + 1]);
  }

  let i1,
    i2,
    i3,
    i4,
    v1x,
    v1y,
    v1z,
    v2x,
    v2y,
    v2z,
    v3x,
    v3y,
    v3z,
    v4x,
    v4y,
    v4z,
    nx,
    ny,
    l;

  let voo = vertices.length / 3;
  for (let i = 0; i < region.edges.length; i += 2) {
    i1 = vo1 + region.edges[i + 1];
    i2 = vo1 + region.edges[i];
    i3 = vo2 + region.edges[i + 1];
    i4 = vo2 + region.edges[i];
    v1x = vertices[i1 * 3];
    v1y = vertices[i1 * 3 + 1];
    v1z = vertices[i1 * 3 + 2];
    v2x = vertices[i2 * 3];
    v2y = vertices[i2 * 3 + 1];
    v2z = vertices[i2 * 3 + 2];
    v3x = vertices[i3 * 3];
    v3y = vertices[i3 * 3 + 1];
    v3z = vertices[i3 * 3 + 2];
    v4x = vertices[i4 * 3];
    v4y = vertices[i4 * 3 + 1];
    v4z = vertices[i4 * 3 + 2];

    nx = v2y - v1y;
    ny = -(v2x - v1x);
    l = Math.sqrt(nx * nx + ny * ny);
    nx /= l;
    ny /= l;

    vertices.push(v1x, v1y, v1z, v2x, v2y, v2z, v3x, v3y, v3z, v4x, v4y, v4z);
    normals.push(nx, ny, 0, nx, ny, 0, nx, ny, 0, nx, ny, 0);
    uvs.push(
      uvs[i1 + 0],
      uvs[i1 + 1],
      uvs[i2 + 0],
      uvs[i2 + 1],
      uvs[i3 + 0],
      uvs[i3 + 1],
      uvs[i4 + 0],
      uvs[i4 + 1]
    );
    colors.push(
      0.7,
      0.7,
      0.7,
      1.0,
      0.7,
      0.7,
      0.7,
      1.0,
      0.7,
      0.7,
      0.7,
      1.0,
      0.7,
      0.7,
      0.7,
      1.0
    );
    triangles.push(voo, voo + 1, voo + 2, voo + 3, voo + 2, voo + 1);
    lines.push(voo, voo + 1, voo + 2, voo + 3);
    voo += 4;
  }
}
