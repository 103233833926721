import { Store } from "@ngrx/store";
import { Webcad } from "webcad";
import { PointerState } from "webcad/collision";
import { Vector3, lengthVector3, subVectors3 } from "webcad/math";
import { AngleMeasurementVisualizer } from "webcad/measurements";
import { AngleMeasurementModel } from "webcad/models";
import { ClosestSegments } from "../../providers/mevaco-pointer.provider";
import { SceneProvider } from "../../providers/scene.provider";
import { TranslationProvider } from "../../providers/translation.provider";
import { WebcadProvider } from "../../providers/webcad.provider";
import {
  AddAngleMeasurement,
  SetHintMessage,
} from "../../store/actions/drawing.actions";
import { MevacoState } from "../../store/reducers";
import { Tool } from "../tool.interface";
import {Scene, Node} from "@babylonjs/core";
enum AngleMeasurementToolSteps {
  SET_ORIGIN,
  SET_BEGIN,
  SET_END,
}

export class AngleMeasurementTool extends Tool {
  private scene: Scene;
  private currentModel: AngleMeasurementModel;
  private currentModelVisualizer: AngleMeasurementVisualizer;
  private rootNode: Node;
  private webcad: Webcad;
  private step: AngleMeasurementToolSteps =
    AngleMeasurementToolSteps.SET_ORIGIN;

  constructor(
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private webcadProvider: WebcadProvider,
    private translationProvider: TranslationProvider
  ) {
    super();
    this.sceneProvider.getSubscription().subscribe((scene) => {
      if (this.rootNode) {
        this.rootNode.dispose();
        this.rootNode = null;
      }
      if (scene !== null) {
        this.scene = scene;
        this.rootNode = new Node("measurementToolNode", this.scene);
      }
    });
    this.webcadProvider.getObservable().subscribe((webcad) => {
      this.webcad = webcad;
    });
  }

  activate() {
    this.step = AngleMeasurementToolSteps.SET_ORIGIN;
    this.currentModel = {
      editable: false,
      origin: { x: 0, y: 0, z: 0 },
      axis: { x: 0, y: 0, z: 1 },
      startDir: { x: 1, y: 0, z: 0 },
      visible: false,
      radius: 0,
      exchange: {
        value: 0,
        onInputLive: null, // to be set by tool
        onInputConfirmed: null, // to be set by tool
        inputValidation: (val) => val !== "" && !isNaN(+val),

        fromModel: (value: number) => ((value * 180) / Math.PI).toFixed(2),
        toModel: (value: string) => (+value / 180) * Math.PI,
      },
    };
    if (this.currentModelVisualizer) {
      this.currentModelVisualizer.dispose();
      this.currentModelVisualizer = null;
    }
    this.currentModelVisualizer = new AngleMeasurementVisualizer();
    this.currentModelVisualizer.init(
      this.rootNode,
      this.currentModel,
      this.webcad
    );
    this.store.dispatch(
      new SetHintMessage(
        this.translationProvider.translate("startAngleMeasurementTool")
      )
    );
  }

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {}

  onMouseClick(pointerState: PointerState) {
    switch (this.step) {
      case AngleMeasurementToolSteps.SET_ORIGIN:
        this.currentModel.origin = pointerState.position;
        this.step = AngleMeasurementToolSteps.SET_BEGIN;
        this._dirty = true;
        this.store.dispatch(
          new SetHintMessage(
            this.translationProvider.translate("endAngleMeasurementTool")
          )
        );
        break;
      case AngleMeasurementToolSteps.SET_BEGIN:
        this.currentModel.startDir = subVectors3(
          pointerState.position,
          this.currentModel.origin
        );
        this.updateModelWithPoint(pointerState.position);
        this.step = AngleMeasurementToolSteps.SET_END;
        this.store.dispatch(
          new SetHintMessage(
            this.translationProvider.translate("angleAngleMeasurementTool")
          )
        );
        break;
      case AngleMeasurementToolSteps.SET_END:
        this.updateModelWithPoint(pointerState.position);
        this.store.dispatch(new AddAngleMeasurement({ ...this.currentModel }));
        this.reset();
        this.activate();
        break;
    }
  }

  onMouseDown(pointerState: PointerState) {}

  onMouseMove(pointerState: PointerState) {
    if (this.step === AngleMeasurementToolSteps.SET_BEGIN) {
      this.currentModel.startDir = subVectors3(
        pointerState.position,
        this.currentModel.origin
      );
      this.updateModelWithPoint(pointerState.position);
      this.visualizeCurrentMeasurement();
    } else if (this.step === AngleMeasurementToolSteps.SET_END) {
      this.updateModelWithPoint(pointerState.position);
      this.visualizeCurrentMeasurement();
    }
  }

  onMouseUp(pointerState: PointerState) {}

  reset() {
    this.onCancel();
    this.activate();
  }

  visualizeCurrentMeasurement() {
    this.currentModelVisualizer.updateVisualization({ ...this.currentModel });
  }

  private updateModelWithPoint(point: Vector3): void {
    const toEnd = subVectors3(point, this.currentModel.origin);
    let angle =
      Math.atan2(toEnd.y, toEnd.x) -
      Math.atan2(this.currentModel.startDir.y, this.currentModel.startDir.x);
    if (angle < 0) {
      angle += Math.PI * 2;
    }
    // Math.acos(CosOfAngleBetween2Vectors2D(this.currentModel.startDir, toEnd));
    this.currentModel = {
      ...this.currentModel,
      radius: lengthVector3(toEnd),
      exchange: {
        ...this.currentModel.exchange,
        value: angle,
      },
      visible: true,
    };
  }

  onCancel() {
    this.currentModel = {
      editable: false,
      origin: { x: 0, y: 0, z: 0 },
      axis: { x: 0, y: 0, z: 1 },
      startDir: { x: 1, y: 0, z: 0 },
      visible: false,
      radius: 0,
      exchange: {
        value: 0,
        onInputLive: null, // to be set by tool
        onInputConfirmed: null, // to be set by tool
        inputValidation: (val) => val !== "" && !isNaN(+val),

        fromModel: (value: number) => ((value * 180) / Math.PI).toFixed(2),
        toModel: (value: string) => (+value / 180) * Math.PI,
      },
    };
    if (this.currentModelVisualizer) {
      this.currentModelVisualizer.dispose();
      this.currentModelVisualizer = null;
    }
    // this.currentModelVisualizer = new AngleMeasurementVisualizer();
    this.step = AngleMeasurementToolSteps.SET_ORIGIN;
    this._dirty = false;
  }

  onConfirm() {}

  isDirty() {
    return this._dirty;
  }
}
