import {routerReducer, RouterReducerState} from "@ngrx/router-store";
import {ActionReducerMap, createFeatureSelector, createSelector,} from "@ngrx/store";
import {Segment} from "webcad/models";
import {AdditionalHolesForConfigurationError} from "../../../app/model/additional-holes-for-configuration-error.enum";
import {environment} from "../../../environments/environment";
import {getNodeByPositionAndOrigin, PointNode} from "../../model";
import {DownloadDxfParams} from "../../model/client-server.Params/download-dxf-params";
import {PerforationParams} from "../../model/client-server.Params/perforation.params";
import {Dataset, SingleTool} from "../../model/dataset/dataset.model";
import {
  HoleSize,
  SpacingFormat,
  stringToFormat,
  stringToSize,
  stringToSpacingFormat,
} from "../../model/dataset/perforation-format.model";
import {Drawing, ShapeOrigin} from "../../model/drawing.model";
import {SelectedDesignerEffect} from "../../model/effect.model";
import {SelectedFading} from "../../model/fading.model";
import {initialMevacoPage, MevacoPage} from "../../model/mevaco-page.model";
import {SelectedMultiSizePattern, SelectedPerfaction,} from "../../model/perfaction.model";
import {Plate} from "../../model/plate.model";
import {Element} from "../../model/product-configuration/element.model";
import {PerforationCustomGeom} from "../../model/product-configuration/perforation-custom-geom";
import {formatToString, Perforation,} from "../../model/product-configuration/perforation.model";
import {
  anyBendedElement,
  getExpandedMetalGltfHash,
  ProductConfiguration,
  ProductType,
  Step,
} from "../../model/product-configuration/product-configuration.model";
import {RightPanel, SubpanelCurrentState,} from "../../model/right-panel.model";
import {anyErrorAction} from "../../model/rule-action.model";
import {ShapeWithHoles} from "../../model/shape-with-holes";
import {SmallerHoleSize, SmallerHoleSizesData,} from "../../model/smaller-hole-size.model";
import {SnapOptions} from "../../model/snap-options.model";
import {SnappingModel} from "../../model/snapping.model";
import {StepAndPlate} from "../../model/view-model/step-plate.viewModel";
import {ColliderItems} from "../../providers/colliders/collider-items";
import {MultiSizePattern} from "../../services/perforation.service";
import {mevacoPageReducer} from "./mevaco-page.reducer";
import {RouterStateUrl, routerStateUrlInitialState} from "./router.reducer";

export interface MevacoState {
  router: RouterReducerState<RouterStateUrl>;
  model: MevacoPage;
}

export const mevacoInitialstate: MevacoState = {
  router: { state: routerStateUrlInitialState, navigationId: -1 },
  model: initialMevacoPage,
};

export const mevacoReducers: ActionReducerMap<MevacoState> = {
  router: routerReducer,
  // model: reducerDebugWrapper(mevacoPageReducer)
  model: mevacoPageReducer,
};

export const getRouterState = createFeatureSelector('router');
export const  getModelState = createFeatureSelector('model');
export const getMevacoPageState = createSelector(
  getModelState,
  (state: MevacoPage) => state
);
export const getDrawingState = createSelector(
  getModelState,
  (state: MevacoPage) => state.drawing
);

export const getDataSetState = createSelector(
  getMevacoPageState,
  (state: MevacoPage) => state.dataset
);
export const getStateConfiguration = createSelector(
  getMevacoPageState,
  (state) => state.productConfiguration.configuration
);
export const getRightPanelState = createSelector(
  getModelState,
  (state: MevacoPage) => state.rightPanel
);
export const getSelectedUnit = createSelector(
  getModelState,
  (state: MevacoPage) => state.selectedUnit
);
export const getConfiguratorSubpanelState = createSelector(
  getRightPanelState,
  (state: RightPanel) => state.configuratorPanel
);
export const getEffectsSubpanelState = createSelector(
  getRightPanelState,
  (state: RightPanel) => state.effectsPanel
);
export const getFadingSubpanelState = createSelector(
  getRightPanelState,
  (state: RightPanel) => state.fadingPanel
);
export const getMessageVisualizerSubpanelState = createSelector(
  getRightPanelState,
  (state: RightPanel) => state.messageVisualizerPanel
);
export const getRightPanelWidth = createSelector(
  getRightPanelState,
  (state: RightPanel) => {
    let rightPanelWidth = 0;
    for (const [name, props] of Object.entries(state)) {
      if (props.state === SubpanelCurrentState.OPENED) {
        rightPanelWidth = rightPanelWidth + props.width;
      }
    }
    return rightPanelWidth;
  }
);

export const getDrawingPerforation = createSelector(
  getModelState,
  (state: MevacoPage) => state.lastPerforationResponse
);
export const getPlate = createSelector(
  getDrawingState,
  (state: Drawing) => state && state.plate
);
export const getAutoCenter = createSelector(
  getPlate,
  (state: Plate) => state && state.autoCenter
);
export const getPlatePosition = createSelector(
  getPlate,
  (state: Plate) => state.position
);
export const getPerforation = createSelector(
  getModelState,
  (state: MevacoPage) => state.lastPerforationResponse
);
export const getNodesState = createSelector(
  getDrawingState,
  (state: Drawing) => state.nodes
);
export const getCurrentActionType = createSelector(
  getPlate,
  (state: Plate) => state.actionType
);
export const getSelectedPerforationArea = createSelector(
  getDrawingState,
  (state: Drawing) =>
    state &&
    state.plate &&
    state.plate.perforationAreas &&
    state.plate.perforationAreas[state.selectedPerforationArea]
);
export const getSelectedPerforationAreaRotation = createSelector(
  getDrawingState,
  (state: Drawing) =>
    state &&
    state.plate.perforationAreas[state.selectedPerforationArea] &&
    state.plate.perforationAreas[state.selectedPerforationArea].rotation
);
export const getSelectedPerforationAreaIndex = createSelector(
  getDrawingState,
  (state: Drawing) => state && state.selectedPerforationArea
);
export const getPerforationAreaModels = createSelector(
  getPlate,
  (state: Plate) => state && state.perforationAreas
);
export const getSelectedOrFirstPerforationArea = createSelector(
  getPerforationAreaModels,
  getSelectedPerforationAreaIndex,
  (areas, index) => {
    index = index === -1 || !index ? 0 : index;
    return areas && areas.length > index ? areas[index] : null;
  }
);
export const getSelectedOrFirstPerforationAreaOffset = createSelector(
  getSelectedOrFirstPerforationArea,
  getPlate,
  (selectedArea, plate) =>
    (selectedArea && selectedArea.offset) ||
    plate.defaultPerforationAreaOffset || { x: 0, y: 0 }
);
export const getSnappingState = createSelector(
  getDrawingState,
  (state: Drawing) => state.snappingModel
);
export const getGridSize = createSelector(
  getSnappingState,
  (snappingGrid: SnappingModel) =>
    Math.min(snappingGrid.snapX, snappingGrid.snapY)
);
export const getProductConfiguration = createSelector(
  getModelState,
  (state: MevacoPage) => state.productConfiguration
);

export const getProductFormatType = createSelector(
  getProductConfiguration,
  (state: ProductConfiguration) => state.format.type
);
export const isMevacoUser = createSelector(
  getProductConfiguration,
  (state: ProductConfiguration) => state.isMevacoUser
);
export const getConfigurationId = createSelector(
  getProductConfiguration,
  (state: ProductConfiguration) => state.configId
);
export const getConfigurationPerforation = createSelector(
  getProductConfiguration,
  (state: ProductConfiguration) => state.perforation
);
export const getConfigurationElements = createSelector(
  getProductConfiguration,
  (state: ProductConfiguration) => state.elements
);

export const getSmallerHolesForCurrentConfiguration = createSelector(
  getMevacoPageState,
  (page: MevacoPage) => {
    const errors: AdditionalHolesForConfigurationError[] = [];
    const state = page.productConfiguration;
    const dataSet = page.dataset;
    // const holeSize = state.perforation.size;
    // const materialCode = state.material.quality;
    // const thickness = state.material.thickness;
    const geometryType =
      state.activePerforation === "perfaction"
        ? state.multiSizePattern.form
        : state.perforation.geometryType;

    // if (!holeSize || holeSize == '' || holeSize == '0') errors.push(AdditionalHolesForConfigurationError.HOLE_SIZE_NOT_SET);
    // if (!materialCode || materialCode == '') errors.push(AdditionalHolesForConfigurationError.MATERIAL_NOT_SET);
    // if (!thickness || thickness == '') errors.push(AdditionalHolesForConfigurationError.THICKNESS_NOT_SET);
    if (!geometryType || geometryType === "")
      errors.push(AdditionalHolesForConfigurationError.HOLE_TYPE_NOT_SET);

    if (errors.length !== 0) return { sizes: [], error: errors };

    // const numberThickens = parseFloat(thickness);
    // const holeSizeNumber = parseFloat(holeSize);

    if (!dataSet) return { sizes: [], error: errors };

    function onlyUniqueTools(value, index, array) {
      return array.findIndex((x) => x.toolId === value.toolId) === index;
    }

    const holes: SmallerHoleSize[] = dataSet.singleTools
      // .filter(x => x.materialCode.toString() === materialCode)
      .filter((x) => x.form === geometryType)
      // .filter(x => x.thicknessFrom < numberThickens && x.thicknessTo > numberThickens)
      // .filter(x => x.width < holeSizeNumber)
      .map<SmallerHoleSize>((x) => ({
        id: x.id,
        form: x.form,
        width: x.width,
        lenght: x.lenght,
        toolId: x.toolId,
      }))
      .filter(onlyUniqueTools);
    // if(holes.length === 0) errors.push(AdditionalHolesForConfigurationError.LACK_OF_SMALLER_SIZE);
    return { sizes: holes, error: errors } as SmallerHoleSizesData;
  }
);

export const getConfigurationsPerforationInputs = createSelector(
  getProductConfiguration,
  (state) => state.perforation
);
export const getConfigurationsMaterialInputs = createSelector(
  getProductConfiguration,
  (state) => state.material
);
export const getConfigrationsWallpaperInputs = createSelector(
  getProductConfiguration,
  (state) => state.wallpaper
);
export function getEffectInputs(
  state: ProductConfiguration
): SelectedDesignerEffect | null {
  return state.effect && state.effect.enabled ? state.effect : null;
}
export const getEffectInputsSelector = createSelector(
  getProductConfiguration,
  getEffectInputs
);

export function getFadingInputs(
  state: ProductConfiguration
): SelectedFading | null {
  return state.fading &&
    state.fading.enabled &&
    state.fading.fadingType !== undefined
    ? state.fading
    : null;
}
export const getFadingInputsSelector = createSelector(
  getProductConfiguration,
  getFadingInputs
);
export const getPerfactionInputs = createSelector(
  getProductConfiguration,
  (state) => state.perfaction
);
export const getIsPerfactionEnabled = createSelector(
  getPerfactionInputs,
  (state) => state.enabled
);
export const isEffectEnabled = createSelector(
  getEffectInputsSelector,
  (state) => !!state && state.enabled
);
export const isFadingEnabled = createSelector(
  getFadingInputsSelector,
  (state) => !!state && state.enabled
);

export const getPerfactionInputsConfig = createSelector(
  getPerfactionInputs,
  (state) => state.config
);
export const getPerfactionBrightness = createSelector(
  getPerfactionInputsConfig,
  (state) => (state ? state.brightness : 0)
);
export const getPerfactionIsInverted = createSelector(
  getPerfactionInputsConfig,
  (state) => (state ? state.inverted : false)
);
export interface IPerfactionPerforationParams {
  patter: string;
  format: SpacingFormat;
  position: number;
  additionalHoleSizes: string[];
}
export const getPerfactionImage = createSelector(
  getProductConfiguration,
  (state) => state.perfaction.imageId
);

export const getPerforationVariables = createSelector(
  getPlatePosition,
  getConfigurationPerforation,
  getConfigurationElements,
  (position: number, perforation: Perforation, elements: Element[]) => {
    if (position !== null && perforation !== null && elements !== null) {
      return {
        position,
        perforation,
        elements,
      };
    }
    return null;
  }
);
export const getSelectedShapeId = createSelector(
  getDrawingState,
  (state: Drawing) => state.selectedShapeId
);
export const getBendings = createSelector(
  getDataSetState,
  (state: Dataset) => state.bendings
);
export const getSingleTools = createSelector(
  getDataSetState,
  (state: Dataset) => state.singleTools
);
export const getSingleToolsFormTypes = createSelector(
  getSingleTools,
  (singleTools) =>
    singleTools
      .filter(isDuplicate((a, b) => a.formGroup === b.formGroup))
      .sort((a, b) => a.formGroupSort - b.formGroupSort)
      .map((st) => st.formGroup)
);
export const getEffects = createSelector(getDataSetState, (state: Dataset) =>
  state == null ? null : state.effects
);

export const loaded = createSelector(
  getProductConfiguration,
  getDataSetState,
  (config, dataSet) => !!config && !!dataSet
);

export const isDataReady = createSelector(
  getProductConfiguration,
  getDataSetState,
  (config, dataSet) => {
    if (config && dataSet) {
      return config.perforation.format !== "" && dataSet !== null;
    } else {
      return false;
    }
  }
);
export const getReloadState = createSelector(
  getMevacoPageState,
  (state: MevacoPage) => state.positionsToReload
);
export const getElementWithId = (id: number) =>
  createSelector(getConfigurationElements, (elements) => elements[id - 1]);
export const needElementUpdate = (id: number) =>
  createSelector(getReloadState, (reloadState) => reloadState.includes(id));
export const elementHasErrors = (id: number) =>
  createSelector(getMevacoPageState, (state) => {
    const rules = state.rulesActions.elements[id];
    if (!!rules) {
      for (const rule of rules) {
        if (
          rule.type.toLowerCase() === "error" &&
          rule.fieldName !== "overall"
        ) {
          return true;
        }
      }
    }
    return false;
  });
export const getOuterShapePolyline = createSelector(
  getPlate,
  (state: Plate) => state.shapeWithHoles.conture
);
export const getPerforationArea = createSelector(getPlate, (state: Plate) =>
  state.perforationAreas.map((x) => x.shape)
);
export const getShapeWithHoles = createSelector(
  getPlate,
  (state: Plate) => state && state.shapeWithHoles
);

export const getMountingHoles = createSelector(
  getPlate,
  (state: Plate) => state.mountingHoles
);
export const getStep = createSelector(
  getProductConfiguration,
  (state: ProductConfiguration) => state.step
);

export const getIsCustomPerforation = createSelector(
  getProductConfiguration,
  (state: ProductConfiguration) => state.customPerforation
);

export const getStepAndPlate = createSelector(
  getStep,
  getPlate,
  (step: Step, plate: Plate) =>
    <StepAndPlate>{
      step: step,
      plate: plate,
    }
);
export const getHelpLines = createSelector(
  getDrawingState,
  (state: Drawing) => state.helpingLines
);
export const getToolHelplines = createSelector(
  getDrawingState,
  (state: Drawing) => state.toolHelpLines
);
export const getSnapOptions = createSelector(
  getDrawingState,
  (state: Drawing) => state.snapOptions
);
export const getSnapEdgeStatus = createSelector(
  getSnapOptions,
  (state: SnapOptions) => state.edges
);
export const getSnapNodeStatus = createSelector(
  getSnapOptions,
  (state: SnapOptions) => state.nodes
);
export const getSnapGridStatus = createSelector(
  getSnapOptions,
  (state: SnapOptions) => state.grid
);
export const getMountingsStatus = createSelector(
  getSnapOptions,
  (state: SnapOptions) => state.mountingHoles
);
export const getSnapMeasurementStatus = createSelector(
  getSnapOptions,
  (state: SnapOptions) => state.measurements
);
export const getSnapHelpLineStatus = createSelector(
  getSnapOptions,
  (state: SnapOptions) => state.helpLines
);
export const getPerforationAreaStatus = createSelector(
  getSnapOptions,
  (state: SnapOptions) => state.perforationArea
);

export const getSelectedNodes = createSelector(
  getDrawingState,
  (state: Drawing) => state.selectedNodes
);
export const getSelectedSegments = createSelector(
  getSelectedNodes,
  getSnapOptions,
  getShapeWithHoles,
  getPerforationArea,
  (
    selectedNodes: PointNode[],
    snapOptions: SnapOptions,
    plateShape: ShapeWithHoles,
    perforationArea: ShapeWithHoles[]
  ): Segment[] => {
    const selectedSegments: Segment[] = [];
    if (selectedNodes && snapOptions) {
      if (plateShape) {
        for (const segment of plateShape.conture) {
          const beginNode = getNodeByPositionAndOrigin(
            selectedNodes,
            segment.begin,
            ShapeOrigin.SHAPE
          );
          const endNode = getNodeByPositionAndOrigin(
            selectedNodes,
            segment.end,
            ShapeOrigin.SHAPE
          );
          if (!!beginNode && !!endNode) {
            selectedSegments.push(segment);
          }
        }
        for (const hole of plateShape.holes) {
          for (const segment of hole) {
            const beginNode = getNodeByPositionAndOrigin(
              selectedNodes,
              segment.begin,
              ShapeOrigin.SHAPE
            );
            const endNode = getNodeByPositionAndOrigin(
              selectedNodes,
              segment.end,
              ShapeOrigin.SHAPE
            );
            if (!!beginNode && !!endNode) {
              selectedSegments.push(segment);
            }
          }
        }
      }
      if (snapOptions.perforationArea && perforationArea) {
        for (const shape of perforationArea) {
          for (const segment of shape.conture) {
            const beginNode = getNodeByPositionAndOrigin(
              selectedNodes,
              segment.begin,
              ShapeOrigin.PERFORATION
            );
            const endNode = getNodeByPositionAndOrigin(
              selectedNodes,
              segment.end,
              ShapeOrigin.PERFORATION
            );
            if (!!beginNode && !!endNode) {
              selectedSegments.push(segment);
            }
          }
          for (const hole of shape.holes) {
            for (const segment of hole) {
              const beginNode = getNodeByPositionAndOrigin(
                selectedNodes,
                segment.begin,
                ShapeOrigin.PERFORATION
              );
              const endNode = getNodeByPositionAndOrigin(
                selectedNodes,
                segment.end,
                ShapeOrigin.PERFORATION
              );
              if (!!beginNode && !!endNode) {
                selectedSegments.push(segment);
              }
            }
          }
        }
      }
    }
    return selectedSegments;
  }
);
export const getClosestSegmentVisibilityState = createSelector(
  getDrawingState,
  (state: Drawing) => state.closestSegmentVisibility
);
export const getHintMessage = createSelector(
  getDrawingState,
  (state: Drawing) => state.hint.label.value
);
export const getMeasurementsState = createSelector(
  getDrawingState,
  (state: Drawing) => state.measurements
);
export const getAngleMeasurementsState = createSelector(
  getDrawingState,
  (state: Drawing) => state.angleMeasurements
);
export const getSelectedMeasurement = createSelector(
  getDrawingState,
  (state: Drawing) => {
    if (state.selectedMeasurementId !== null) {
      return state.measurements.get(state.selectedMeasurementId);
    } else {
      return null;
    }
  }
);
export const getSelectedAutomaticMeasurement = createSelector(
  getDrawingState,
  (state: Drawing) => {
    if (state.selectedAutomaticMeasurementId !== null) {
      return state.automaticMeasurements.get(
        state.selectedAutomaticMeasurementId
      );
    } else {
      return null;
    }
  }
);

export const getSelectedAngleMeasurement = createSelector(
  getDrawingState,
  (state: Drawing) => {
    if (state.selectedAngleMeasurementId !== null) {
      return state.angleMeasurements.get(state.selectedAngleMeasurementId);
    } else {
      return null;
    }
  }
);
export const getSelectedHelpLine = createSelector(
  getDrawingState,
  (state: Drawing) => {
    const selected = state.helpingLines.get(state.selectedHelpLineId);
    if (selected) {
      return selected;
    } else {
      return null;
    }
  }
);
export const getMountings = createSelector(
  getMevacoPageState,
  (state: MevacoPage) => state.mountings
);
export const getPlateMountings = createSelector(
  getPlate,
  (state: Plate) => state.mountingHoles
);
export const getMountingsModel = createSelector(
  getDrawingState,
  (state) => state.mountingsModel
);
export const getSelectedMountingForm = createSelector(
  getMountingsModel,
  (state) => state.mounting.form
);
export const getSelectedMountingPositioning = createSelector(
  getMountingsModel,
  (state) => state.mounting.positioning
);
export const getMaterial = createSelector(
  getMevacoPageState,
  (state) => state.productConfiguration.configuration.material
);
export const getMaterialCode = createSelector(
  getMaterial,
  (state) => state && state.materialcodeLomoe
);
export const getSelectedMaterialType = createSelector(
  getMevacoPageState,
  (state) => state.productConfiguration.material.materialType
);
export const getSelectedMaterialCode = createSelector(
  getMevacoPageState,
  (state) => state.productConfiguration.material.quality
);
export const getSelectedMaterialFoil = createSelector(
  getMevacoPageState,
  (state) => state.productConfiguration.material.foil
);
export const getSelectedLomoeMaterialCode = createSelector(
  getMevacoPageState,
  (state) => state.productConfiguration.configuration.material.materialcodeLomoe
);
export const getSelectedMaterialThickness = createSelector(
  getMevacoPageState,
  (state) => state.productConfiguration.material.thickness
);
export const getSelectedMountings = createSelector(
  getDrawingState,
  (state) => state.selectedMountings
);
export const getSurface = createSelector(
  getProductConfiguration,
  (state) => state.surface
);
export const getSurfaceType = createSelector(
  getProductConfiguration,
  (state) => state.surface.surfaceType
);
export const getSurfacePolishedSides = createSelector(
  getProductConfiguration,
  (state) => state.surface.polishedSides
);
export const getMaxElementsWidth = createSelector(
  getProductConfiguration,
  (state) => state.maxElementWidth
);
export const getMaxElementsLength = createSelector(
  getProductConfiguration,
  (state) => state.maxElementLength
);
export const getProductType = createSelector(
  getProductConfiguration,
  (state) => state.productType
);

export const getShapeForMeasurements = createSelector(
  getPlate,
  getStep,
  getProductType,
  (plate, step, productType) => productType === (ProductType.ExtendedMetals && step !== Step.shape) ? plate.cutShapeWithHoles : plate.shapeWithHoles
);

export const getPerforationParams = createSelector(
  getMevacoPageState,
  (state) => {
    const perf = state.productConfiguration.perforation;
    const element =
      state.productConfiguration.elements[state.drawing.plate.position - 1];

    if (perf.format !== "") {
      const size = stringToSize(perf.size);
      const format = stringToFormat(perf.format);

      if (!element.nodes) {
        return;
      }
      return {
        pattern: perf.perforationType,
        w: size.width / 1000,
        l: size.length / 1000,
        p1: format.p1 / 1000,
        p2: format.p2 / 1000,
        shape: element.perforationAreas,
      } as PerforationParams;
    } else {
      return null;
    }
  }
);

export const getPerforationType = createSelector(
  getMevacoPageState,
  (state) => state.productConfiguration.perforation.perforationType
);

export const getProductPerforationType = createSelector(
  getMevacoPageState,
  (state) => state.productConfiguration.activePerforation
);
export const getPerforationSize = createSelector(
  getMevacoPageState,
  (state) => state.productConfiguration.perforation.size
);
export const getPerforationFormat = createSelector(
  getMevacoPageState,
  (state) => state.productConfiguration.perforation.format
);
export const getPerforationPosition = createSelector(
  getMevacoPageState,
  (state) => state.productConfiguration.perforation.position
);
export const getPerforationDependentMode = createSelector(
  getMevacoPageState,
  (state) => state.productConfiguration.perforation.dependentMode
);

export const getWallpaperName = createSelector(
  getMevacoPageState,
  (state) => state.productConfiguration.wallpaper.name
);
export const getWallpaperZoom = createSelector(
  getMevacoPageState,
  (state) => state.productConfiguration.wallpaper.zoomEnabled
);
export const getWallpaperHalfHols = createSelector(
  getMevacoPageState,
  (state) => state.productConfiguration.wallpaper.halfHolesEnabled
);

export const getDownloadDxfParams = createSelector(
  getProductConfiguration,
  getPlate,
  (config, plate) => {
    return {
      id: config.configId,
      position: plate.position,
    } as DownloadDxfParams;
  }
);
export const getImportedShape = createSelector(getDrawingState, (state) => {
  if (state.importedShape) {
    return state.importedShape;
  }
  return null;
});
export const getUserTemplates = createSelector(
  getMevacoPageState,
  (state) => state.userTemplates
);
export const getUserPatterns = createSelector(
  getMevacoPageState,
  (state) => state.userPatterns
);
export const getMountingsVertices = createSelector(
  getMountingsModel,
  (state) => state.verticesMap
);
export const getMountingsShapes = createSelector(
  getMountingsModel,
  (state) => state.shapeMap
);
export const getConfigurableTemplates = createSelector(
  getMevacoPageState,
  (state) => state.configurableTemplates
);
export const getBasicRectangleTemplate = createSelector(
  getConfigurableTemplates,
  (state) => state.basicRectangleTemplate
);
export const getExpandedMetalTemplate = createSelector(
  getConfigurableTemplates,
  (state) => state.expandedMetalTemplate
);
export const getBalconyCornerTemplate = createSelector(
  getConfigurableTemplates,
  (state) => state.balconyCornerTemplate
);
export const getMultiShapeTemplate = createSelector(
  getConfigurableTemplates,
  (state) => state.multiShape
);
export const getRectangleNotchTemplate = createSelector(
  getConfigurableTemplates,
  (state) => state.rectangleNotchTemplate
);
export const getParallelogramTemplate = createSelector(
  getConfigurableTemplates,
  (state) => state.parallelogramTemplate
);
export const getStaircaseTemplate = createSelector(
  getConfigurableTemplates,
  (state) => state.staircaseTemplate
);
export const getTrapezeTemplate = createSelector(
  getConfigurableTemplates,
  (state) => state.trapezeTemplate
);
export const isAnyNodeSelected = createSelector(
  getSelectedNodes,
  (state: PointNode[]) => state.length > 0
);
export const isChecked = createSelector(
  getProductConfiguration,
  (state: ProductConfiguration) => state.generateLabel
);
export const getHintStatus = createSelector(
  getDrawingState,
  (state: Drawing) => state.hint.status
);
export const getHintVisibility = createSelector(
  getDrawingState,
  (state: Drawing) => state.hint.label.visible
);
export const reqValBasicRectangle = createSelector(
  getBasicRectangleTemplate,
  (state) =>
    !state.a ||
    state.a < 0 ||
    !state.b ||
    state.b < 0 ||
    !state.e1 ||
    state.e1 < 0 ||
    !state.e2 ||
    state.e2 < 0 ||
    !state.f1 ||
    state.f1 < 0 ||
    !state.f2 ||
    state.f2 < 0
);
export const reqValRectangleNotch = createSelector(
  getRectangleNotchTemplate,
  (state) =>
    !state.x1 ||
    state.x1 < 0 ||
    !state.x2 ||
    state.x2 < 0 ||
    !state.x3 ||
    state.x3 < 0 ||
    !state.x4 ||
    state.x4 < 0 ||
    !state.y1 ||
    state.y1 < 0 ||
    !state.y2 ||
    state.y2 < 0 ||
    !state.y3 ||
    state.y3 < 0 ||
    !state.y4 ||
    state.y4 < 0 ||
    !state.a ||
    state.a < 0 ||
    !state.b ||
    state.b < 0 ||
    !state.e1 ||
    state.e1 < 0 ||
    !state.e2 ||
    state.e2 < 0 ||
    !state.f1 ||
    state.f1 < 0 ||
    !state.f2 ||
    state.f2 < 0
);
export const reqValBalconyCorner = createSelector(
  getBalconyCornerTemplate,
  (state) =>
    !state.x1 ||
    state.x1 < 0 ||
    !state.x2 ||
    state.x2 < 0 ||
    !state.x3 ||
    state.x3 < 0 ||
    !state.x4 ||
    state.x4 < 0 ||
    !state.y1 ||
    state.y1 < 0 ||
    !state.y2 ||
    state.y2 < 0 ||
    !state.y3 ||
    state.y3 < 0 ||
    !state.y4 ||
    state.y4 < 0 ||
    !state.x5 ||
    state.x5 < 0 ||
    !state.y5 ||
    state.y5 < 0 ||
    !state.a ||
    state.a < 0 ||
    !state.b1 ||
    state.b1 < 0 ||
    !state.e1 ||
    state.e1 < 0 ||
    !state.e2 ||
    state.e2 < 0 ||
    !state.f1 ||
    state.f1 < 0 ||
    !state.f2 ||
    state.f2 < 0 ||
    !state.b2 ||
    state.b2 < 0
);
export const reqValMultiShape = createSelector(
  getMultiShapeTemplate,
  (state) =>
    !state.plateParams.a ||
    state.plateParams.a < 0 ||
    !state.plateParams.b ||
    state.plateParams.b < 0 ||
    !state.plateParams.e1 ||
    state.plateParams.e1 < 0 ||
    !state.plateParams.e2 ||
    state.plateParams.e2 < 0 ||
    !state.plateParams.f1 ||
    state.plateParams.f1 < 0 ||
    !state.plateParams.f2 ||
    state.plateParams.f2 < 0
);
export const reqValParallelogram = createSelector(
  getParallelogramTemplate,
  (state) =>
    !state.a ||
    state.a < 0 ||
    !state.b ||
    state.b < 0 ||
    !state.e1 ||
    state.e1 < 0 ||
    !state.e2 ||
    state.e2 < 0 ||
    !state.f1 ||
    state.f1 < 0 ||
    !state.f2 ||
    state.f2 < 0 ||
    !state.b1 ||
    !state.ha ||
    state.ha < 0 ||
    !state.tilt
);
export const reqValTrapeze = createSelector(
  getTrapezeTemplate,
  (state) =>
    !state.a ||
    state.a < 0 ||
    state.hb === null ||
    state.hb === undefined ||
    !state.e1 ||
    state.e1 < 0 ||
    !state.e2 ||
    state.e2 < 0 ||
    !state.f1 ||
    state.f1 < 0 ||
    !state.f2 ||
    state.f2 < 0 ||
    !state.ha1 ||
    state.ha1 < 0 ||
    !state.ha2 ||
    state.ha2 < 0 ||
    !state.b1 ||
    state.b1 < 0 ||
    !state.b2 ||
    state.b2 < 0 ||
    !state.beta ||
    !state.alpha
);
export const reqValStaircase = createSelector(
  getStaircaseTemplate,
  (state) =>
    !state.a1 ||
    state.a1 < 0 ||
    !state.a2 ||
    state.a2 < 0 ||
    !state.b1 ||
    state.b1 < 0 ||
    !state.b2 ||
    state.b2 < 0 ||
    !state.b3 ||
    state.b3 < 0 ||
    !state.tilt ||
    !state.e1 ||
    state.e1 < 0 ||
    !state.e2 ||
    state.e2 < 0 ||
    !state.f1 ||
    state.f1 < 0 ||
    !state.f2 ||
    state.f2 < 0 ||
    !state.ha
);
export const getRuleActionsForActivePlate = createSelector(
  getMevacoPageState,
  getPlatePosition,
  (state: MevacoPage, position: number) => state.rulesActions.elements[position]
);
export const getActiveElement = createSelector(
  getPlatePosition,
  getConfigurationElements,
  (position, elements) => elements[position - 1]
);
export const getActiveElementPerforationAreas = createSelector(
  getActiveElement,
  (element) => element && element.perforationAreas
);
export const getActiveElementPerforationAreasSimpleRectangle = createSelector(
  getActiveElement,
  (element) => element && element.isPerforationSimpleRect
);
export const getActiveElementPerforationAutoCenter = createSelector(
  getActiveElement,
  (element) => element && element.perforationAutoCenter
);
export const getActiveElementUuid = createSelector(
  getActiveElement,
  (element) => element && element.uuid
);
export const getActiveElementPerforationOffset = createSelector(
  getActiveElement,
  (element) => element && element.perfactionOffset
);
export const getTranslations = createSelector(
  getMevacoPageState,
  (dataset) => dataset.translations
);
export const getEnableNotesStatus = createSelector(
  getProductConfiguration,
  (config) => config.generateLabel
);
export const showStartPopup = createSelector(
  getProductConfiguration,
  (config) => config.showStartPopup
);
export const loadTemplateModal = createSelector(
  getProductConfiguration,
  (config) => config.loadTemplateModal
);
export const getAutomaticMeasurements = createSelector(
  getDrawingState,
  (state) => state.automaticMeasurements
);
export const getBendingLines = createSelector(
  getPlate,
  (state) => state.bendingLines
);
// export const getZones = createSelector(getPlate, (state) => state.possiblePerforationZones);
const partColliderItems = createSelector(
  getNodesState,
  getShapeWithHoles,
  getPerforationAreaModels,
  getHelpLines,
  getMountingHoles,
  (nodes, shapeWithHoles, perforationAreas, helpLines, mountingHoles) => {
    return {
      nodes: nodes,
      shapeWithHoles: shapeWithHoles,
      perforationAreas: perforationAreas,
      helpLines: helpLines,
      mountingHoles: mountingHoles,
    };
  }
);
export const getColliderItems = createSelector(
  partColliderItems,
  getMeasurementsState,
  getAngleMeasurementsState,
  getAutomaticMeasurements,
  getToolHelplines,
  getPerforation,
  getImportedShape,
  getBendingLines,
  (
    part,
    measurements,
    angleMeasurements,
    automaticMeasurements,
    toolHelpLines,
    perforation,
    importedShape,
    bendingLines
  ) => {
    return {
      nodes: part.nodes,
      shapeWithHoles: part.shapeWithHoles,
      bendingLines: bendingLines,
      perforationAreas: part.perforationAreas,
      helpLines: part.helpLines,
      mountingHoles: part.mountingHoles,
      measurements: measurements,
      angleMeasurements: angleMeasurements,
      toolHelpLines: toolHelpLines,
      perforation: perforation,
      importedShape: importedShape,
      automaticMeasurements: automaticMeasurements,
    } as ColliderItems;
  }
);
export const getRuleActions = createSelector(
  getMevacoPageState,
  (state: MevacoPage) => state.rulesActions
);
export const incompleteReason = createSelector(
  getRuleActions,
  getConfigurationElements,
  getConfigurationsPerforationInputs,
  getConfigurationsMaterialInputs,
  getPerforationType,
  getIsCustomPerforation,
  getConfigrationsWallpaperInputs,
  getSurface,
  (
    rules,
    elements,
    perfInputs,
    materialInputs,
    perfType,
    isCustom,
    wallpaperInput,
    surface
  ) => {
    if (elements.length <= 0) {
      return "Missing possitions";
    }

    if (perfType === "classic") {
      if (!isCustom) {
        if (perfInputs.geometryType === "") {
          return "Missing geometryType";
        }
        if (perfInputs.perforationType === "") {
          return "Missing perforationType";
        }
        if (perfInputs.size === "") {
          return "Missing perforationSize";
        }
        if (perfInputs.spacing === "") {
          return "Missing perforationSpacing";
        }
        if (perfInputs.format === "") {
          return "Missing perforationFormat";
        }
      } else {
        if (
          !perfInputs.custom.offsetX ||
          !perfInputs.custom.offsetY ||
          !perfInputs.custom.stumps.some(
            (stamp) =>
              !!stamp &&
              stamp.enabled &&
              !!stamp.offsetDivX &&
              !!stamp.offsetDivY &&
              !!stamp.w
          )
        ) {
          return "Missing custom pattern";
        }
      }
    } else if (perfType === "wallpaper") {
      if (wallpaperInput.name === "") {
        return "Missing wallpaper";
      }
    }

    if (materialInputs.materialType === "") {
      return "Missing materialType";
    }
    if (materialInputs.thickness === "") {
      return "Missing thickness";
    }
    if (materialInputs.quality === "") {
      return "Missing quality";
    }

    if (surface.surfaceType === "") {
      return "Missing surfaceType";
    }
    if (surface.surfaceType === "Polished" && surface.polishedSides === "") {
      return "Missing polishedSides";
    }
    if (surface.surfaceType === "Coated") {
      if (surface.colorSystem === "") {
        return "Missing colorSystem";
      }
      if (surface.colorGroup === "") {
        return "Missing colorGroup";
      }
      if (surface.color === "") {
        return "Missing color";
      }
      if (surface.undercoating === "") {
        return "Missing undercoating";
      }
      if (surface.levelOfGloss === "") {
        return "Missing levelOfGloss";
      }
    }

    if (!rules) {
      // not validate yet
      return "Waiting for validation";
    }

    if (anyErrorAction(rules)) {
      return "Validation failed";
    }
    return null;
  }
);

export const areAllPerforationsAreasSimpleRectangle = createSelector(
  getConfigurationElements,
  (elements) => !elements.find((element) => !element.isPerforationSimpleRect)
);

export const isImporting = createSelector(
  getDrawingState,
  (state: Drawing) => state.isImporting
);
export const getRemovedMeasurements = createSelector(
  getDrawingState,
  (state: Drawing) => state.removedMeasurements
);
export const getAppMode = createSelector(
  getMevacoPageState,
  (state: MevacoPage) => state.mode
);

export const isAnyBendedElement = createSelector(
  getProductConfiguration,
  (prodConf) => anyBendedElement(prodConf)
);

export const perforationPositionCanBeSelected = createSelector(
  getProductConfiguration,
  (productConfiguration: ProductConfiguration) =>
    !productConfiguration.elements ||
    productConfiguration.elements.length === 0 ||
    !productConfiguration.elements.some(
      (e) => !e.perforationAutoCenter || !e.isPerforationSimpleRect
    )
);

export const getPerforationCustomGeom = createSelector(
  getConfigurationsPerforationInputs,
  (perforation) => perforation.custom
);
export const getActiveUniqueCustomStampsParams = createSelector(
  getPerforationCustomGeom,
  (custom) => {
    if (custom && custom.stumps) {
      const filtered = custom.stumps
        .filter((stamp) => stamp && stamp.enabled && stamp.form && stamp.w)
        .map((stamp) => ({
          shape: stamp.form,
          w: stamp.w,
          l: stamp.l,
        }))
        .filter(
          (stamp, index, self) =>
            self.findIndex(
              (s) =>
                s.shape === stamp.shape && s.w === stamp.w && s.l === stamp.l
            ) === index
        );

      if (filtered.length > 0) {
        return filtered;
      }
      return null;
    }
  }
);
export const getCustomStampsParamsJson = createSelector(
  getActiveUniqueCustomStampsParams,
  (stamps) => (stamps ? JSON.stringify(stamps) : null)
);
export const getCustomStamp = createSelector(
  getPerforationCustomGeom,
  (custom, index: number) => custom.stumps[index]
);
export const getCustomStampFormType = createSelector(
  getPerforationCustomGeom,
  (custom, index: number) =>
    custom.stumps[index] ? custom.stumps[index].formType : null
);
export const getCustomStampForm = createSelector(
  getPerforationCustomGeom,
  (custom, index: number) =>
    custom.stumps[index] ? custom.stumps[index].form : null
);
export const getCustomStampWidth = createSelector(
  getPerforationCustomGeom,
  (custom, index: number) =>
    custom.stumps[index] ? custom.stumps[index].w : null
);
export const getCustomStampWidthString = createSelector(
  getPerforationCustomGeom,
  (custom, index: number) =>
    (custom.stumps[index] && custom.stumps[index].w
      ? custom.stumps[index].w
      : 0
    ).toString()
);
export const getCustomStampLengthString = createSelector(
  getPerforationCustomGeom,
  (custom, index: number) =>
    (custom.stumps[index] && custom.stumps[index].l
      ? custom.stumps[index].l
      : 0
    ).toString()
);
export const getCustomStampRotation = createSelector(
  getPerforationCustomGeom,
  (custom, index: number) =>
    custom.stumps[index] ? custom.stumps[index].rotation : 0
);

export const getCustomOffsetX = createSelector(
  getPerforationCustomGeom,
  (custom) => custom.offsetX || 0
);
export const getCustomOffsetY = createSelector(
  getPerforationCustomGeom,
  (custom) => custom.offsetY || 0
);
export const getCustomStampOffsetX = createSelector(
  getPerforationCustomGeom,
  (custom, index: number) =>
    custom.stumps[index] ? custom.stumps[index].offsetDivX : 0
);
export const getCustomStampOffsetY = createSelector(
  getPerforationCustomGeom,
  (custom, index: number) =>
    custom.stumps[index] ? custom.stumps[index].offsetDivY : 0
);
export const getCustomStampStartX = createSelector(
  getPerforationCustomGeom,
  (custom, index: number) =>
    custom.stumps[index] ? custom.stumps[index].startX : 0
);
export const getCustomStampStartY = createSelector(
  getPerforationCustomGeom,
  (custom, index: number) =>
    custom.stumps[index] ? custom.stumps[index].startY : 0
);
export const getCustomStampEndX = createSelector(
  getPerforationCustomGeom,
  (custom, index: number) =>
    custom.stumps[index] ? custom.stumps[index].endX : 0
);
export const getCustomStampEndY = createSelector(
  getPerforationCustomGeom,
  (custom, index: number) =>
    custom.stumps[index] ? custom.stumps[index].endY : 0
);
export const getSingleToolsFormsForCustomStamp = createSelector(
  getSingleTools,
  getPerforationCustomGeom,
  (
    singleTools: SingleTool[],
    customGeom: PerforationCustomGeom,
    stampIndex: number
  ): SingleTool[] => {
    if (!customGeom.stumps[stampIndex]) {
      return;
    }
    const formType = customGeom.stumps[stampIndex].formType;
    return singleTools
      .filter((a) => a.formGroup === formType && a.defaultSize === 1)
      .sort((a, b) => a.formSort - b.formSort);
  }
);

export const getSingleToolsWidthsForCustomStamp = createSelector(
  getSingleTools,
  getPerforationCustomGeom,
  (
    singleTools: SingleTool[],
    customGeom: PerforationCustomGeom,
    stampIndex: number
  ): number[] => {
    if (!customGeom.stumps[stampIndex]) {
      return;
    }
    const form = customGeom.stumps[stampIndex].form;
    return singleTools
      .filter((a) => a.form === form)
      .filter(
        isDuplicate((a: SingleTool, b: SingleTool) => a.width === b.width)
      )
      .sort((a, b) => a.width - b.width)
      .map((st) => st.width);
  }
);

export const getSingleToolsLengthsDefinedForCustomStamp = createSelector(
  getSingleTools,
  getPerforationCustomGeom,
  (
    singleTools: SingleTool[],
    customGeom: PerforationCustomGeom,
    stampIndex: number
  ): boolean => {
    if (!customGeom.stumps[stampIndex]) {
      return;
    }
    const form = customGeom.stumps[stampIndex].form;
    const formTools = singleTools.filter((a) => a.form === form);
    return formTools.length !== 0 && formTools[0].lenght !== 0;
  }
);

export const getSingleToolsLengthsForCustomStamp = createSelector(
  getSingleTools,
  getPerforationCustomGeom,
  (
    singleTools: SingleTool[],
    customGeom: PerforationCustomGeom,
    stampIndex: number
  ): number[] => {
    if (!customGeom.stumps[stampIndex]) {
      return;
    }
    const form = customGeom.stumps[stampIndex].form;
    const width = customGeom.stumps[stampIndex].w;
    return singleTools
      .filter((a) => a.form === form && a.width === width)
      .filter(
        isDuplicate((a: SingleTool, b: SingleTool) => a.lenght === b.lenght)
      )
      .sort((a, b) => a.lenght - b.lenght)
      .map((st) => st.lenght);
  }
);

export const customPatternEditorIsClosed = createSelector(
  getMevacoPageState,
  (mevacoPage) => !mevacoPage.customPatternEditorOpened
);
export const customPatternEditorIsOpened = createSelector(
  getMevacoPageState,
  (mevacoPage) => mevacoPage.customPatternEditorOpened
);
export const perfactionEditorIsOpened = createSelector(
  getMevacoPageState,
  (mevacoPage) => mevacoPage.perfactionEditorIsOpened
);

export const showCube = createSelector(
  getStep,
  customPatternEditorIsClosed,
  (step, patternEditorIsClosed) => step === Step.design && patternEditorIsClosed
);

export const showView3dStatusBar = createSelector(
  customPatternEditorIsClosed,
  (patternEditorIsClosed) => patternEditorIsClosed
);

export const showView3dTopBar = createSelector(
  getStep,
  customPatternEditorIsClosed,
  (step, customPatternEditorClosed) =>
    step !== Step.import &&
    step !== Step.import_pattern &&
    step !== Step.import_attachment &&
    customPatternEditorClosed
);

export const selectedCustomStamp = createSelector(
  getMevacoPageState,
  (mevacoPage) => mevacoPage.selectedCustomStamp
);

export const getSelectedCustomStamp = createSelector(
  getPerforationCustomGeom,
  selectedCustomStamp,
  (customGeom, stampIndex) => {
    if (!customGeom.stumps[stampIndex]) {
      return;
    }
    return customGeom.stumps[stampIndex];
  }
);

export const getSelectedCustomStampFrom = createSelector(
  getSelectedCustomStamp,
  (stamp) => {
    if (!stamp) {
      return;
    }
    return stamp.form;
  }
);
export const getSelectedCustomStampW = createSelector(
  getSelectedCustomStamp,
  (stamp) => {
    if (!stamp) {
      return;
    }
    return stamp.w;
  }
);

export const getSelectedPerfaction = createSelector(
  getProductConfiguration,
  (prdConf) => prdConf.perfaction
);
export const getSelectedMultiSizePattern = createSelector(
  getProductConfiguration,
  (prdConf) => prdConf.multiSizePattern
);
export const getSelectedMultiSizePatternLabel = createSelector(
  getSelectedMultiSizePattern,
  (msp) => msp.label
);

export const getSelectedPerfactionParams = createSelector(
  getSelectedMultiSizePatternLabel,
  getDataSetState,
  (label, dataset) =>
    dataset ? dataset.perfactionPatterns.find((pp) => pp.label === label) : null
);
export const getMultiSizePatternPerforationPattern = createSelector(
  getSelectedPerfactionParams,
  (pp) => (pp ? pp.pattern : null)
);
export const getMultiSizePatternFormat = createSelector(
  getSelectedMultiSizePattern,
  (pp) => pp.format
);

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
export const getMultiSizePatternForms = createSelector(
  getDataSetState,
  (dataset): string[] => {
    if (!dataset) {
      return [];
    } else {
      return dataset.perfactionPatterns.map((pp) => pp.form).filter(onlyUnique);
    }
  }
);

export const getMultiSizePatternLabels = createSelector(
  getDataSetState,
  getSelectedMultiSizePattern,
  (dataset, multiSizePattern): string[] => {
    if (!dataset || !multiSizePattern.form) {
      return [];
    } else {
      return dataset.perfactionPatterns
        .filter((pp) => pp.form === multiSizePattern.form)
        .map((pp) => pp.label);
    }
  }
);

export const getSelectedMultiSizePatternHoleSizes = createSelector(
  getSelectedMultiSizePattern,
  (msp) => msp.holeSizes
);

export const getSelectedMultiSizePatternBiggestHole = createSelector(
  getSelectedMultiSizePatternHoleSizes,
  findBiggestHole
);

export function findBiggestHole(sizes: string[]): HoleSize {
  if (sizes && sizes.length > 0) {
    const holesSizes = sizes.map((s) => stringToSize(s.substr(s.indexOf(" "))));
    return holesSizes.reduce(
      (biggest, current) =>
        !!biggest &&
        (biggest.width > current.width ||
          (biggest.width === current.width && biggest.length > current.length))
          ? biggest
          : current,
      null
    );
  } else {
    return null;
  }
}

export const getMultiSizePatternSpacing = createSelector(
  getDataSetState,
  getSelectedMultiSizePatternBiggestHole,
  getSelectedPerfactionParams,
  (dataset, biggestHole, multiSizePattern): string[] => {
    if (!dataset || !biggestHole || !multiSizePattern) {
      return [];
    } else {
      const perfType = dataset.perforationTypes.find(
        (pt) => pt.name === multiSizePattern.form
      );
      const perforation = perfType.perforations.find(
        (p) => p.name === multiSizePattern.pattern
      );
      const perforationSize = perforation.sizes.find(
        (ps) =>
          ps.width === biggestHole.width && ps.length === biggestHole.length
      );
      return perforationSize.formats.map((f) => formatToString(f));
    }
  }
);

export function getPerfactionInputsNotNull(
  perfaction: SelectedPerfaction
): SelectedPerfaction | null {
  if (
    !perfaction.enabled ||
    perfaction.imageId == null ||
    perfaction.imageConfig == null
  ) {
    return null;
  }
  return perfaction;
}
export const getPerfactionInputsNotNullSelector = createSelector(
  getPerfactionInputs,
  getPerfactionInputsNotNull
);

export const getPerfactionInputsImageConfig = createSelector(
  getPerfactionInputs,
  (perfaction) => {
    return perfaction ? perfaction.imageConfig : null;
  }
);

export const getPerfactionPictureSize = createSelector(
  getPerfactionInputsImageConfig,
  (imageConfig) => {
    return imageConfig ? imageConfig.pictureSize || 10 : 10; // 10 pixels per cm by default
  }
);

export const currentElementImportId = createSelector(
  getActiveElement,
  (element) => element && element.importId
);

export const isDxfAllowedOrganizationUnit = createSelector(
  getProductConfiguration,
  (state: ProductConfiguration) => state.isDxfAllowedOrganizationUnit === "Yes"
);
export const blockUser = createSelector(
  getMevacoPageState,
  (state) => state.blockUser
);
export const isTrustedOrganizationUnit = createSelector(
  getProductConfiguration,
  (state: ProductConfiguration) => state.isTrustedOrganizationUnit === "Yes"
);
export const logoUrl = createSelector(
  getProductConfiguration,
  (state: ProductConfiguration) => {
    if (!state.configId) {
      return "";
    }
    return state.isTrustedOrganizationUnit === "Yes"
      ? "assets/MEVACO_PARTOFRMIG_quer_RGB.png"
      : environment.logo_img;
  }
);
export const getIsSingleToolMode = createSelector(
  getStateConfiguration,
  (state) => state.isSingleToolMode
);
export const getTotalPerforationHoles = createSelector(
  getStateConfiguration,
  (state) => state.totalPerforationHoles
);

export function getMultiSizePattern(
  selection: SelectedMultiSizePattern,
  dataset: Dataset
): MultiSizePattern | null {
  if (
    !dataset ||
    !selection.form ||
    !selection.holeSizes ||
    selection.holeSizes.length === 0 ||
    !selection.format
  ) {
    return null;
  }
  const perfactionPattern = dataset.perfactionPatterns.find(
    (pp) => pp.form === selection.form && pp.label === selection.label
  );
  const pattern = perfactionPattern.pattern;
  const format = stringToSpacingFormat(selection.format);
  const singleToolsIds = selection.holeSizes.map((holeSize) => {
    const size = stringToSize(holeSize.substr(holeSize.indexOf(" ")));
    const tool = dataset.singleTools.find(
      (st) =>
        st.form === selection.form &&
        st.width === size.width &&
        st.lenght === size.length
    );
    if (!tool) {
      return 0;
    } else {
      return tool.id;
    }
  });

  const result: MultiSizePattern = {
    pattern,
    geomatrixId: perfactionPattern.geomatrixId,
    singleToolsIds,
    p1: format.p1,
    p2: format.p2,
  };
  return result;
}

export const getMultiSizePatternSelector = createSelector(
  getSelectedMultiSizePattern,
  getDataSetState,
  getMultiSizePattern
);

export const getGltfs = createSelector(
  getMevacoPageState,
  (state) => state.gltfs
);

export const currentGltfFileName = createSelector(
  getProductConfiguration,
  productConfiguration => getExpandedMetalGltfHash(productConfiguration)
);

export const  getSelectedExpandedMetal = createSelector(
  getProductConfiguration,
  productConfiguration => productConfiguration.extendedMetal
);

export const  getSelectedExpandedMetalType = createSelector(
  getSelectedExpandedMetal,
  em => em.type
);

export const  getSelectedExpandedMetalSize = createSelector(
  getSelectedExpandedMetal,
  em => em.size
);

export const  getSelectedExpandedMetalSizeDecode = createSelector(
  getSelectedExpandedMetalSize,
  size => {
    const s1 = size.split('|');
    const s2 = s1[0].split(' x ');
    return {
      a: Number(s2[0]),
      b: Number(s2[1]),
      air: s1[1] ? s1[1] : null
    };
  }
);

export const getExpandedMetalPossibleTypeOfCuts = createSelector(
  getSelectedExpandedMetalType,
  getSelectedExpandedMetalSize,
  getDataSetState,
  (expMetType, size, dataset) => {
    if (!dataset || !expMetType || !size || !dataset.expandedMetals[expMetType] || !dataset.expandedMetals[expMetType][size] || !dataset.expandedMetals[expMetType][size][0] || !dataset.expandedMetals[expMetType][size][0].typesOfCut) {
      return ['D'];
    }
    return dataset.expandedMetals[expMetType][size][0].typesOfCut;
  }
);

export const  getSelectedExpandedMetalSizeA = createSelector(
  getSelectedExpandedMetalSizeDecode,
  d => d.a
);
export const  getSelectedExpandedMetalSizeB = createSelector(
  getSelectedExpandedMetalSizeDecode,
  d => d.b
);
export const  getSelectedExpandedMetalSizeAir = createSelector(
  getSelectedExpandedMetalSizeDecode,
  d => d.air
);

export const  getSelectedExpandedMetalFeedrate = createSelector(
  getSelectedExpandedMetal,
  em => em.feedrate
);
export const  getSelectedExpandedMetalTypeOfCut = createSelector(
  getSelectedExpandedMetal,
  em => em.typeOfCut
);
export const  getSelectedExpandedMetalOpenMesh = createSelector(
  getSelectedExpandedMetal,
  em => em.openMesh
);

export const getCurrentPosition = createSelector(
  getProductConfiguration,
  state => state.currentPosition
);

export * from "./mevaco-page.reducer";
export * from "./router.reducer";

function isDuplicate<T>(
  comapre: (e1: T, e2: T) => boolean
): (a: T, pos: number, array: T[]) => boolean {
  return function (a: T, pos: number, array: T[]): boolean {
    return array.findIndex((b) => comapre(a, b)) === pos;
  };
}
