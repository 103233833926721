import { Store, select } from "@ngrx/store";
import { PointerState } from "webcad/collision";
import {
  CircleCircleIntersect2D,
  SinOfAngleBetween2Vectors2D,
  Vector3,
  addVectors3,
  crossVector2,
  distanceVector2,
  getRightPerpendicularVector2,
  isPointBetween,
  lengthVector2,
  multiplyVector3byScalar,
  normalizeVector2,
  normalizeVector3,
  sqrLengthVector2,
  subVectors2,
  subVectors3,
} from "webcad/math";
import {
  AngleModelManager,
  MeasurementModelManager,
  MeasurementsManager,
} from "webcad/measurements";
import { Segment, SegmentType, getPointsFromSegment } from "webcad/models";
import { ActionType, PointNode } from "../../model";
import { MeasurementsManagerProvider } from "../../providers/measurements-manager.provider";
import { ClosestSegments } from "../../providers/mevaco-pointer.provider";
import { SceneProvider } from "../../providers/scene.provider";
import { ToolProvider } from "../../providers/tool.provider";
import { TranslationProvider } from "../../providers/translation.provider";
import { WebcadProvider } from "../../providers/webcad.provider";
import { AddShapeToAdd, AddShapeToRemove } from "../../store/actions";
import {
  RequestRender,
  SetHintMessage,
} from "../../store/actions/drawing.actions";
import {
  MevacoState,
  getCurrentActionType,
  getNodesState,
} from "../../store/reducers";
import { createLineWithDepthOffset } from "../../visualizers/line-system";
import { DrawingTool } from "../drawing-tool.interface";
import { updateXYMeasurements } from "../utils";
import {LinesMesh, Scene, Vector3 as B_Vector3, Color4 as B_Color4} from "@babylonjs/core";
export enum BowToolStep {
  SET_BEGIN,
  SET_END,
  SET_BIAS,
}

export class BowTool extends DrawingTool {
  mode: ActionType;
  private closestSegments: ClosestSegments;
  private begin: Vector3;
  private end: Vector3;
  private bias: Vector3;
  private scene: Scene;
  private measurementsManager: MeasurementsManager;
  private toolStep: BowToolStep;
  private isDragging: boolean;
  private segment: Segment;
  private currentMesh: LinesMesh;
  private nodes: PointNode[];
  private verticalMeasurement: MeasurementModelManager;
  private horizontalMeasurement: MeasurementModelManager;
  private biasMeasurement: MeasurementModelManager;
  private point: Vector3;
  private angleMeasurement: AngleModelManager;

  constructor(
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private webcadProvider: WebcadProvider,
    private measurementManagerProvider: MeasurementsManagerProvider,
    private toolProvider: ToolProvider,
    private translationProvider: TranslationProvider
  ) {
    super();
    this.sceneProvider.getSubscription().subscribe((v) => {
      this.scene = v;
    });
    this.measurementManagerProvider.getSubsciption().subscribe((v) => {
      this.measurementsManager = v;
    });
    this.store
      .pipe(select(getCurrentActionType))
      .subscribe((v) => (this.mode = v));
    this.store.pipe(select(getNodesState)).subscribe((v) => (this.nodes = v));
    this.toolStep = BowToolStep.SET_BEGIN;
  }

  activate() {
    this.store.dispatch(new SetHintMessage(this.translate("startBow"))); //Click left mouse button to place the center of the Bow.
    this.toolStep = BowToolStep.SET_BEGIN;
  }

  activateMeasurements() {
    if (this.toolStep === BowToolStep.SET_END) {
      if (!this.angleMeasurement) {
        this.angleMeasurement =
          this.measurementsManager.getAngleMeasurementModel();
        this.angleMeasurement.setInputCallbacks(
          (value: number) => {
            this.setSegmentAngle(value);
            return value;
          },
          (value: number) => {
            this.setSegmentAngle(value);
            this.applyCurrentSegment();
            return value;
          }
        );
      }
    }
    if (
      this.toolStep === BowToolStep.SET_BEGIN ||
      this.toolStep === BowToolStep.SET_END
    ) {
      if (!this.verticalMeasurement) {
        this.verticalMeasurement =
          this.measurementsManager.getMeasurementModel();
        this.verticalMeasurement.setInputCallbacks(
          (value: number) => {
            this.setVertical(value);
            return value;
          },
          (value: number) => {
            this.setVertical(value);
            this.applyCurrentSegment();
            return value;
          }
        );
      }
      if (!this.horizontalMeasurement) {
        this.horizontalMeasurement =
          this.measurementsManager.getMeasurementModel();
        this.horizontalMeasurement.setInputCallbacks(
          (value: number) => {
            this.setHorizontal(value);
            return value;
          },
          (value: number) => {
            this.setHorizontal(value);
            this.applyCurrentSegment();
            return value;
          }
        );
      }
    } else if (this.toolStep === BowToolStep.SET_BIAS) {
      if (!this.biasMeasurement) {
        this.biasMeasurement = this.measurementsManager.getMeasurementModel();
        this.biasMeasurement.setInputCallbacks(
          (value: number) => {
            this.setBias(value);
            return value;
          },
          (value: number) => {
            this.setBias(value);
            this.applyCurrentSegment();
            return value;
          }
        );
      }
    }
  }

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {
    this.closestSegments = closestSegments;
    if (this.point) {
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    }
  }

  onMouseClick(pointerState: PointerState) {
    this.point = pointerState.position;
    this.applyCurrentSegment();
  }

  applyCurrentSegment() {
    switch (this.toolStep) {
      case BowToolStep.SET_BEGIN:
        this.begin = this.point;
        this.toolStep = BowToolStep.SET_END;
        this._dirty = true;
        this.store.dispatch(new SetHintMessage(this.translate("endBow")));
        break;
      case BowToolStep.SET_END:
        this.end = this.point;
        this.bias = {
          x: (this.begin.x + this.end.x) / 2,
          y: (this.begin.y + this.end.y) / 2,
          z: this.begin.z,
        };
        this.calculateSegment();
        this.drawSegment();
        this.toolStep = BowToolStep.SET_BIAS;
        this.disposeMeasurements();
        this.store.dispatch(new SetHintMessage(this.translate("setBiasBow")));
        break;
      case BowToolStep.SET_BIAS:
        this.onConfirm();
        break;
    }
  }

  confirmSegment() {
    if (isPointBetween(this.begin, this.end, this.bias)) {
      return;
    }
    const polyline: Segment[] = [
      {
        type: SegmentType.arc,
        begin: this.begin,
        end: this.end,
        beginAngle: this.segment.beginAngle,
        endAngle: this.segment.endAngle,
        origin: this.segment.origin,
        radius: this.segment.radius,
      },
      {
        type: SegmentType.line,
        begin: this.end,
        end: this.begin,
      },
    ];
    this.store.dispatch(
      this.mode === ActionType.ADD
        ? new AddShapeToAdd(polyline)
        : new AddShapeToRemove(polyline)
    );
    this.reset();
  }

  onMouseDown(pointerState: PointerState) {
    if (this.toolStep === BowToolStep.SET_BIAS) {
      this.isDragging = true;
    }
  }

  onMouseMove(pointerState: PointerState) {
    this.point = pointerState.position;
    this.activateMeasurements();
    if (this.toolStep === BowToolStep.SET_BEGIN) {
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        pointerState.position
      );
    } else if (this.toolStep === BowToolStep.SET_END) {
      this.end = this.point;
      this.bias = {
        x: (this.begin.x + this.end.x) / 2,
        y: (this.begin.y + this.end.y) / 2,
        z: this.begin.z,
      };
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        pointerState.position
      );
      this.angleMeasurement.updateMeasurement(this.begin, this.end);
      this.calculateSegment();
      this.drawSegment();
    } else if (this.toolStep === BowToolStep.SET_BIAS) {
      // if (this.isDragging) {
      this.bias = this.point;
      this.calculateSegment();
      this.updateBiasMeasurement();
      this.drawSegment();
      // }
    }
  }

  onMouseUp(pointerState: PointerState) {
    if (this.toolStep === BowToolStep.SET_BIAS) {
      this.isDragging = false;
    }
  }

  reset() {
    this.onCancel();
    this.activate();
  }

  disposeMeasurements() {
    if (this.horizontalMeasurement) {
      this.horizontalMeasurement.disposeModel();
      this.horizontalMeasurement = null;
    }
    if (this.verticalMeasurement) {
      this.verticalMeasurement.disposeModel();
      this.verticalMeasurement = null;
    }
    if (this.biasMeasurement) {
      this.biasMeasurement.disposeModel();
      this.biasMeasurement = null;
    }
    if (this.angleMeasurement) {
      this.angleMeasurement.disposeModel();
      this.angleMeasurement = null;
    }
  }

  private updateXYMeasurements(
    XMeasurement: MeasurementModelManager,
    YMeasurement: MeasurementModelManager,
    point: Vector3
  ) {
    updateXYMeasurements(
      XMeasurement,
      YMeasurement,
      point,
      this.closestSegments
    );
  }

  private updateClosestSegments(point: Vector3) {
    this.updateClosestHorizontalSegment(point);
    this.updateClosestVerticalSegment(point);
  }

  private updateClosestVerticalSegment(point: Vector3) {
    this.closestSegments.verticalSegment =
      this.toolProvider.getNearestVerticalSegmentToPoint(point);
  }

  private updateClosestHorizontalSegment(point: Vector3) {
    this.closestSegments.horizontalSegment =
      this.toolProvider.getNearestHorizontalSegmentToPoint(point);
  }

  setVertical(value: number): void {
    if (this.toolStep === BowToolStep.SET_BEGIN) {
      const point = this.verticalMeasurement.getStart();
      const wall = this.verticalMeasurement.getEnd();
      const moveDir = normalizeVector3(subVectors3(point, wall));
      const newPoint = addVectors3(
        wall,
        multiplyVector3byScalar(moveDir, value)
      );
      this.point = { x: newPoint.x, y: newPoint.y, z: newPoint.z };
      this.begin = this.point;
      this.updateClosestVerticalSegment(this.point);
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    } else if (this.toolStep === BowToolStep.SET_END) {
      const wall = this.verticalMeasurement.getEnd();
      const moveDir = normalizeVector3(subVectors3(this.point, wall));
      const newPoint = addVectors3(
        wall,
        multiplyVector3byScalar(moveDir, value)
      );
      this.point = { x: newPoint.x, y: newPoint.y, z: newPoint.z };
      this.end = this.point;
      this.bias = {
        x: (this.begin.x + this.end.x) / 2,
        y: (this.begin.y + this.end.y) / 2,
        z: this.begin.z,
      };
      this.calculateSegment();
      this.updateClosestVerticalSegment(this.point);
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
      this.drawSegment();
    }
  }

  setHorizontal(value: number): void {
    if (this.toolStep === BowToolStep.SET_BEGIN) {
      const point = this.horizontalMeasurement.getStart();
      const wall = this.horizontalMeasurement.getEnd();
      const moveDir = normalizeVector3(subVectors3(point, wall));
      const newPoint = addVectors3(
        wall,
        multiplyVector3byScalar(moveDir, value)
      );
      this.point = { x: newPoint.x, y: newPoint.y, z: newPoint.z };
      this.begin = this.point;
      this.updateClosestHorizontalSegment(this.point);
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    } else if (this.toolStep === BowToolStep.SET_END) {
      const wall = this.horizontalMeasurement.getEnd();
      const moveDir = normalizeVector3(subVectors3(this.point, wall));
      const newPoint = addVectors3(
        wall,
        multiplyVector3byScalar(moveDir, value)
      );
      this.point = { x: newPoint.x, y: newPoint.y, z: newPoint.z };
      this.end = this.point;
      this.bias = {
        x: (this.begin.x + this.end.x) / 2,
        y: (this.begin.y + this.end.y) / 2,
        z: this.begin.z,
      };
      this.calculateSegment();
      this.updateClosestHorizontalSegment(this.point);
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
      this.drawSegment();
    }
  }

  setBias(value: number): void {
    if (this.toolStep === BowToolStep.SET_BIAS) {
      const point = this.biasMeasurement.getStart();
      const wall = this.biasMeasurement.getEnd();
      const moveDir = normalizeVector3(subVectors3(point, wall));
      const newPoint = addVectors3(
        wall,
        multiplyVector3byScalar(moveDir, value)
      );
      this.point = { x: newPoint.x, y: newPoint.y, z: newPoint.z };
      this.bias = this.point;
      this.calculateSegment();
      this.updateBiasMeasurement();
      this.drawSegment();
    }
  }

  setSegmentAngle(value: number): void {
    if (this.currentMesh) {
      if (this.point && this.begin) {
        const segmentDir = subVectors2(this.point, this.begin);
        const l = lengthVector2(segmentDir);
        const s = this.begin;
        const dir: Vector3 = { x: Math.cos(value), y: Math.sin(value), z: 0 };
        const end: Vector3 = addVectors3(s, multiplyVector3byScalar(dir, l));
        this.point = end;
        this.end = this.point;
        this.bias = {
          x: (this.begin.x + this.end.x) / 2,
          y: (this.begin.y + this.end.y) / 2,
          z: this.begin.z,
        };
        this.calculateSegment();
        this.updateClosestSegments(this.point);
        this.updateXYMeasurements(
          this.horizontalMeasurement,
          this.verticalMeasurement,
          this.point
        );
        this.drawSegment();
      }
    }
  }

  updateBiasMeasurement(): void {
    const end: Vector3 = {
      x: (this.begin.x + this.end.x) * 0.5,
      y: (this.begin.y + this.end.y) * 0.5,
      z: this.begin.z,
    };
    const biasNodeAngle =
      (this.segment.endAngle + this.segment.beginAngle) * 0.5;
    const biasPosition = {
      x: this.segment.origin.x + this.segment.radius * Math.cos(biasNodeAngle),
      y: this.segment.origin.y + this.segment.radius * Math.sin(biasNodeAngle),
    };
    const begin = { x: biasPosition.x, y: biasPosition.y, z: end.z };
    let measurementDir = subVectors2(end, begin);
    if (sqrLengthVector2(measurementDir) === 0) {
      measurementDir = this.biasMeasurement.getModel().measurementDirection;
    }
    const dir = normalizeVector2(getRightPerpendicularVector2(measurementDir));
    this.biasMeasurement.updateMeasurement(
      begin,
      end,
      { x: dir.x, y: dir.y, z: begin.z },
      undefined,
      true
    );
  }

  private drawSegment(): void {
    if (this.currentMesh) {
      this.currentMesh.dispose();
      this.currentMesh = null;
    }
    const points = getPointsFromSegment(this.segment, true);
    const positions = points.map(
      (v) => new B_Vector3(v.x, v.y, this.begin.z)
    );
    const color = new B_Color4(1, 0, 0, 1);
    const colors = [];
    for (const p of positions) {
      colors.push(color);
    }
    this.currentMesh = createLineWithDepthOffset(
      "bow",
      { points: positions, colors: colors },
      this.scene,
      -0.0006
    );
    this.store.dispatch(new RequestRender());
  }

  private calculateSegment(): void {
    const newBias = this.bias;
    const vecToBeginFromNewBias = subVectors2(this.begin, newBias);
    const vecToEndFromNewBias = subVectors2(this.end, newBias);
    let sinAngle = SinOfAngleBetween2Vectors2D(
      vecToBeginFromNewBias,
      vecToEndFromNewBias
    );
    if (sinAngle < 0) {
      sinAngle = SinOfAngleBetween2Vectors2D(
        vecToEndFromNewBias,
        vecToBeginFromNewBias
      );
    }
    const counterSide = lengthVector2(subVectors2(this.end, this.begin));
    const newRadius = counterSide / (2 * sinAngle);
    const intersections = CircleCircleIntersect2D(
      this.begin.x,
      this.begin.y,
      newRadius,
      this.end.x,
      this.end.y,
      newRadius
    );
    if (
      intersections.length !== 2 ||
      Math.round(sinAngle * 1000) * 0.0001 === 0
    ) {
      if (intersections.length === 0) {
        this.segment = {
          begin: this.begin,
          end: this.end,
          type: SegmentType.arc,
          origin: {
            x: (this.begin.x + this.end.x) * 0.5,
            y: (this.begin.y + this.end.y) * 0.5,
          },
          radius: 0,
          beginAngle: 0,
          endAngle: 0,
        };
      } else {
        this.segment = {
          begin: this.begin,
          end: this.end,
          type: SegmentType.arc,
          origin: intersections[0],
          radius: 0,
          beginAngle: 0,
          endAngle: 0,
        };
      }
      return;
    }
    const inter0dist = Math.abs(
      distanceVector2(intersections[0], newBias) - newRadius
    );
    const inter1dist = Math.abs(
      distanceVector2(intersections[1], newBias) - newRadius
    );
    const newOrigin =
      inter0dist < inter1dist ? intersections[0] : intersections[1];
    const originToBegin = subVectors2(this.begin, newOrigin);
    const originToEnd = subVectors2(this.end, newOrigin);
    const beginAngle = Math.atan2(originToBegin.y, originToBegin.x);
    let endAngle = Math.atan2(originToEnd.y, originToEnd.x);
    const originToBias = subVectors2(newOrigin, this.begin);
    const biasAngle = Math.atan2(originToBias.y, originToBias.x);
    const beginToEnd = subVectors2(this.end, this.begin);
    const beginToBias = subVectors2(newBias, this.begin);
    const side = crossVector2(beginToEnd, beginToBias);
    if (endAngle < beginAngle) {
      endAngle += Math.PI * 2;
    }
    if (side > 0) {
      endAngle -= Math.PI * 2;
    }
    this.segment = {
      begin: this.begin,
      end: this.end,
      type: SegmentType.arc,
      origin: newOrigin,
      radius: newRadius,
      beginAngle: beginAngle,
      endAngle: endAngle,
    };
  }

  onCancel() {
    this.toolStep = BowToolStep.SET_BEGIN;
    if (this.currentMesh) {
      this.currentMesh.dispose();
      this.currentMesh = null;
    }
    this.begin = null;
    this.end = null;
    this.disposeMeasurements();
    this._dirty = false;
  }

  onConfirm() {
    // if (!this.isDragging) {
    this.confirmSegment();
    //  }
  }

  isDirty() {
    return this._dirty;
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }
}
