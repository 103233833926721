import {Drawing, initialDrawing} from './drawing.model';
import {initialProductConfiguration, ProductConfiguration} from './product-configuration/product-configuration.model';
import {Dataset} from './dataset/dataset.model';
import {RuleActions} from './rule-action.model';
import {Mounting} from './mounting.model';
import {UserTemplate} from './user-template.model';
import {ConfigurableTemplates, initialConfigurableTemplates} from './basic-templates.model';
import {initialMessage, Message} from './error.model';
import {AppMode} from './app-mode.enum';
import {initialToleranceLookup, ToleranceLookup} from './tolerance-calculator';
import {PerforationModel} from './perforation.model';
import {UserPattern} from "./user-pattern.model";
import { RightPanel, SubpanelCurrentState } from './right-panel.model';
import { Units } from './units';
import { Mesh } from '@babylonjs/core';


export interface MevacoPage {
  drawing: Drawing;
  productConfiguration: ProductConfiguration;
  rulesActions: RuleActions;
  dataset: Dataset | null;
  mountings: Mounting[];
  userTemplates: UserTemplate[];
  userPatterns: UserPattern[];
  message: Message;
  configurableTemplates: ConfigurableTemplates;
  translations: {[module: string]: {[identifier: string]: string}};
  mode: AppMode;
  positionsToReload: number[];
  lastPerforationResponse: PerforationModel;
  toleranceLookup: ToleranceLookup;
  customPatternEditorOpened: boolean;
  perfactionEditorIsOpened: boolean;
  selectedCustomStamp: number;
  selectedUnit: Units;
  rightPanel: RightPanel;
  blockUser: boolean;
  gltfs: {[key: string]: boolean};
}

export const initialMevacoPage: MevacoPage = {
  drawing: initialDrawing,
  lastPerforationResponse: {perforation: []},
  productConfiguration: initialProductConfiguration,
  rulesActions: {
    general: [],
    elements: {}
  },
  mountings: [],
  dataset: null,
  message: initialMessage,
  userTemplates: [],
  userPatterns: [],
  configurableTemplates: initialConfigurableTemplates,
  translations: null,
  mode: null,
  positionsToReload: [],
  toleranceLookup: initialToleranceLookup,
  customPatternEditorOpened: false,
  perfactionEditorIsOpened: false,
  selectedCustomStamp: 0,
  selectedUnit: null,
  rightPanel: {
    configuratorPanel: {
      position: {
        top: 0,
        right: 0
      },
      width: 320,
      order: 1,
      state: SubpanelCurrentState.OPENED
    },
    messageVisualizerPanel: {
      position: {
        top: 0,
        right: -320
      },
      width: 320,
      order: 3,
      state: SubpanelCurrentState.CLOSED
    },
    effectsPanel: {
      position: {
        top: 0,
        right: -350
      },
      width: 350,
      order: 2,
      state: SubpanelCurrentState.CLOSED
    },
    fadingPanel: {
      position: {
        top: 0,
        right: -350
      },
      width: 350,
      order: 2,
      state: SubpanelCurrentState.CLOSED
    }
  },
  blockUser: false,
  gltfs: {}
};
