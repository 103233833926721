import { Webcad } from 'webcad/core';
import { PolylineToVector2Array, Segment } from 'webcad/models';
import { ModelVisualizer } from 'webcad/visualizers';
import { ToolModel } from '../model/tool';
import { PerforationModeToolModel } from '../model/tool/perforation-mode-tool.model';
import { createLineSystemWithDepthOffset } from './line-system';
import {Color3, Mesh, Node, Scene, Vector3} from '@babylonjs/core';

export class ToolVisualizer implements ModelVisualizer<ToolModel> {
  private wallpaper: Mesh = null;
  private model: ToolModel = null;
  private webcad: Webcad = null;

  dispose(): void {
    this.disposeWallpaperMoveTool();
  }

  init(
    rootNode: Node,
    model: ToolModel,
    webcad: Webcad
  ): Promise<void> {
    this.webcad = webcad;
    this.updateVisualization(model);
    return Promise.resolve();
  }

  updateVisualization(newModel: ToolModel): void {
    if (newModel && newModel.name === 'PerforationModeToolModel') {
      this.updateWallpaperMoveTool(newModel as PerforationModeToolModel);
    } else {
      this.disposeWallpaperMoveTool();
    }
    this.model = newModel;
  }

  private disposeWallpaperMoveTool() {
    if (this.wallpaper) {
      this.wallpaper.dispose();
      this.wallpaper = null;
    }
  }

  private updateWallpaperMoveTool(newModel: PerforationModeToolModel) {
    if (
      !this.model ||
      this.model.name !== 'PerforationModeToolModel' ||
      (this.model as PerforationModeToolModel).wallpaperShape !==
        newModel.wallpaperShape
    ) {
      this.disposeWallpaperMoveTool();
      if (newModel.wallpaperShape && newModel.wallpaperShape.length > 0) {
        this.wallpaper = createWallpaperMesh(
          newModel.wallpaperShape,
          this.webcad.scene
        );
      }
    }
    if (this.wallpaper) {
      this.wallpaper.position = new Vector3(
        newModel.offset.x / 1000,
        newModel.offset.y / 1000,
        0
      );
      this.wallpaper.scaling = new Vector3(
        newModel.zoom,
        newModel.zoom,
        1
      );
    }
  }
}

function createWallpaperMesh(
  shape: Segment[][],
  scene: Scene
): Mesh {
  const lines: Vector3[][] = [];
  for (let polylineIndex = 0; polylineIndex < shape.length; polylineIndex++) {
    const points = PolylineToVector2Array(shape[polylineIndex]);
    points.push(points[0]);
    lines.push(points.map((p) => new Vector3(p.x, p.y, 0.0)));
  }

  const mesh = createLineSystemWithDepthOffset(
    'perforation Outline',
    { lines: lines },
    scene,
    -0.0004
  );
  mesh.color = new Color3(0.5, 0.5, 0.05);
  return mesh;
}
