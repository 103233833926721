import { Webcad } from "webcad/core";
import { PolylineToVector2Array, Segment } from "webcad/models";
import { ModelVisualizer } from "webcad/visualizers";
import { PolylineViewModel } from "../model/view-model/polylines.viewModel";
import { createLineSystemWithDepthOffset } from "./line-system";
import {Color4, Mesh, Node, Scene, Vector3} from "@babylonjs/core";

export class PolylineVisualizer implements ModelVisualizer<PolylineViewModel> {
  private mesh: Mesh;
  private model: PolylineViewModel;
  private scene: Scene;
  dispose(): void {}

  init(
    rootNode: Node,
    model: PolylineViewModel,
    webcad: Webcad
  ): Promise<void> {
    this.scene = rootNode.getScene();
    if (model && model.polyline) {
      this.mesh = createPolylineMesh(model.polyline, model.color, this.scene);
    }
    this.model = model;
    return Promise.resolve();
  }

  updateVisualization(newModel: PolylineViewModel): void {
    if (
      !newModel ||
      newModel.polyline !== this.model.polyline ||
      newModel.color !== this.model.color
    ) {
      if (this.mesh) {
        this.mesh.dispose();
        this.mesh = null;
      }
      if (newModel && newModel.polyline) {
        this.mesh = createPolylineMesh(
          newModel.polyline,
          newModel.color,
          this.scene
        );
      }
    }
  }
}

function createPolylineMesh(
  shape: Segment[][],
  colorHex: string,
  scene: Scene
): Mesh {
  const lines: Vector3[][] = [];
  const colors: Color4[][] = [];
  const color = Color4.FromHexString(colorHex);
  for (let polylineIndex = 0; polylineIndex < shape.length; polylineIndex++) {
    const points = PolylineToVector2Array(shape[polylineIndex]);
    if (points.length === 0) {
      return null;
    }
    points.push(points[0]);
    lines.push(points.map((p) => new Vector3(p.x, p.y, 0.0)));
    colors.push(new Array(points.length).fill(color));
  }

  const mesh = createLineSystemWithDepthOffset(
    "polyline",
    { lines, colors },
    scene,
    -0.0004
  );
  return mesh;
}
