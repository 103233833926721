import { select, Store } from "@ngrx/store";
import { PointerState } from "webcad/collision";
import {
  clamp,
  dotVector2,
  normalizeVector2,
  subVectors3,
  Vector3,
} from "webcad/math";
import { AngleModelManager, MeasurementsManager } from "webcad/measurements";
import {
  Aabb2,
  aabbOfPolyline,
  getPointsFromSegment,
  rotatePolyline,
  Segment,
} from "webcad/models";
import { MeasurementsManagerProvider } from "../../providers/measurements-manager.provider";
import { ClosestSegments } from "../../providers/mevaco-pointer.provider";
import { SceneProvider } from "../../providers/scene.provider";
import { RotatePolyline } from "../../store/actions";
import { getSelectedSegments, MevacoState } from "../../store/reducers";
import { createLineSystemWithDepthOffset } from "../../visualizers/line-system";
import { DifferentiationTool } from "../differentiation-tool.interface";
import {Color4, LinesMesh, Scene, Vector3 as B_Vector3} from "@babylonjs/core";
export class RotationTool extends DifferentiationTool {
  private hightlightedPolyline: LinesMesh;
  private scene: Scene;
  private selectedSegments: Segment[];
  private measurementsManager: MeasurementsManager;
  private angleMeasurement: AngleModelManager;
  private selectedSegmentsAabb: Aabb2;
  private rotationOrigin: Vector3;
  private mouseDown = false;
  private rotation: number;
  private pointer: Vector3;

  constructor(
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private measurementsManagerProvider: MeasurementsManagerProvider
  ) {
    super();
    this.sceneProvider.getSubscription().subscribe((v) => {
      this.scene = v;
    });
    this.store.pipe(select(getSelectedSegments)).subscribe((v) => {
      this.selectedSegments = v;
      this.selectedSegmentsAabb = aabbOfPolyline(v);
      this.rotationOrigin = {
        x:
          (this.selectedSegmentsAabb.max.x + this.selectedSegmentsAabb.min.x) /
          2,
        y:
          (this.selectedSegmentsAabb.max.y + this.selectedSegmentsAabb.min.y) /
          2,
        z: 0,
      };
    });
    this.measurementsManagerProvider.getSubsciption().subscribe((v) => {
      this.measurementsManager = v;
    });
  }

  activate() {
    if (this.measurementsManager) {
      this.angleMeasurement =
        this.measurementsManager.getAngleMeasurementModel();
      this.angleMeasurement.updateMeasurementWithAngle(this.rotationOrigin, 0, {
        x: 1,
        y: 0,
        z: 0,
      });
      this.angleMeasurement.setInputCallbacks(
        (v) => {
          return v;
        },
        (v) => {
          return v;
        }
      );
    }
  }

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {}

  onMouseClick(pointerState: PointerState) {}

  onMouseDown(pointerState: PointerState) {
    this.mouseDown = true;
  }

  onMouseMove(pointerState: PointerState) {
    this.pointer = pointerState.position;
    if (this.mouseDown) {
      if (this.selectedSegments.length > 0 && this.rotationOrigin) {
        this.calculateNewRotation();
        const rotatedPolyline = rotatePolyline(
          this.selectedSegments,
          this.rotation,
          this.rotationOrigin
        );
        this.visualizePolyline(rotatedPolyline);
        this.angleMeasurement.updateMeasurementWithAngle(
          this.rotationOrigin,
          this.rotation,
          { x: 1, y: 0, z: 0 }
        );
        this._dirty = true;
      }
    }
  }

  onMouseUp(pointerState: PointerState) {
    this.mouseDown = false;
  }

  reset() {
    this.onCancel();
    this.activate();
  }

  onCancel() {
    if (this.hightlightedPolyline) {
      this.hightlightedPolyline.dispose();
      this.hightlightedPolyline = null;
    }
    if (this.angleMeasurement) {
      this.angleMeasurement.disposeModel();
      this.angleMeasurement = null;
    }
    this._dirty = false;
  }

  onConfirm() {
    this.store.dispatch(
      new RotatePolyline({
        point: this.rotationOrigin,
        rotation: this.rotation,
        polyline: this.selectedSegments,
      })
    );
  }

  calculateNewRotation() {
    const dir = subVectors3(this.pointer, this.rotationOrigin);
    this.rotation = Math.acos(
      clamp(dotVector2(normalizeVector2(dir), { x: 1, y: 0 }), -1, 1)
    );
  }

  visualizePolyline(polyline: Segment[]) {
    if (this.hightlightedPolyline) {
      this.hightlightedPolyline.dispose();
      this.hightlightedPolyline = null;
    }
    const outlinePoints: B_Vector3[][] = [];
    const colors: Color4[][] = [];
    const color = new Color4(0, 1, 0, 1);
    const outline: B_Vector3[] = [];
    const outlineColor: Color4[] = [];
    for (const s of polyline) {
      const points = getPointsFromSegment(s);
      for (const point of points) {
        outline.push(new B_Vector3(point.x, point.y, 0));
        outlineColor.push(color);
      }
    }
    outline.push(outline[0]);
    outlineColor.push(outlineColor[0]);
    outlinePoints.push(outline);
    colors.push(outlineColor);
    this.hightlightedPolyline = createLineSystemWithDepthOffset(
      "toRotateHighlight",
      {
        lines: outlinePoints,
        colors: colors,
      },
      this.scene,
      -0.0006
    );
    this.hightlightedPolyline.isPickable = false;
  }

  onMouseClickOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnMounting(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnNode(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnSegment(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnMounting(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnNode(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnSegment(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnMounting(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnNode(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnSegment(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnMounting(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnNode(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnSegment(pointerPosition: Vector3, ctrl?: boolean) {}

  isDirty() {
    return this._dirty;
  }

  onMouseClickOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {}
}
