import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import {
  addVectors2,
  crossVector2,
  distanceVector2,
  getPointsFromSegment,
  getRightPerpendicularVector2,
  MeasurementModelManager,
  MeasurementsManager,
  normalizeVector2,
  Segment,
  SegmentType,
  subVectors2,
  Vector2,
  Vector3,
  Webcad,
} from "webcad";
import { PointerState } from "webcad/collision";
import {
  addVectors3,
  CircleCircleIntersect2D,
  multiplyVector3byScalar,
  normalizeVector3,
  subVectors3,
} from "webcad/math";
import { AngleModelManager } from "webcad/measurements";
import { ShapeOrigin } from "../../../model/drawing.model";
import { LineShape } from "../../../model/line-shape";
import { LineType } from "../../../model/line-type.model";
import { Plate } from "../../../model/plate.model";
import {
  createNode,
  createTempNode,
  PointNode,
} from "../../../model/point-node.model";
import { ActionType } from "../../../model/shape-action.model";
import { MeasurementsManagerProvider } from "../../../providers/measurements-manager.provider";
import {
  checkForClosestNode,
  ClosestSegments,
} from "../../../providers/mevaco-pointer.provider";
import { SceneProvider } from "../../../providers/scene.provider";
import { ToolProvider } from "../../../providers/tool.provider";
import { TranslationProvider } from "../../../providers/translation.provider";
import { WebcadProvider } from "../../../providers/webcad.provider";
import { getPlate, MevacoState } from "../../../store";
import {
  RequestRender,
  SetHintMessage,
} from "../../../store/actions/drawing.actions";
import { AddNode } from "../../../store/actions/node.actions";
import { Curve } from "../../../utils/Curve";
import { checkLineIntersection } from "../../../utils/math.utils";
import { createLineWithDepthOffset } from "../../../visualizers/line-system";
import { DrawingTool } from "../../drawing-tool.interface";
import { getNewEndPosition, updateXYMeasurements } from "../../utils";
import {LinesMesh, Scene, Node, Vector3 as B_Vector3, Color4 as B_Color4} from "@babylonjs/core";
export class PolylineTool extends DrawingTool {
  public mode: ActionType = ActionType.REMOVE;
  private beginNode: PointNode;
  private endNode: PointNode;
  private currentMesh: LinesMesh;
  private nodes: PointNode[];
  private nodesArray: PointNode[];
  private currentDrawnShape: LineShape;
  private previousLineDirection: Vector2;
  private perpendicularToPreviousLineDirection: Vector2;
  private perpendicularDirection: Vector2;
  private origin: Vector2;
  private radius: number;
  private startAngle: number;
  private endAngle: number;
  private aClockwise: boolean;
  private drawingMode: LineType;
  private angle: number;
  private measurmentModel: MeasurementModelManager;
  private rootNode: Node;
  private point: Vector3;
  private maxLength: number;
  private scene: Scene;
  private webcad: Webcad;
  private measurementManager: MeasurementsManager;
  private verticalMeasurement: MeasurementModelManager;
  private horizontalMeasurement: MeasurementModelManager;
  private maxWidth: number;
  private maxHeight: number;
  private closestSegments: ClosestSegments;
  private onSegmentDone: Subject<Segment> = new Subject<Segment>();
  private angleMeasurement: AngleModelManager;

  constructor(
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private webcadProvider: WebcadProvider,
    private measurementManagerProvider: MeasurementsManagerProvider,
    private toolProvider: ToolProvider,
    private translationProvider: TranslationProvider
  ) {
    super();
    this.sceneProvider.getSubscription().subscribe((value) => {
      this.scene = value;
      if (this.scene) {
        this.rootNode = new Node("measurmentNode", this.scene);
      }
    });
    this.webcadProvider.getObservable().subscribe((value) => {
      this.webcad = value;
    });
    this.measurementManagerProvider.getSubsciption().subscribe((value) => {
      if (value) {
        this.measurementManager = value;
      }
    });
    this.init();
  }

  public segmentDoneObservable(): Observable<Segment> {
    return this.onSegmentDone.asObservable();
  }

  activate() {
    this.store.dispatch(
      new SetHintMessage(this.translationProvider.translate("startLineTool"))
    );
    if (this.measurementManager) {
      if (!this.measurmentModel) {
        this.measurmentModel = this.measurementManager.getMeasurementModel();
        this.measurmentModel.setInputCallbacks(
          (value: number) => {
            this.setSegmentLength(value);
            return value;
          },
          (value: number) => {
            this.setSegmentLength(value);
            this.applyCurrentSegment();
            return value;
          }
        );
      }
      if (!this.verticalMeasurement) {
        this.verticalMeasurement =
          this.measurementManager.getMeasurementModel();
        this.verticalMeasurement.setInputCallbacks(
          (value: number) => {
            this.setVertical(value);
            return value;
          },
          (value: number) => {
            this.setVertical(value);
            this.applyCurrentSegment();
            return value;
          }
        );
      }
      if (!this.horizontalMeasurement) {
        this.horizontalMeasurement =
          this.measurementManager.getMeasurementModel();
        this.horizontalMeasurement.setInputCallbacks(
          (value: number) => {
            this.setHorizontal(value);
            return value;
          },
          (value: number) => {
            this.setHorizontal(value);
            this.applyCurrentSegment();
            return value;
          }
        );
      }
      if (!this.angleMeasurement) {
        this.angleMeasurement =
          this.measurementManager.getAngleMeasurementModel();
        this.angleMeasurement.setInputCallbacks(
          (value: number) => {
            this.setAngle(value);
            return value;
          },
          (value: number) => {
            this.setAngle(value);
            this.applyCurrentSegment();
            return value;
          }
        );
      }
      this.horizontalMeasurement.focus();
    }
  }

  init() {
    this.drawingMode = LineType.TANGENTIAL;
    this.store
      .pipe(select((state) => state.model.drawing.nodes))
      .subscribe((nodes) => {
        if (nodes) {
          if (typeof nodes.values !== "function") {
            this.nodes = [];
            this.nodesArray = [];
          } else {
            this.nodes = nodes;
            this.nodesArray = Array.from(this.nodes.values());
          }
        }
      });
    this.store.pipe(select(getPlate)).subscribe((plate: Plate) => {
      this.maxLength = Math.max(plate.height, plate.width);
      this.maxHeight = plate.height;
      this.maxWidth = plate.width;
    });
  }

  setSegmentLength(value: number): void {
    if (this.currentMesh) {
      if (this.point && this.beginNode) {
        const end = getNewEndPosition(this.measurmentModel, value);
        this.updatePolylineVisualization(end);
        this.point = end;
        this.updateClosestSegments(this.point);
        this.updateXYMeasurements(
          this.horizontalMeasurement,
          this.verticalMeasurement,
          this.point
        );
      }
    }
    this.store.dispatch(new RequestRender());
  }

  setVertical(value: number): void {
    if (!this.beginNode) {
      const point = this.verticalMeasurement.getStart();
      const wall = this.verticalMeasurement.getEnd();
      const moveDir = normalizeVector3(subVectors3(point, wall));
      const newPoint = addVectors3(
        wall,
        multiplyVector3byScalar(moveDir, value)
      );
      this.point = { x: newPoint.x, y: newPoint.y, z: newPoint.z };
      this.updateClosestVerticalSegment(this.point);
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    } else {
      const wall = this.verticalMeasurement.getEnd();
      const moveDir = normalizeVector3(subVectors3(this.point, wall));
      const newPoint = addVectors3(
        wall,
        multiplyVector3byScalar(moveDir, value)
      );
      this.point = { x: newPoint.x, y: newPoint.y, z: newPoint.z };
      this.updatePolylineVisualization(this.point);
      this.updateClosestVerticalSegment(this.point);
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    }
    this.store.dispatch(new RequestRender());
  }

  setHorizontal(value: number): void {
    if (!this.beginNode) {
      const point = this.horizontalMeasurement.getStart();
      const wall = this.horizontalMeasurement.getEnd();
      const moveDir = normalizeVector3(subVectors3(point, wall));
      const newPoint = addVectors3(
        wall,
        multiplyVector3byScalar(moveDir, value)
      );
      this.point = { x: newPoint.x, y: newPoint.y, z: newPoint.z };
      this.updateClosestHorizontalSegment(this.point);
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    } else {
      const wall = this.horizontalMeasurement.getEnd();
      const moveDir = normalizeVector3(subVectors3(this.point, wall));
      const newPoint = addVectors3(
        wall,
        multiplyVector3byScalar(moveDir, value)
      );
      this.point = { x: newPoint.x, y: newPoint.y, z: newPoint.z };
      this.updatePolylineVisualization(this.point);
      this.updateClosestHorizontalSegment(this.point);
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    }
    this.store.dispatch(new RequestRender());
  }

  setAngle(value: number): void {
    this.angle = value;
    this.updatePolylineVisualization(this.point);
    this.updateClosestSegments(this.point);
    this.updateXYMeasurements(
      this.horizontalMeasurement,
      this.verticalMeasurement,
      this.point
    );
    this.updateAngleMeasurement();
    this.store.dispatch(new RequestRender());
  }

  onMouseDown(pointerState: PointerState) {}

  onMouseUp(pointerState: PointerState) {}

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {
    this.closestSegments = closestSegments;
    if (this.point) {
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    }
  }

  onMouseMove(pointerState: PointerState) {
    this.point = pointerState.position;
    if (!this.measurmentModel) {
      this.activate();
    }
    if (!this.verticalMeasurement) {
      this.activate();
    }
    if (!this.horizontalMeasurement) {
      this.activate();
    }
    if (!this.angleMeasurement) {
      this.activate();
    }
    if (this.currentMesh) {
      this.currentMesh.dispose();
    }
    if (this.beginNode) {
      this.updatePolylineVisualization(pointerState.position);
      const startToEnd = subVectors2(
        this.beginNode.position,
        pointerState.position
      );
      const dirV2 = getRightPerpendicularVector2(startToEnd);
      const dir = new B_Vector3(
        dirV2.x,
        dirV2.y,
        this.beginNode.position.z
      ).normalize();
      const end = {
        x: this.endNode.position.x,
        y: this.endNode.position.y,
        z: this.beginNode.position.z,
      };
      this.measurmentModel.updateMeasurement(
        this.beginNode.position,
        end,
        dir,
        this.maxLength,
        true
      );
      this.measurmentModel.focus();
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        pointerState.position
      );
      this.updateAngleMeasurement();
    }
  }

  updatePolylineVisualization(point: Vector3) {
    if (this.currentMesh) {
      this.currentMesh.dispose();
    }
    if (!this.beginNode) {
      return;
    }
    const points: B_Vector3[] = [];
    this.endNode =
      checkForClosestNode(this.nodesArray, point) || createTempNode(point);
    const middlePointBetweenEndOfPreviousLineAndMouse: Vector2 = {
      x: (this.beginNode.position.x + this.endNode.position.x) / 2,
      y: (this.beginNode.position.y + this.endNode.position.y) / 2,
    };
    const vectorToMouse = subVectors2(
      middlePointBetweenEndOfPreviousLineAndMouse,
      this.beginNode.position
    );
    const perpendicularToMouseVector =
      getRightPerpendicularVector2(vectorToMouse);
    const beginAndPerpendicularToPrevious = addVectors2(
      this.beginNode.position,
      this.perpendicularToPreviousLineDirection
    );
    const middlePointAndPerpendicularToMouse = addVectors2(
      middlePointBetweenEndOfPreviousLineAndMouse,
      perpendicularToMouseVector
    );
    this.aClockwise =
      crossVector2(vectorToMouse, this.previousLineDirection) > 0;
    const red = new B_Color4(1, 0, 0, 1);
    const colors = [];
    switch (this.drawingMode) {
      case LineType.TANGENTIAL:
        const crossingPointOfPerpendiculars = checkLineIntersection(
          this.beginNode.position.x,
          this.beginNode.position.y,
          beginAndPerpendicularToPrevious.x,
          beginAndPerpendicularToPrevious.y,
          middlePointBetweenEndOfPreviousLineAndMouse.x,
          middlePointBetweenEndOfPreviousLineAndMouse.y,
          middlePointAndPerpendicularToMouse.x,
          middlePointAndPerpendicularToMouse.y
        );
        this.origin = {
          x: crossingPointOfPerpendiculars.x,
          y: crossingPointOfPerpendiculars.y,
        };
        this.radius = distanceVector2(this.beginNode.position, this.origin);
        const vectorToBeginFromOrigin = subVectors2(
          this.beginNode.position,
          this.origin
        );
        this.startAngle = Math.atan2(
          vectorToBeginFromOrigin.y,
          vectorToBeginFromOrigin.x
        );
        const endVector = subVectors2(this.endNode.position, this.origin);
        this.endAngle = Math.atan2(endVector.y, endVector.x);
        if (this.endAngle > this.startAngle) {
          this.angle = this.endAngle - this.startAngle;
        } else {
          this.angle = this.startAngle - this.endAngle;
        }
        const curve = new Curve(
          this.origin,
          this.radius,
          this.startAngle,
          this.endAngle,
          this.aClockwise
        );
        const pathPoints = curve.getPoints();
        for (const p of pathPoints) {
          points.push(new B_Vector3(p.x, p.y, this.beginNode.position.z));
          colors.push(red);
        }
        this.currentMesh = createLineWithDepthOffset(
          "currentLine",
          { points: points, colors: colors },
          this.scene,
          -0.0006
        );

        break;
      case LineType.ANGULAR:
        const angleInRadians = this.angle;
        this.radius = Math.abs(
          distanceVector2(this.beginNode.position, this.endNode.position) /
            Math.sin(angleInRadians / 2.0) /
            2.0
        );
        const toEnd = subVectors2(point, this.beginNode.position);
        this.aClockwise = crossVector2(toEnd, this.previousLineDirection) < 0;
        const intersections = CircleCircleIntersect2D(
          this.beginNode.position.x,
          this.beginNode.position.y,
          this.radius,
          this.endNode.position.x,
          this.endNode.position.y,
          this.radius
        );
        if (intersections.length === 0) {
          return;
        }
        if (intersections.length > 1) {
          const beginToEnd = subVectors2(point, this.beginNode.position);
          const beginToInter0 = subVectors2(
            intersections[0],
            this.beginNode.position
          );
          const sinAngle = Math.sin(this.angle);
          if (sinAngle > 0) {
            this.origin =
              crossVector2(beginToEnd, beginToInter0) > 0
                ? intersections[0]
                : intersections[1];
          } else {
            this.origin =
              crossVector2(beginToEnd, beginToInter0) < 0
                ? intersections[0]
                : intersections[1];
          }
        } else {
          this.origin = intersections[0];
        }
        if (!this.origin) {
          return;
        }
        const vectorFromOrigin = subVectors2(
          this.beginNode.position,
          this.origin
        );
        this.startAngle = Math.atan2(vectorFromOrigin.y, vectorFromOrigin.x);
        this.endAngle = this.startAngle + angleInRadians;
        // if (this.aClockwise) {
        //   const tmp = this.startAngle;
        //   this.startAngle = this.endAngle;
        //   this.endAngle = tmp;
        // }

        const seg: Segment = {
          radius: this.radius,
          origin: this.origin,
          endAngle: this.endAngle,
          beginAngle: this.startAngle,
          begin: this.beginNode.position,
          end: point,
          type: SegmentType.arc,
        };
        const positions = getPointsFromSegment(seg, false).map(
          (v) => new B_Vector3(v.x, v.y, this.beginNode.position.z)
        );
        for (const p of positions) {
          colors.push(red);
        }
        this.currentMesh = createLineWithDepthOffset(
          "currentLine",
          {
            points: positions,
            colors: colors,
          },
          this.scene,
          -0.0006
        );
        break;
    }
  }

  public setMode(mode: LineType, angle?: number) {
    this.drawingMode = mode;
    if (angle) {
      this.angle = angle;
    }
  }

  onMouseClick(pointerState: PointerState) {
    if (this.beginNode) {
      this.applyCurrentSegment();
    }
  }

  applyCurrentSegment() {
    if (this.beginNode) {
      this.endNode = checkForClosestNode(this.nodesArray, this.point);
      if (!this.endNode) {
        this.endNode = createNode(this.point, ShapeOrigin.TEMPORARY);
        this.store.dispatch(new AddNode(this.endNode));
      }
      if (this.drawingMode === LineType.TANGENTIAL) {
        if (!this.aClockwise && this.startAngle >= this.endAngle) {
          this.endAngle += Math.PI * 2;
        }
        if (this.aClockwise && this.startAngle <= this.endAngle) {
          this.startAngle += Math.PI * 2;
        }
      }
      const line: Segment = {
        begin: this.beginNode.position,
        end: this.endNode.position,
        type: SegmentType.arc,
        radius: this.radius,
        endAngle: this.endAngle,
        beginAngle: this.startAngle,
        origin: this.origin,
      };
      this.onSegmentDone.next(line);
    }
  }

  reset() {
    this.onCancel();
    this.activate();
  }

  startNextLine(
    beginPosition: Vector3,
    previousLineDirection: Vector2 = { x: 1, y: 0 }
  ) {
    if (this.currentMesh) {
      this.currentMesh.dispose();
    }
    // Think of something better to find this direction
    this.beginNode =
      checkForClosestNode(this.nodesArray, beginPosition) ||
      createTempNode(beginPosition);
    this.previousLineDirection = previousLineDirection;
    this.perpendicularToPreviousLineDirection = getRightPerpendicularVector2(
      this.previousLineDirection
    );
    this.perpendicularDirection = normalizeVector2(
      this.perpendicularToPreviousLineDirection
    );
  }

  disposeStartMeasurements() {
    if (this.horizontalMeasurement) {
      this.horizontalMeasurement.disposeModel();
      this.horizontalMeasurement = null;
    }
    if (this.verticalMeasurement) {
      this.verticalMeasurement.disposeModel();
      this.verticalMeasurement = null;
    }
  }

  disposeMeasurements() {
    if (this.measurmentModel) {
      this.measurmentModel.disposeModel();
      this.measurmentModel = null;
    }
    if (this.angleMeasurement) {
      this.angleMeasurement.disposeModel();
      this.angleMeasurement = null;
    }
    this.disposeStartMeasurements();
  }

  private updateXYMeasurements(
    XMeasurement: MeasurementModelManager,
    YMeasurement: MeasurementModelManager,
    point: Vector3
  ) {
    updateXYMeasurements(
      XMeasurement,
      YMeasurement,
      point,
      this.closestSegments
    );
  }

  private updateAngleMeasurement() {
    if (this.beginNode && this.beginNode.position && this.origin) {
      const originV3: Vector3 = {
        x: this.origin.x,
        y: this.origin.y,
        z: this.beginNode.position.z,
      };
      this.angleMeasurement.updateMeasurementWithAngle(
        originV3,
        this.angle,
        normalizeVector3(subVectors3(this.beginNode.position, originV3))
      );
    }
  }

  private updateClosestSegments(point: Vector3) {
    this.updateClosestHorizontalSegment(point);
    this.updateClosestVerticalSegment(point);
  }

  private updateClosestVerticalSegment(point: Vector3) {
    this.closestSegments.verticalSegment =
      this.toolProvider.getNearestVerticalSegmentToPoint(point);
  }

  private updateClosestHorizontalSegment(point: Vector3) {
    this.closestSegments.horizontalSegment =
      this.toolProvider.getNearestHorizontalSegmentToPoint(point);
  }

  onCancel() {
    if (this.currentMesh) {
      this.currentMesh.dispose();
    }
    this.disposeMeasurements();
    this.beginNode = null;
    this.origin = null;
    this.endNode = null;
    this.currentMesh = null;
    this.endAngle = null;
    this.startAngle = null;
    this.aClockwise = null;
    this.radius = null;
  }

  onConfirm() {
    this.applyCurrentSegment();
  }

  isDirty(): boolean {
    return this._dirty;
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }
}
