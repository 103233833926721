<div class="configurator-accordion-wrapper">
  <h2
    class="configurator-accordion-title accordion-title--color-default"
  >
    {{ translate("Mesh", "designer") }}
  </h2>

  <dropdown
    [model]="expandedMetalTypeDropdown"
    [tooltipConfig]=" {
      fieldName: 'extendedMetal.type',
      helpText: 'Shape Tooltip'
    }"
  >
  </dropdown>

  <dropdown
    [model]="expandedMetalSizeDropdown"
    [tooltipConfig]=" {
      fieldName: 'extendedMetal.size',
      helpText: 'Expanded Metal Format Tooltip'
    }"
  >
  </dropdown>

  <div class="marginComboBox">
    <label class="labelText">
      <span class="radiodesc">{{translate('schmal')}}</span>
      <input class="radioButton" type="radio" value="schmal"
             [checked]="(stegcluster|async) === 'schmal'"
             [disabled]="!(schmalPossible|async)"
             (change)="setStegcluster('schmal')"
      >
      <span class="checkmark"></span>
    </label>
    <label class="labelText" style="margin-left: 10px">
    <span class="radiodesc">{{translate("breit")}}</span>
    <input class="radioButton" type="radio" value="breit"
      [checked]="(stegcluster|async) === 'breit'"
      [disabled]="!(breitPossible|async)"
      (change)="setStegcluster('breit')"
    >
      <span class="checkmark" ></span>
    </label>
  </div>

  <div class="feedrate">
    <div>{{minFeedrate|async}} &le; </div>
    <div class="feedrate_input">
      <debounce-input
        [inputType]="'number'"
        [value]="(feedrate|async)"
        [placeholder]="translate('Feedrate')"
        (valueChanged)="setFeedrate($event)"
      ></debounce-input>
    </div>
    <!--      (valueChanged)="setP1($event)"-->

<!--    <input class="numericInput3"-->
<!--    type="number"-->
<!--    step="0.1"-->
<!--    [placeholder]="translate('Feedrate')"-->
<!--    value={appState.extendedMetal.feedrate} -->
<!--    onChange={this.onInput3Change.bind(this)} />-->
    <div> &le; {{maxFeedrate|async}}</div>
  </div>
  <p class="typeofcut_label"
  >
    {{ translate("Type of a cut", "designer") }}
  </p>

  <div class="typeofcut">
    <div class="typeofcut_row_separate">
        <img class="cut_icon" src="/assets/CutIcons/LongSide.png">
        <img class="cut_icon" src="/assets/CutIcons/WideSide.png">
    </div>
    <div class="typeofcut_row_separate">
      <button class="typeofcut_button"
              *ngIf="lsRandom as cutControl;"
              [disabled]="cutControl.disabled"
              [ngClass]="{typeofcut_selected: cutControl.selected, typeofcut_hidden: cutControl.hidden, typeofcut_transparent: cutControl.transparent}"
              (click)="cutControl.select()"
              (mouseenter)="cutControl.shoRelations(true)"
              (mouseleave)="cutControl.shoRelations(false)"
      >
        <img class="cut_icon" src="/assets/CutIcons/LSRandomly.png">
      </button>
      <button class="typeofcut_button"
              *ngIf="wsRandom as cutControl;"
              [disabled]="cutControl.disabled"
              [ngClass]="{typeofcut_selected: cutControl.selected, typeofcut_hidden: cutControl.hidden, typeofcut_transparent: cutControl.transparent}"
              (click)="cutControl.select()"
              (mouseenter)="cutControl.shoRelations(true)"
              (mouseleave)="cutControl.shoRelations(false)"
      >
        <img class="cut_icon" src="/assets/CutIcons/WSRandomly.png">
      </button>
    </div>
    <div class="typeofcut_row_separate">
      <button class="typeofcut_button"
              *ngIf="lsOnKnots as cutControl;"
              [disabled]="cutControl.disabled"
              [ngClass]="{typeofcut_selected: cutControl.selected, typeofcut_hidden: cutControl.hidden, typeofcut_transparent: cutControl.transparent}"
              (click)="cutControl.select()"
              (mouseenter)="cutControl.shoRelations(true)"
              (mouseleave)="cutControl.shoRelations(false)"
      >
        <img class="cut_icon" src="/assets/CutIcons/LSOnKnots.png">
      </button>
      <button class="typeofcut_button"
              *ngIf="wsOnKnots as cutControl;"
              [disabled]="cutControl.disabled"
              [ngClass]="{typeofcut_selected: cutControl.selected, typeofcut_hidden: cutControl.hidden, typeofcut_transparent: cutControl.transparent}"
              (click)="cutControl.select()"
              (mouseenter)="cutControl.shoRelations(true)"
              (mouseleave)="cutControl.shoRelations(false)"
      >
        <img class="cut_icon" src="/assets/CutIcons/WSOnKnots.png">
      </button>
    </div>
    <div class="typeofcut_row_together">
      <button class="typeofcut_button"
              *ngIf="onKnotsOpen as cutControl;"
              [disabled]="cutControl.disabled"
              [ngClass]="{typeofcut_selected: cutControl.selected, typeofcut_hidden: cutControl.hidden, typeofcut_transparent: cutControl.transparent}"
              (click)="cutControl.select()"
              (mouseenter)="cutControl.shoRelations(true)"
              (mouseleave)="cutControl.shoRelations(false)"
      >
        <img class="cut_icon" src="/assets/CutIcons/LSOnKnotsOpen.png">
      </button>
      <button class="typeofcut_button"
              *ngIf="onKnotsClose as cutControl;"
              [disabled]="cutControl.disabled"
              [ngClass]="{typeofcut_selected: cutControl.selected, typeofcut_hidden: cutControl.hidden, typeofcut_transparent: cutControl.transparent}"
              (click)="cutControl.select()"
              (mouseenter)="cutControl.shoRelations(true)"
              (mouseleave)="cutControl.shoRelations(false)"
      >
        <img class="cut_icon" src="/assets/CutIcons/WSOnKnotsClose.png">
      </button>
    </div>
    <div class="typeofcut_row_separate">
      <button class="typeofcut_button"
              *ngIf="lsSymmetric as cutControl;"
              [disabled]="cutControl.disabled"
              [ngClass]="{typeofcut_selected: cutControl.selected, typeofcut_hidden: cutControl.hidden, typeofcut_transparent: cutControl.transparent}"
              (click)="cutControl.select()"
              (mouseenter)="cutControl.shoRelations(true)"
              (mouseleave)="cutControl.shoRelations(false)"
      >
        <img class="cut_icon" src="/assets/CutIcons/LSSymmetric.png">
      </button>
      <button class="typeofcut_button"
              *ngIf="wsSymmetric as cutControl;"
              [disabled]="cutControl.disabled"
              [ngClass]="{typeofcut_selected: cutControl.selected, typeofcut_hidden: cutControl.hidden, typeofcut_transparent: cutControl.transparent}"
              (click)="cutControl.select()"
              (mouseenter)="cutControl.shoRelations(true)"
              (mouseleave)="cutControl.shoRelations(false)"
      >
        <img class="cut_icon" src="/assets/CutIcons/WSSymmetric.png">
      </button>
    </div>
    <div class="typeofcut_row_together">
      <button class="typeofcut_button"
              *ngIf="symmetricSmall as cutControl;"
              [disabled]="cutControl.disabled"
              [ngClass]="{typeofcut_selected: cutControl.selected, typeofcut_hidden: cutControl.hidden, typeofcut_transparent: cutControl.transparent}"
              (click)="cutControl.select()"
              (mouseenter)="cutControl.shoRelations(true)"
              (mouseleave)="cutControl.shoRelations(false)"
      >
        <img class="cut_icon" src="/assets/CutIcons/LSSymmetricSmall.png">
      </button>
      <button class="typeofcut_button"
              *ngIf="symmetricBig as cutControl;"
              [disabled]="cutControl.disabled"
              [ngClass]="{typeofcut_selected: cutControl.selected, typeofcut_hidden: cutControl.hidden, typeofcut_transparent: cutControl.transparent}"
              (click)="cutControl.select()"
              (mouseenter)="cutControl.shoRelations(true)"
              (mouseleave)="cutControl.shoRelations(false)"
      >
        <img class="cut_icon" src="/assets/CutIcons/LSSymmetricBig.png">
      </button>
    </div>
    <div class="typeofcut_row_together">
      <button class="typeofcut_button"
              *ngIf="custom as cutControl;"
              [disabled]="cutControl.disabled"
              [ngClass]="{typeofcut_selected: cutControl.selected, typeofcut_hidden: cutControl.hidden, typeofcut_transparent: cutControl.transparent}"
              (click)="cutControl.select()"
              (mouseenter)="cutControl.shoRelations(true)"
              (mouseleave)="cutControl.shoRelations(false)"
      >
        <img class="cut_icon" src="/assets/CutIcons/Custom.png">
      </button>
    </div>

  </div>
<!--  <dropdown-->
<!--    [model]="expandedMetalTypeOfCutDropdown"-->
<!--    [tooltipConfig]=" {-->
<!--      fieldName: 'extendedMetal.size',-->
<!--      helpText: 'Expanded Metal Format Tooltip'-->
<!--    }"-->
<!--  >-->
<!--  </dropdown>-->

</div>
